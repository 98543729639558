import { openDB, DBSchema } from 'idb';

import { logger } from '../Logger';

import { Movement, createMovementsService } from './movementsService';
import { tokenService } from './tokenService';

interface MovementDBSchema extends DBSchema {
  movements: {
    key: string;
    value: {
      movements: Movement[];
      timestamp: number;
    };
  };
}

class MovementCache {
  private movements: Movement[] = [];
  private lastFetchTime = 0;
  private readonly CACHE_DURATION = 60 * 60 * 1000; // 60 minutes
  private readonly DB_NAME = 'movements-db';
  private readonly movementsService = createMovementsService();

  constructor() {
    this.movementsService.subscribe(() => this.clearCache());
    this.initialize().catch((error) => logger.error('Failed to initialize movement cache:', error));
  }

  private async getDB() {
    return openDB<MovementDBSchema>(this.DB_NAME, 1, {
      upgrade(db) {
        db.createObjectStore('movements');
      },
    });
  }

  public async initialize(): Promise<void> {
    try {
      if (!tokenService.getToken()) {
        this.movements = [];
        return;
      }

      const db = await this.getDB();
      const cached = await db.get('movements', 'cache');

      if (
        cached &&
        cached.movements.length > 0 &&
        Date.now() - cached.timestamp <= this.CACHE_DURATION
      ) {
        this.movements = cached.movements;
        this.lastFetchTime = cached.timestamp;
        return;
      }

      this.movements = await this.movementsService.getAll();
      this.lastFetchTime = Date.now();

      if (this.movements.length > 0) {
        await db.put(
          'movements',
          {
            movements: this.movements,
            timestamp: this.lastFetchTime,
          },
          'cache',
        );
      }
    } catch (error) {
      logger.error('Failed to initialize movements:', error);
      this.movements = [];
    }
  }

  public getMovements(): Movement[] {
    const now = Date.now();
    if (now - this.lastFetchTime > this.CACHE_DURATION) {
      this.initialize().catch((error) => logger.error('Failed to refresh movements:', error));
    }
    return this.movements;
  }

  public async clearCache(): Promise<void> {
    this.movements = [];
    this.lastFetchTime = 0;
    try {
      const db = await this.getDB();
      await db.delete('movements', 'cache');
    } catch (error) {
      logger.error('Failed to clear cache:', error);
    }
    this.initialize().catch((error) => logger.error('Failed to reinitialize movements:', error));
  }
}

export const movementCache = new MovementCache();
