import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';

import PrivateRoute from './auth/components/PrivateRoute';
import { AuthProvider } from './auth/context/AuthContext';
import MaintenancePage from './components/MaintenancePage/MaintenancePage';
import Navigation from './components/Navigation/Navigation';
import Graphs from './pages/Graphs';
import Login from './pages/Login';
import MovementLibrary from './pages/MovementLibrary';
import ProgramList from './pages/ProgramList/ProgramList';
import Registration from './pages/Registration';
import WorkoutPlanner from './pages/WorkoutPlanner/WorkoutPlanner';

const theme = createTheme();

const appStyles = {
  display: 'flex',
};

const mainContentStyles = {
  flexGrow: 1,
  paddingTop: '10px',
};

const isRegistrationDisabled = process.env.REACT_APP_DISABLE_REGISTRATION === 'true';

function App() {
  // By default, show maintenance page unless explicitly set to 'false'
  const isUnderMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE !== 'false';

  if (isUnderMaintenance) {
    return <MaintenancePage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Router>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

  return (
    <div style={appStyles}>
      {!isAuthPage && <Navigation />}
      <main style={mainContentStyles}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/register"
            element={
              isRegistrationDisabled ? (
                <Navigate
                  to="/login"
                  replace
                  state={{ message: 'Registration is currently disabled' }}
                />
              ) : (
                <Registration />
              )
            }
          />
          <Route element={<PrivateRoute />}>
            <Route path="/programs" element={<ProgramList />} />
            <Route path="/workout-planner/:programId" element={<WorkoutPlanner />} />
            <Route path="/graphs" element={<Graphs />} />
            <Route path="/movement-library" element={<MovementLibrary />} />
          </Route>
        </Routes>
      </main>
    </div>
  );
}

export default App;
