import { Token}  from 'antlr4ng';
import { MarkerSeverity } from 'monaco-editor';

import { logger } from '../../Logger';
import {
  Workout_attributeContext,
  WorkoutContext,
} from '../antlr/.antlr/UniversalWorkoutLanguageParser';
import { UWLError } from '../UWLErrorHandler';

import { workoutDefs } from './constants';
import { AttributeMessageOverride, WorkoutAttrTypes } from './types';
import { normalizeName, typeCheck } from './utils';

function genTokenError(
  token : Token | null | undefined, 
  message : string, 
  severity : MarkerSeverity
) : UWLError {
  const line = token?.line ?? 0;
  const startColumn = token?.column ?? 0;
  const textLength = token?.text?.length ?? 0;

  return {
      startLineNumber: line,
      endLineNumber: line,
      startColumn: startColumn + 1,
      endColumn: startColumn + textLength + 1,
      message: message,
      severity: severity
  }
}

export function addUnrecognizedWorkoutError(
  ctx: WorkoutContext,
  name: string,
  errors: UWLError[],
): void {
  const offender = ctx.workout_iden().WORKOUT_IDENTIFIER().symbol;
  const text = offender.text || '';
  errors.push({
    startLineNumber: offender.line,
    endLineNumber: offender.line,
    startColumn: offender.column,
    endColumn: offender.column + text.length + 1,
    message: `Unrecognized workout type ${name}`,
    severity: MarkerSeverity.Error,
  });
}

export function addAttributeCustomError(
  attr: Workout_attributeContext,
  severity: MarkerSeverity,
  message: string,
  errors: UWLError[],
): void {
  const offender_start = attr.workout_attr_id().start;
  const offender_stop = attr.workout_attr_id().stop;

  if (!offender_start || !offender_stop || !offender_stop.text) {
    return;
  }

  errors.push({
    startLineNumber: offender_start.line,
    endLineNumber: offender_stop.line,
    startColumn: offender_start.column + 1,
    endColumn: offender_stop.column + offender_stop.text.length + 1,
    message: message,
    severity: severity,
  });
}

export function addAttributeTypeError(
  attr: Workout_attributeContext,
  name: string,
  expectedType: string,
  errors: UWLError[],
): void {
  const offender_start = attr.workout_attr_val().start;
  const offender_stop = attr.workout_attr_val().stop;

  if (!offender_start || !offender_stop || !offender_stop.text) {
    return;
  }

  errors.push({
    startLineNumber: offender_start.line,
    endLineNumber: offender_stop.line,
    startColumn: offender_start.column + 1,
    endColumn: offender_stop.column + offender_stop.text.length + 1,
    message: `Type mismatch, attribute "${name}" should have type ${expectedType}`,
    severity: MarkerSeverity.Error,
  });
}

export function addUnknownAttributeError(
  attr: Workout_attributeContext,
  name: string,
  errors: UWLError[],
): void {
  const offender = attr.workout_attr_id().WORKOUT_IDEN().symbol;

  if (!offender || !offender.text) {
    return;
  }

  errors.push({
    startLineNumber: offender.line,
    endLineNumber: offender.line,
    startColumn: offender.column + 1,
    endColumn: offender.column + offender.text.length + 1,
    message: `Unknown attribute "${name}"`,
    severity: MarkerSeverity.Warning,
  });
}

export function addDuplicateAttributeError(
  attr: Workout_attributeContext,
  name: string,
  errors: UWLError[],
): void {
  const offender = attr.workout_attr_id().WORKOUT_IDEN().symbol;

  if (!offender || !offender.text) {
    return;
  }

  errors.push({
    startLineNumber: offender.line,
    endLineNumber: offender.line,
    startColumn: offender.column + 1,
    endColumn: offender.column + offender.text.length + 1,
    message: `Duplicate attribute "${name}"\nOnly the first value will be used`,
    severity: MarkerSeverity.Warning,
  });
}

export function addMissingAttributeErrors(
  ctx: WorkoutContext,
  remaining_attrs: string[],
  workout_type: string,
  errors: UWLError[],
) {
  const offender = ctx.workout_iden().WORKOUT_IDENTIFIER().symbol;
  const text = offender.text || '';

  for (const attr of remaining_attrs) {
    const attrType = workoutDefs[workout_type][attr];

    if ((attrType & WorkoutAttrTypes.Optional) !== 0) {
      errors.push({
        startLineNumber: offender.line,
        endLineNumber: offender.line,
        startColumn: offender.column + 1,
        endColumn: offender.column + text.length + 1,
        message: `This workout type can support an optional attribute "${attr}"`,
        severity: MarkerSeverity.Hint,
      });
    } else {
      errors.push({
        startLineNumber: offender.line,
        endLineNumber: offender.line,
        startColumn: offender.column + 1,
        endColumn: offender.column + text.length + 1,
        message: `This workout is missing a required attribute "${attr}"`,
        severity: MarkerSeverity.Error,
      });
    }
  }
}

export function validateAttribute(
  name: string,
  normType: string,
  val: string,
  attr: Workout_attributeContext,
  errors: UWLError[],
  overrides: AttributeMessageOverride[] | null = null,
) {
  const override: AttributeMessageOverride | null =
    overrides?.find((val: AttributeMessageOverride) => {
      return val.name === name;
    }) ?? null;
  const isKnown = name in (workoutDefs[normType] ?? {}) || (override?.expectedType ?? false);

  if (override?.severity !== undefined) {
    addAttributeCustomError(attr, override.severity, override.message ?? '', errors);
  }

  if (override?.suppressOtherErrors ?? false) {
    return;
  }

  if (isKnown) {
    const expectedType = workoutDefs[normType][normalizeName(name)];
    const isValidType = typeCheck(val, expectedType);

    if (!isValidType) {
      addAttributeTypeError(attr, name, WorkoutAttrTypes[expectedType], errors);
    }
  } else {
    addUnknownAttributeError(attr, name, errors);
  }
}

export function validateEmomBounds(
  minutesPerRound: number, 
  minTimeToken : Token | null | undefined, 
  maxTimeToken : Token | null | undefined, 
  errors: UWLError[]
) {
  const minTotalMinutes : number = 
    minTimeToken?.text !== undefined ? Number.parseInt(minTimeToken.text) : 0;
  const maxTotalMinutes : number | null = 
    maxTimeToken?.text !== undefined ? Number.parseInt(maxTimeToken.text) : null;

  if (minTimeToken === null){
    logger.warn("Token for Emom lower bound is null! Defaulting to 0.");
  }

  if (minTotalMinutes < minutesPerRound) {
    errors.push(
      genTokenError(
        minTimeToken,
        //`Emom lower bound must be at least as long as the number of minutes.\n e.g.: ${no_mins}, ${no_mins*2}, ${no_mins*3}, ...`,
        `Emom must run for at least ${minutesPerRound} ${minutesPerRound === 1 ? "minute" : "minutes"}, to cover all specified movements.\n\nPossible times: ${minutesPerRound}, ${minutesPerRound*2}, ${minutesPerRound*3}, ...`,
        MarkerSeverity.Error
       )
    );
  }

  if (maxTotalMinutes !== null && maxTotalMinutes < minTotalMinutes) {
    errors.push(
      genTokenError(
        maxTimeToken,
        `Maximum time (${maxTotalMinutes}) cannot be shorter than than the minimum time (${minTotalMinutes}).`,
        MarkerSeverity.Error
      )
    );
  }

  if (minTotalMinutes % minutesPerRound !== 0) {
    errors.push(
      genTokenError(
        minTimeToken,
        `The total time must be a complete set of ${minutesPerRound}-minute rounds.\n\nPossible times: ${minutesPerRound}, ${minutesPerRound * 2}, ${minutesPerRound * 3} minutes`,
        MarkerSeverity.Error
      )
    );
  }

  if (maxTotalMinutes !== null && maxTotalMinutes % minutesPerRound !== 0) {
    errors.push(
      genTokenError(
        maxTimeToken,
        `The maximum time must be a complete set of ${minutesPerRound}-minute rounds.\n\nPossible times: ${minutesPerRound}, ${minutesPerRound * 2}, ${minutesPerRound * 3} minutes`,
        MarkerSeverity.Error
      )
    );
  }
}