import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  IconButton,
  Typography,
  Switch,
  Chip,
} from '@mui/material';
import React from 'react';

import { logger } from '../../Logger';
import { Movement, createMovementsService } from '../../services/movementsService';

import { styles } from './MovementLibraryDialog.styles';
import { MovementDialogProps } from './MovementLibraryDialog.types';

const isValidVideoUrl = (url: string): boolean => {
  if (!url) return true;
  try {
    const parsedUrl = new URL(url);

    const isYoutube =
      parsedUrl.hostname.includes('youtube.com') || parsedUrl.hostname.includes('youtu.be');

    const isVimeo = parsedUrl.hostname.includes('vimeo.com');

    return isYoutube || isVimeo;
  } catch {
    return false;
  }
};

const MovementLibraryDialog: React.FC<MovementDialogProps> = ({
  open,
  onClose,
  onSave,
  onDelete,
  movement,
}) => {
  const [formData, setFormData] = React.useState<Movement>({
    id: movement?.id || 0,
    name: '',
    has_reps: false,
    has_load: false,
    has_duration: false,
    has_distance: false,
    has_height: false,
    has_power: false,
    has_calories: false,
    is_complex: false,
    is_priority: false,
    video_url: movement?.video_url ? movement.video_url : '',
    aliases: [],
  });

  const [newAlias, setNewAlias] = React.useState('');
  const [aliasError, setAliasError] = React.useState(false);
  const [urlError, setUrlError] = React.useState(false);
  const [isEditingUrl, setIsEditingUrl] = React.useState(false);
  const [tempUrl, setTempUrl] = React.useState('');

  const movementsService = createMovementsService();

  React.useEffect(() => {
    const loadMovement = async () => {
      if (open && movement) {
        const freshMovement = await movementsService.getById(movement.id);
        setFormData({
          ...freshMovement,
          video_url: freshMovement.video_url || '',
        });
      }
    };

    loadMovement();
  }, [open, movement]);

  const attributes = [
    { name: 'Reps', field: 'has_reps' },
    { name: 'Load', field: 'has_load' },
    { name: 'Duration', field: 'has_duration' },
    { name: 'Distance', field: 'has_distance' },
    { name: 'Height', field: 'has_height' },
    { name: 'Power', field: 'has_power' },
    { name: 'Calories', field: 'has_calories' },
  ];

  const handleAttributeChange = (field: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: !prev[field as keyof Movement],
    }));
  };

  const handleAddAlias = async () => {
    if (!newAlias.trim()) return;

    if (formData.aliases?.some((a) => a.alias.toLowerCase() === newAlias.toLowerCase())) {
      setAliasError(true);
      return;
    }

    try {
      const alias = await movementsService.addAlias(formData.id, newAlias.trim());
      setFormData((prev) => ({
        ...prev,
        aliases: [...(prev.aliases || []), alias],
      }));
      setNewAlias('');
    } catch (error) {
      logger.error('Failed to add alias:', error);
    }
  };

  const handleRemoveAlias = async (aliasId: number) => {
    try {
      await movementsService.removeAlias(formData.id, aliasId);
      setFormData((prev) => ({
        ...prev,
        aliases: prev.aliases?.filter((a) => a.id !== aliasId),
      }));
    } catch (error) {
      logger.error('Failed to remove alias:', error);
    }
  };

  const handleSave = async () => {
    await onSave(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={styles.dialog}>
      <DialogTitle sx={styles.dialogTitle}>
        {movement ? 'Edit movement' : 'New movement'}
        <IconButton onClick={onClose} sx={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={styles.dialogContent}>
        {/* Name Section */}
        <Box sx={styles.section}>
          <Typography variant="subtitle2" sx={styles.sectionLabel}>
            Name <span style={{ color: '#1976d2' }}>*</span>
          </Typography>
          <TextField
            required
            fullWidth
            size="small"
            value={formData.name}
            onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
            sx={styles.textField}
          />
        </Box>

        {/* Attributes Section */}
        <Box sx={styles.section}>
          <Typography variant="subtitle2" sx={styles.sectionLabel}>
            Attributes <span style={{ color: '#1976d2' }}>*</span>
          </Typography>
          <Box sx={styles.attributesGrid}>
            {attributes.map(({ name, field }) => (
              <FormControlLabel
                key={field}
                control={
                  <Checkbox
                    checked={formData[field as keyof Movement] as boolean}
                    onChange={() => handleAttributeChange(field)}
                    size="small"
                  />
                }
                label={<Typography variant="body2">{name}</Typography>}
              />
            ))}
          </Box>
        </Box>

        {/* Priority Movement Switch */}
        <Box sx={styles.section}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.is_priority || false}
                onChange={() => handleAttributeChange('is_priority')}
                size="small"
              />
            }
            label={
              <Box sx={styles.priorityLabel}>
                <Typography variant="body2">Priority Movement</Typography>
                <Typography variant="caption" color="text.secondary">
                  Priority movements appear first in suggestions
                </Typography>
              </Box>
            }
          />
        </Box>

        {/* Aliases Section */}
        <Box sx={styles.section}>
          <Typography variant="subtitle2" sx={styles.sectionLabel}>
            Aliases
          </Typography>
          <Box sx={styles.aliasContainer}>
            <Box sx={styles.aliasChips}>
              {formData.aliases?.map((alias) => (
                <Chip
                  key={alias.id}
                  label={alias.alias}
                  size="small"
                  onDelete={() => handleRemoveAlias(alias.id)}
                  sx={styles.aliasChip}
                />
              ))}
            </Box>
            <Box sx={styles.aliasInputContainer}>
              <TextField
                size="small"
                placeholder="Add alias"
                value={newAlias}
                onChange={(e) => {
                  setNewAlias(e.target.value);
                  setAliasError(false);
                }}
                error={aliasError}
                helperText={aliasError ? 'Alias already exists' : ''}
                sx={styles.aliasInput}
              />
              <Button
                size="small"
                variant="contained"
                onClick={handleAddAlias}
                sx={styles.aliasButton}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Additional Information Section */}
        <Box sx={styles.section}>
          <Typography variant="subtitle2" sx={styles.sectionLabel}>
            Additional Information
          </Typography>
          <Box sx={styles.videoUrlContainer}>
            {isEditingUrl ? (
              <TextField
                size="small"
                fullWidth
                value={tempUrl}
                onChange={(e) => {
                  setTempUrl(e.target.value);
                  setUrlError(!isValidVideoUrl(e.target.value));
                }}
                placeholder="Enter YouTube or Vimeo URL"
                error={urlError}
                helperText={urlError ? 'Please enter a valid YouTube or Vimeo URL' : ''}
                sx={styles.videoUrlField}
              />
            ) : formData.video_url ? (
              <Typography
                component="a"
                href={formData.video_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formData.video_url}
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No video url attached
              </Typography>
            )}
            <Button
              size="small"
              onClick={() => {
                if (isEditingUrl) {
                  if (isValidVideoUrl(tempUrl)) {
                    setFormData((prev) => ({ ...prev, video_url: tempUrl }));
                    setIsEditingUrl(false);
                    setUrlError(false);
                  } else {
                    setUrlError(true);
                  }
                } else {
                  setTempUrl(formData.video_url || '');
                  setIsEditingUrl(true);
                }
              }}
              disabled={isEditingUrl && urlError}
            >
              {isEditingUrl ? 'SAVE' : 'EDIT'}
            </Button>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description / instructions (optional)"
            value={formData.complex_details || ''}
            onChange={(e) => setFormData((prev) => ({ ...prev, complex_details: e.target.value }))}
            sx={styles.descriptionField}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={styles.dialogActions}>
        <Box>
          {movement && onDelete && (
            <Button
              startIcon={<DeleteOutlineIcon />}
              onClick={onDelete}
              sx={styles.removeButton}
              variant="outlined"
            >
              Remove from library
            </Button>
          )}
        </Box>
        <Box>
          <Button onClick={onClose} sx={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!formData.name || urlError}
            sx={styles.saveButton}
          >
            {movement ? 'Save changes' : 'Create movement'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MovementLibraryDialog;
