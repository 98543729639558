import { Movement } from '../@monaco-uwl/UWLVisitor/Movement';
import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { ForTimeComponent } from './parser.types';
import { prepareNotes } from './utils';

function prepareName(rounds: number | undefined): string {
  if (!rounds || rounds <= 1) {
    return 'For Time:';
  }
  return `${rounds} Rounds For Time:`;
}

function isMultipleRounds(rounds: string | undefined): boolean {
  if (!rounds) return false;
  const roundsNum = parseInt(rounds);
  return !isNaN(roundsNum) && roundsNum >= 2;
}

function prepareMovementsRest(movements: Movement[]): Movement[] {
  return movements.map((movement) => {
    if (movement.restAfter) {
      movement.restAfter = movement.restAfter.replace(/"/g, '');
    }
    return movement;
  });
}

function prepareRest(roundRest: string | undefined): string | undefined {
  if (!roundRest) return undefined;
  return roundRest.replace(/"/g, '');
}

export function parseUWLForTime(w: Workout, prefix: string): ForTimeComponent {
  return {
    type: 'ForTime',
    is_alternating: w.is_alternating,
    should_be_inside_alternating: false,
    prefix: prefix,
    name: prepareName(parseInt(w.attrs.rounds)),
    movements: prepareMovementsRest(w.movements),
    roundRest: prepareRest(w.attrs.round_rest),
    componentRest: prepareRest(w.attrs.component_rest),
    notes: prepareNotes(w.notes),
    isMultipleRounds: isMultipleRounds(w.attrs.rounds),
  };
}

/* 

1- No rest for time (CrossFit) 
4 rounds for time
 > # movement (load where applicable)
 > # movement (load where applicable)
 > # movement (load where applicable)
*Notes

2- Interval style 1 - For time (CrossFit w/ embedded rest)
4 rounds for time
 > # movement (load where applicable)
 > # movement (load where applicable)
rest exactly 2 min into
 3 rounds for time
 > # movement (load where applicable)
 > # movement (load where applicable)

3- Interval style 2 - multiple movements with rest between
For time:
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 45 sec
> # movement (load where applicable)

4- Interval style 3 - multiple movements with rest between for multiple sets
For time:
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 30 sec
> # movement (load where applicable)
rest 45 sec
> # movement (load where applicable)
rest # min
x# sets
*Notes

5- Interval style 4 - multiple rounds for multiple sets
3 rounds, for time:
 > # movement (load where applicable)
 > # movement (load where applicable)
rest # min
x# sets
*Notes
*/
