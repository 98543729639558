import { Typography, Box, Container, CircularProgress } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../api/base';
import { WeekGrid } from '../../components/WeekGrid/WeekGrid';
import { WeekManager } from '../../components/WeekManager/WeekManager';
import { useDebouncedSave } from '../../hooks/useDebouncedSave';
import { useWeeks } from '../../hooks/useWeeks';
import { logger } from '../../Logger';
import { Program, ProgramDay } from '../../types/program';

import { styles } from './WorkoutPlanner.styles';

function WorkoutPlanner() {
  const { weeks, setWeeks, addWeeks, removeWeeks } = useWeeks(2);
  const [isLoading, setIsLoading] = useState(false);
  const { programId } = useParams<{ programId: string }>();
  const [programData, setProgramData] = useState<Program | null>(null);
  const debouncedSave = useDebouncedSave(programId);

  const fetchProgramData = useCallback(
    async (id: number) => {
      setIsLoading(true);
      try {
        const response = await api.get<Program>(`/api/programs/${id}`);
        setProgramData(response.data);
        if (response.data.days?.length > 0) {
          const maxWeek = Math.max(...response.data.days.map((day: ProgramDay) => day.weekNumber));
          setWeeks(Math.max(2, maxWeek));
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          logger.error('Error fetching program data:', error.message);
        } else {
          logger.error('Error fetching program data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setWeeks],
  );

  useEffect(() => {
    if (programId) {
      fetchProgramData(parseInt(programId));
    }
  }, [programId, fetchProgramData]);

  const handleWeeksChange = (weekChange: number) => {
    if (weekChange > 0) {
      addWeeks(weekChange);
    } else if (weekChange < 0) {
      removeWeeks(Math.abs(weekChange));
    }
  };

  return (
    <Container maxWidth="xl">
      <Box sx={styles.header}>
        <Typography variant="h5">Program</Typography>
      </Box>

      <WeekGrid
        weeks={weeks}
        onDayContentChange={debouncedSave}
        initialDaysData={programData?.days || []}
      />

      <Box sx={styles.weekManagerContainer}>
        <WeekManager onWeeksChange={handleWeeksChange} />
      </Box>

      {isLoading && (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
}

export default WorkoutPlanner;
