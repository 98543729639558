// Generated from UniversalWorkoutLanguageParser.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";

import { UniversalWorkoutLanguageParserVisitor } from "./UniversalWorkoutLanguageParserVisitor.js";

// for running tests with parameters, TODO: discuss strategy for typed parameters in CI
// eslint-disable-next-line no-unused-vars
type int = number;


export class UniversalWorkoutLanguageParser extends antlr.Parser {
    public static readonly WS = 1;
    public static readonly NL = 2;
    public static readonly NUMBER = 3;
    public static readonly FORTIME_KW = 4;
    public static readonly WORKOUT_SEPARATOR = 5;
    public static readonly INTO_KW = 6;
    public static readonly MOVEMENT_MARKER = 7;
    public static readonly CHOICE_KW = 8;
    public static readonly OPTION_KW = 9;
    public static readonly EMOM_KW = 10;
    public static readonly WOD_KW = 11;
    public static readonly DEF_KW = 12;
    public static readonly NEW_MOV_KW = 13;
    public static readonly REST_KW = 14;
    public static readonly MIN_KW = 15;
    public static readonly STRING = 16;
    public static readonly GENERIC_STRING = 17;
    public static readonly PAREN_L = 18;
    public static readonly LBRACE = 19;
    public static readonly RBRACE = 20;
    public static readonly COLON = 21;
    public static readonly INLINE_COMMENT = 22;
    public static readonly MULTILINE_COMMENT = 23;
    public static readonly WORKOUT_IDENTIFIER = 24;
    public static readonly GENERIC_SEPARATOR = 25;
    public static readonly ERROR_CHAR = 26;
    public static readonly WORKOUT_WS = 27;
    public static readonly WORKOUT_PAREN_L = 28;
    public static readonly WORKOUT_PAREN_R = 29;
    public static readonly WORKOUT_COLON = 30;
    public static readonly WORKOUT_COMMA = 31;
    public static readonly WORKOUT_QUOTE = 32;
    public static readonly WORKOUT_STRING = 33;
    public static readonly WORKOUT_ML_STRING = 34;
    public static readonly WORKOUT_TIME = 35;
    public static readonly WORKOUT_NUMBER = 36;
    public static readonly WORKOUT_UNIT_TIME = 37;
    public static readonly WORKOUT_IDEN = 38;
    public static readonly CHOICE_WS = 39;
    public static readonly CHOICE_NAME = 40;
    public static readonly MOVEMENT_NAME_REP_MARKER = 41;
    public static readonly SIMPLE_MOVEMENT = 42;
    public static readonly MOVEMENT_NAME_REPS = 43;
    public static readonly MOVEMENT_NAME_COMPOENENT_SEPARATOR = 44;
    public static readonly COMPLEX_MOVEMENT = 45;
    public static readonly MOVEMENT_NAME_TERMINATOR = 46;
    public static readonly MOVEMENT_NAME_WS = 47;
    public static readonly MC_WS = 48;
    public static readonly MC_NUMBER = 49;
    public static readonly MC_MAX = 50;
    public static readonly AMRAP_UNIT_KW = 51;
    public static readonly MC_PAREN_L = 52;
    public static readonly MC_PAREN_R = 53;
    public static readonly MC_DASH = 54;
    public static readonly MC_CAL_KW = 55;
    public static readonly MC_TIME = 56;
    public static readonly MC_SET_SEPARATOR = 57;
    public static readonly LOAD_MARKER = 58;
    public static readonly TEMPO_MARKER = 59;
    public static readonly DURATION_MARKER = 60;
    public static readonly DISTANCE_MARKER = 61;
    public static readonly MOVEMENT_TERM = 62;
    public static readonly MC_CONTENT = 63;
    public static readonly MOV_ERR = 64;
    public static readonly DEF_WS = 65;
    public static readonly DEF_COMMENT = 66;
    public static readonly DEF_REGEX = 67;
    public static readonly DEF_IDENTIFIER = 68;
    public static readonly DEF_QUOTE = 69;
    public static readonly DEF_COLON = 70;
    public static readonly DEF_OPTIONAL = 71;
    public static readonly DEF_COMMA = 72;
    public static readonly DEF_PAREN_L = 73;
    public static readonly DEF_PAREN_R = 74;
    public static readonly DEF_BRACE_L = 75;
    public static readonly DEF_BRACE_R = 76;
    public static readonly DEF_SUPER_KW = 77;
    public static readonly DEF_TYPES = 78;
    public static readonly DEF_ENUM_KW = 79;
    public static readonly NEW_MOV_WS = 80;
    public static readonly NEW_MOV_QUOTE = 81;
    public static readonly NEW_MOV_IDENTIFIER = 82;
    public static readonly NEW_MOV_COLON = 83;
    public static readonly NEW_MOV_COMMA = 84;
    public static readonly NEW_MOV_SEMI = 85;
    public static readonly NEW_MOV_PARAM = 86;
    public static readonly NUMBER_WS = 87;
    public static readonly ROUNDS_WORD = 88;
    public static readonly ALT_KW = 89;
    public static readonly FORTIME_SET_KW = 90;
    public static readonly TIME_UNIT = 91;
    public static readonly NUMBER_CONTEXT_ERR = 92;
    public static readonly FORTIME_WS = 93;
    public static readonly FORTIME_ERR = 94;
    public static readonly AMRAP_WS = 95;
    public static readonly AMRAP_KW = 96;
    public static readonly AMRAP_ERR = 97;
    public static readonly EMOM_X_SEP = 98;
    public static readonly EMOM_DASH = 99;
    public static readonly EMOM_COLON = 100;
    public static readonly EMOM_MIN_KW = 101;
    public static readonly EMOM_NUM = 102;
    public static readonly EMOM_WS = 103;
    public static readonly EMOM_NL = 104;
    public static readonly EMOM_ERR = 105;
    public static readonly RULE_error = 0;
    public static readonly RULE_mov_time = 1;
    public static readonly RULE_sets = 2;
    public static readonly RULE_reps = 3;
    public static readonly RULE_rep_value = 4;
    public static readonly RULE_amrap_value = 5;
    public static readonly RULE_calories = 6;
    public static readonly RULE_load = 7;
    public static readonly RULE_tempo = 8;
    public static readonly RULE_duration = 9;
    public static readonly RULE_distance = 10;
    public static readonly RULE_complex_movement = 11;
    public static readonly RULE_movement_name = 12;
    public static readonly RULE_movement = 13;
    public static readonly RULE_comment = 14;
    public static readonly RULE_workout_iden = 15;
    public static readonly RULE_workout_attr_id = 16;
    public static readonly RULE_workout_attr_val_str = 17;
    public static readonly RULE_workout_attr_val_number = 18;
    public static readonly RULE_workout_attr_val_time = 19;
    public static readonly RULE_workout_attr_val = 20;
    public static readonly RULE_workout_attribute = 21;
    public static readonly RULE_workout_comment = 22;
    public static readonly RULE_workout = 23;
    public static readonly RULE_shorthand_strength = 24;
    public static readonly RULE_shorthand_generic = 25;
    public static readonly RULE_shorthand_fortime = 26;
    public static readonly RULE_intermediate_movement = 27;
    public static readonly RULE_final_movement = 28;
    public static readonly RULE_shorthand_amrap = 29;
    public static readonly RULE_any_workout = 30;
    public static readonly RULE_alternation = 31;
    public static readonly RULE_option_block_title = 32;
    public static readonly RULE_option_title = 33;
    public static readonly RULE_option = 34;
    public static readonly RULE_option_block = 35;
    public static readonly RULE_emom = 36;
    public static readonly RULE_wod = 37;
    public static readonly RULE_def_id = 38;
    public static readonly RULE_def_super_kw = 39;
    public static readonly RULE_def_super_param = 40;
    public static readonly RULE_def_super = 41;
    public static readonly RULE_def_param_name = 42;
    public static readonly RULE_def_param_enum_entry = 43;
    public static readonly RULE_def_param_enum = 44;
    public static readonly RULE_def_param_type = 45;
    public static readonly RULE_def_param = 46;
    public static readonly RULE_definition = 47;
    public static readonly RULE_defs = 48;
    public static readonly RULE_new_movement_name = 49;
    public static readonly RULE_new_movement_params = 50;
    public static readonly RULE_new_movement = 51;
    public static readonly RULE_day = 52;

    public static readonly literalNames = [
        null, null, null, null, null, null, "'into'", null, null, null, 
        null, null, "'define'", "'newMovement'", null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, "'M'", 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, "'?'", null, 
        null, null, null, null, null, null, "'enum'", null, null, null, 
        null, null, "';'"
    ];

    public static readonly symbolicNames = [
        null, "WS", "NL", "NUMBER", "FORTIME_KW", "WORKOUT_SEPARATOR", "INTO_KW", 
        "MOVEMENT_MARKER", "CHOICE_KW", "OPTION_KW", "EMOM_KW", "WOD_KW", 
        "DEF_KW", "NEW_MOV_KW", "REST_KW", "MIN_KW", "STRING", "GENERIC_STRING", 
        "PAREN_L", "LBRACE", "RBRACE", "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", 
        "WORKOUT_IDENTIFIER", "GENERIC_SEPARATOR", "ERROR_CHAR", "WORKOUT_WS", 
        "WORKOUT_PAREN_L", "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", 
        "WORKOUT_QUOTE", "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", 
        "WORKOUT_NUMBER", "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "CHOICE_WS", 
        "CHOICE_NAME", "MOVEMENT_NAME_REP_MARKER", "SIMPLE_MOVEMENT", "MOVEMENT_NAME_REPS", 
        "MOVEMENT_NAME_COMPOENENT_SEPARATOR", "COMPLEX_MOVEMENT", "MOVEMENT_NAME_TERMINATOR", 
        "MOVEMENT_NAME_WS", "MC_WS", "MC_NUMBER", "MC_MAX", "AMRAP_UNIT_KW", 
        "MC_PAREN_L", "MC_PAREN_R", "MC_DASH", "MC_CAL_KW", "MC_TIME", "MC_SET_SEPARATOR", 
        "LOAD_MARKER", "TEMPO_MARKER", "DURATION_MARKER", "DISTANCE_MARKER", 
        "MOVEMENT_TERM", "MC_CONTENT", "MOV_ERR", "DEF_WS", "DEF_COMMENT", 
        "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", "DEF_COLON", "DEF_OPTIONAL", 
        "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", "DEF_BRACE_L", "DEF_BRACE_R", 
        "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", "NEW_MOV_WS", "NEW_MOV_QUOTE", 
        "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", "NEW_MOV_COMMA", "NEW_MOV_SEMI", 
        "NEW_MOV_PARAM", "NUMBER_WS", "ROUNDS_WORD", "ALT_KW", "FORTIME_SET_KW", 
        "TIME_UNIT", "NUMBER_CONTEXT_ERR", "FORTIME_WS", "FORTIME_ERR", 
        "AMRAP_WS", "AMRAP_KW", "AMRAP_ERR", "EMOM_X_SEP", "EMOM_DASH", 
        "EMOM_COLON", "EMOM_MIN_KW", "EMOM_NUM", "EMOM_WS", "EMOM_NL", "EMOM_ERR"
    ];
    public static readonly ruleNames = [
        "error", "mov_time", "sets", "reps", "rep_value", "amrap_value", 
        "calories", "load", "tempo", "duration", "distance", "complex_movement", 
        "movement_name", "movement", "comment", "workout_iden", "workout_attr_id", 
        "workout_attr_val_str", "workout_attr_val_number", "workout_attr_val_time", 
        "workout_attr_val", "workout_attribute", "workout_comment", "workout", 
        "shorthand_strength", "shorthand_generic", "shorthand_fortime", 
        "intermediate_movement", "final_movement", "shorthand_amrap", "any_workout", 
        "alternation", "option_block_title", "option_title", "option", "option_block", 
        "emom", "wod", "def_id", "def_super_kw", "def_super_param", "def_super", 
        "def_param_name", "def_param_enum_entry", "def_param_enum", "def_param_type", 
        "def_param", "definition", "defs", "new_movement_name", "new_movement_params", 
        "new_movement", "day",
    ];

    public get grammarFileName(): string { return "UniversalWorkoutLanguageParser.g4"; }
    public get literalNames(): (string | null)[] { return UniversalWorkoutLanguageParser.literalNames; }
    public get symbolicNames(): (string | null)[] { return UniversalWorkoutLanguageParser.symbolicNames; }
    public get ruleNames(): string[] { return UniversalWorkoutLanguageParser.ruleNames; }
    public get serializedATN(): number[] { return UniversalWorkoutLanguageParser._serializedATN; }

    protected createFailedPredicateException(predicate?: string, message?: string): antlr.FailedPredicateException {
        return new antlr.FailedPredicateException(this, predicate, message);
    }

    public constructor(input: antlr.TokenStream) {
        super(input);
        this.interpreter = new antlr.ParserATNSimulator(this, UniversalWorkoutLanguageParser._ATN, UniversalWorkoutLanguageParser.decisionsToDFA, new antlr.PredictionContextCache());
    }
    public error(): ErrorContext {
        let localContext = new ErrorContext(this.context, this.state);
        this.enterRule(localContext, 0, UniversalWorkoutLanguageParser.RULE_error);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 106;
            this.match(UniversalWorkoutLanguageParser.ERROR_CHAR);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public mov_time(): Mov_timeContext {
        let localContext = new Mov_timeContext(this.context, this.state);
        this.enterRule(localContext, 2, UniversalWorkoutLanguageParser.RULE_mov_time);
        try {
            this.state = 112;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.MC_TIME:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 108;
                this.match(UniversalWorkoutLanguageParser.MC_TIME);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_MAX:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 109;
                this.match(UniversalWorkoutLanguageParser.MC_MAX);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_NUMBER:
                this.enterOuterAlt(localContext, 3);
                {
                {
                this.state = 110;
                this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                this.state = 111;
                this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
                }
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public sets(): SetsContext {
        let localContext = new SetsContext(this.context, this.state);
        this.enterRule(localContext, 4, UniversalWorkoutLanguageParser.RULE_sets);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 114;
            this.match(UniversalWorkoutLanguageParser.LOAD_MARKER);
            this.state = 115;
            localContext._sets_number = this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
            this.state = 120;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 57) {
                {
                {
                this.state = 116;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 117;
                localContext._sets_number = this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                }
                }
                this.state = 122;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public reps(): RepsContext {
        let localContext = new RepsContext(this.context, this.state);
        this.enterRule(localContext, 6, UniversalWorkoutLanguageParser.RULE_reps);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 123;
            localContext._rep_value = this.rep_value();
            localContext._values.push(localContext._rep_value!);
            this.state = 128;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 57) {
                {
                {
                this.state = 124;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 125;
                localContext._rep_value = this.rep_value();
                localContext._values.push(localContext._rep_value!);
                }
                }
                this.state = 130;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public rep_value(): Rep_valueContext {
        let localContext = new Rep_valueContext(this.context, this.state);
        this.enterRule(localContext, 8, UniversalWorkoutLanguageParser.RULE_rep_value);
        try {
            this.state = 134;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.MC_NUMBER:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 131;
                this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_MAX:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 132;
                this.match(UniversalWorkoutLanguageParser.MC_MAX);
                }
                break;
            case UniversalWorkoutLanguageParser.AMRAP_UNIT_KW:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 133;
                this.amrap_value();
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public amrap_value(): Amrap_valueContext {
        let localContext = new Amrap_valueContext(this.context, this.state);
        this.enterRule(localContext, 10, UniversalWorkoutLanguageParser.RULE_amrap_value);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 136;
            this.match(UniversalWorkoutLanguageParser.AMRAP_UNIT_KW);
            this.state = 144;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 52) {
                {
                this.state = 137;
                this.match(UniversalWorkoutLanguageParser.MC_PAREN_L);
                this.state = 141;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MC_DASH:
                    {
                    this.state = 138;
                    this.match(UniversalWorkoutLanguageParser.MC_DASH);
                    this.state = 139;
                    this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                    }
                    break;
                case UniversalWorkoutLanguageParser.MC_NUMBER:
                    {
                    this.state = 140;
                    this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                this.state = 143;
                this.match(UniversalWorkoutLanguageParser.MC_PAREN_R);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public calories(): CaloriesContext {
        let localContext = new CaloriesContext(this.context, this.state);
        this.enterRule(localContext, 12, UniversalWorkoutLanguageParser.RULE_calories);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 146;
            localContext.__tset129 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 49 || _la === 50)) {
                localContext.__tset129 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._cals.push(localContext.__tset129!);
            this.state = 151;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 57) {
                {
                {
                this.state = 147;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 148;
                localContext.__tset140 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 49 || _la === 50)) {
                    localContext.__tset140 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._cals.push(localContext.__tset140!);
                }
                }
                this.state = 153;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 154;
            this.match(UniversalWorkoutLanguageParser.MC_CAL_KW);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public load(): LoadContext {
        let localContext = new LoadContext(this.context, this.state);
        this.enterRule(localContext, 14, UniversalWorkoutLanguageParser.RULE_load);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 156;
            this.match(UniversalWorkoutLanguageParser.LOAD_MARKER);
            this.state = 157;
            localContext.__tset158 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 49 || _la === 50)) {
                localContext.__tset158 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._loads.push(localContext.__tset158!);
            this.state = 162;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 57) {
                {
                {
                this.state = 158;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 159;
                localContext.__tset171 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 49 || _la === 50)) {
                    localContext.__tset171 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._loads.push(localContext.__tset171!);
                }
                }
                this.state = 164;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 165;
            localContext._load_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public tempo(): TempoContext {
        let localContext = new TempoContext(this.context, this.state);
        this.enterRule(localContext, 16, UniversalWorkoutLanguageParser.RULE_tempo);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 167;
            this.match(UniversalWorkoutLanguageParser.TEMPO_MARKER);
            this.state = 168;
            localContext.__tset191 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 49 || _la === 63)) {
                localContext.__tset191 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._tempos.push(localContext.__tset191!);
            this.state = 173;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 57) {
                {
                {
                this.state = 169;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 170;
                localContext.__tset204 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 49 || _la === 63)) {
                    localContext.__tset204 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._tempos.push(localContext.__tset204!);
                }
                }
                this.state = 175;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public duration(): DurationContext {
        let localContext = new DurationContext(this.context, this.state);
        this.enterRule(localContext, 18, UniversalWorkoutLanguageParser.RULE_duration);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 176;
            this.match(UniversalWorkoutLanguageParser.DURATION_MARKER);
            this.state = 177;
            localContext._mov_time = this.mov_time();
            localContext._durations.push(localContext._mov_time!);
            this.state = 182;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 57) {
                {
                {
                this.state = 178;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 179;
                localContext._mov_time = this.mov_time();
                localContext._durations.push(localContext._mov_time!);
                }
                }
                this.state = 184;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public distance(): DistanceContext {
        let localContext = new DistanceContext(this.context, this.state);
        this.enterRule(localContext, 20, UniversalWorkoutLanguageParser.RULE_distance);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 185;
            this.match(UniversalWorkoutLanguageParser.DISTANCE_MARKER);
            this.state = 186;
            localContext.__tset241 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 49 || _la === 50)) {
                localContext.__tset241 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._distances.push(localContext.__tset241!);
            this.state = 191;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 57) {
                {
                {
                this.state = 187;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 188;
                localContext.__tset252 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 49 || _la === 50)) {
                    localContext.__tset252 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._distances.push(localContext.__tset252!);
                }
                }
                this.state = 193;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 194;
            localContext._distance_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public complex_movement(): Complex_movementContext {
        let localContext = new Complex_movementContext(this.context, this.state);
        this.enterRule(localContext, 22, UniversalWorkoutLanguageParser.RULE_complex_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 196;
            localContext._MOVEMENT_NAME_REPS = this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
            localContext._mov_reps.push(localContext._MOVEMENT_NAME_REPS!);
            this.state = 197;
            localContext._SIMPLE_MOVEMENT = this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
            localContext._mov_name.push(localContext._SIMPLE_MOVEMENT!);
            this.state = 203;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 44) {
                {
                {
                this.state = 198;
                this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR);
                this.state = 199;
                localContext._MOVEMENT_NAME_REPS = this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
                localContext._mov_reps.push(localContext._MOVEMENT_NAME_REPS!);
                this.state = 200;
                localContext._SIMPLE_MOVEMENT = this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
                localContext._mov_name.push(localContext._SIMPLE_MOVEMENT!);
                }
                }
                this.state = 205;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public movement_name(): Movement_nameContext {
        let localContext = new Movement_nameContext(this.context, this.state);
        this.enterRule(localContext, 24, UniversalWorkoutLanguageParser.RULE_movement_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 208;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT:
                {
                this.state = 206;
                this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
                }
                break;
            case UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS:
                {
                this.state = 207;
                this.complex_movement();
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public movement(): MovementContext {
        let localContext = new MovementContext(this.context, this.state);
        this.enterRule(localContext, 26, UniversalWorkoutLanguageParser.RULE_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 210;
            this.match(UniversalWorkoutLanguageParser.MOVEMENT_MARKER);
            this.state = 211;
            this.movement_name();
            this.state = 212;
            this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_TERMINATOR);
            this.state = 226;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (((((_la - 49)) & ~0x1F) === 0 && ((1 << (_la - 49)) & 7687) !== 0)) {
                {
                this.state = 222;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                do {
                    {
                    {
                    this.state = 220;
                    this.errorHandler.sync(this);
                    switch (this.interpreter.adaptivePredict(this.tokenStream, 13, this.context) ) {
                    case 1:
                        {
                        this.state = 213;
                        this.reps();
                        }
                        break;
                    case 2:
                        {
                        this.state = 214;
                        this.load();
                        }
                        break;
                    case 3:
                        {
                        this.state = 215;
                        this.tempo();
                        }
                        break;
                    case 4:
                        {
                        this.state = 216;
                        this.duration();
                        }
                        break;
                    case 5:
                        {
                        this.state = 217;
                        this.distance();
                        }
                        break;
                    case 6:
                        {
                        this.state = 218;
                        this.sets();
                        }
                        break;
                    case 7:
                        {
                        this.state = 219;
                        this.calories();
                        }
                        break;
                    }
                    }
                    }
                    this.state = 224;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                } while (((((_la - 49)) & ~0x1F) === 0 && ((1 << (_la - 49)) & 7687) !== 0));
                }
            }

            this.state = 229;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            do {
                {
                {
                this.state = 228;
                this.match(UniversalWorkoutLanguageParser.MOVEMENT_TERM);
                }
                }
                this.state = 231;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            } while (_la === 62);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public comment(): CommentContext {
        let localContext = new CommentContext(this.context, this.state);
        this.enterRule(localContext, 28, UniversalWorkoutLanguageParser.RULE_comment);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 233;
            _la = this.tokenStream.LA(1);
            if(!(_la === 22 || _la === 23)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_iden(): Workout_idenContext {
        let localContext = new Workout_idenContext(this.context, this.state);
        this.enterRule(localContext, 30, UniversalWorkoutLanguageParser.RULE_workout_iden);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 235;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_id(): Workout_attr_idContext {
        let localContext = new Workout_attr_idContext(this.context, this.state);
        this.enterRule(localContext, 32, UniversalWorkoutLanguageParser.RULE_workout_attr_id);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 237;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_IDEN);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_str(): Workout_attr_val_strContext {
        let localContext = new Workout_attr_val_strContext(this.context, this.state);
        this.enterRule(localContext, 34, UniversalWorkoutLanguageParser.RULE_workout_attr_val_str);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 239;
            _la = this.tokenStream.LA(1);
            if(!(_la === 33 || _la === 34)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_number(): Workout_attr_val_numberContext {
        let localContext = new Workout_attr_val_numberContext(this.context, this.state);
        this.enterRule(localContext, 36, UniversalWorkoutLanguageParser.RULE_workout_attr_val_number);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 241;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_NUMBER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_time(): Workout_attr_val_timeContext {
        let localContext = new Workout_attr_val_timeContext(this.context, this.state);
        this.enterRule(localContext, 38, UniversalWorkoutLanguageParser.RULE_workout_attr_val_time);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 243;
            _la = this.tokenStream.LA(1);
            if(!(_la === 35 || _la === 37)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val(): Workout_attr_valContext {
        let localContext = new Workout_attr_valContext(this.context, this.state);
        this.enterRule(localContext, 40, UniversalWorkoutLanguageParser.RULE_workout_attr_val);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 245;
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 33)) & ~0x1F) === 0 && ((1 << (_la - 33)) & 31) !== 0))) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attribute(): Workout_attributeContext {
        let localContext = new Workout_attributeContext(this.context, this.state);
        this.enterRule(localContext, 42, UniversalWorkoutLanguageParser.RULE_workout_attribute);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 247;
            this.workout_attr_id();
            this.state = 248;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_COLON);
            this.state = 249;
            this.workout_attr_val();
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_comment(): Workout_commentContext {
        let localContext = new Workout_commentContext(this.context, this.state);
        this.enterRule(localContext, 44, UniversalWorkoutLanguageParser.RULE_workout_comment);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 251;
            _la = this.tokenStream.LA(1);
            if(!(_la === 22 || _la === 23)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout(): WorkoutContext {
        let localContext = new WorkoutContext(this.context, this.state);
        this.enterRule(localContext, 46, UniversalWorkoutLanguageParser.RULE_workout);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 253;
            this.workout_iden();
            this.state = 255;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 33) {
                {
                this.state = 254;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_STRING);
                }
            }

            this.state = 257;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_L);
            this.state = 259;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 38) {
                {
                this.state = 258;
                localContext._workout_attribute = this.workout_attribute();
                localContext._attrs.push(localContext._workout_attribute!);
                }
            }

            this.state = 265;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 19, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 261;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                    this.state = 262;
                    localContext._workout_attribute = this.workout_attribute();
                    localContext._attrs.push(localContext._workout_attribute!);
                    }
                    }
                }
                this.state = 267;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 19, this.context);
            }
            this.state = 269;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 31) {
                {
                this.state = 268;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                }
            }

            this.state = 271;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R);
            this.state = 276;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 12583040) !== 0)) {
                {
                this.state = 274;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 272;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 273;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 278;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_strength(): Shorthand_strengthContext {
        let localContext = new Shorthand_strengthContext(this.context, this.state);
        this.enterRule(localContext, 48, UniversalWorkoutLanguageParser.RULE_shorthand_strength);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 280;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 16) {
                {
                this.state = 279;
                localContext._name = this.match(UniversalWorkoutLanguageParser.STRING);
                }
            }

            this.state = 284;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 14) {
                {
                this.state = 282;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 283;
                localContext._STRING = this.match(UniversalWorkoutLanguageParser.STRING);
                localContext._rest.push(localContext._STRING!);
                }
            }

            this.state = 286;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 293;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 12599424) !== 0)) {
                {
                this.state = 291;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.REST_KW:
                    {
                    {
                    this.state = 287;
                    this.match(UniversalWorkoutLanguageParser.REST_KW);
                    this.state = 288;
                    localContext._STRING = this.match(UniversalWorkoutLanguageParser.STRING);
                    localContext._rest.push(localContext._STRING!);
                    }
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 289;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 290;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 295;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_generic(): Shorthand_genericContext {
        let localContext = new Shorthand_genericContext(this.context, this.state);
        this.enterRule(localContext, 50, UniversalWorkoutLanguageParser.RULE_shorthand_generic);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 297;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 16) {
                {
                this.state = 296;
                this.match(UniversalWorkoutLanguageParser.STRING);
                }
            }

            this.state = 299;
            this.match(UniversalWorkoutLanguageParser.GENERIC_STRING);
            this.state = 315;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 18) {
                {
                this.state = 300;
                this.match(UniversalWorkoutLanguageParser.PAREN_L);
                this.state = 302;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 38) {
                    {
                    this.state = 301;
                    localContext._workout_attribute = this.workout_attribute();
                    localContext._attrs.push(localContext._workout_attribute!);
                    }
                }

                this.state = 308;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 29, this.context);
                while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                    if (alternative === 1) {
                        {
                        {
                        this.state = 304;
                        this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                        this.state = 305;
                        localContext._workout_attribute = this.workout_attribute();
                        localContext._attrs.push(localContext._workout_attribute!);
                        }
                        }
                    }
                    this.state = 310;
                    this.errorHandler.sync(this);
                    alternative = this.interpreter.adaptivePredict(this.tokenStream, 29, this.context);
                }
                this.state = 312;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 31) {
                    {
                    this.state = 311;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                    }
                }

                this.state = 314;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R);
                }
            }

            this.state = 321;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 12583040) !== 0)) {
                {
                this.state = 319;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 317;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 318;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 323;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_fortime(): Shorthand_fortimeContext {
        let localContext = new Shorthand_fortimeContext(this.context, this.state);
        this.enterRule(localContext, 52, UniversalWorkoutLanguageParser.RULE_shorthand_fortime);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 326;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 3) {
                {
                this.state = 324;
                localContext._rounds_num = this.match(UniversalWorkoutLanguageParser.NUMBER);
                this.state = 325;
                localContext._rounds_word = this.match(UniversalWorkoutLanguageParser.ROUNDS_WORD);
                }
            }

            this.state = 328;
            this.match(UniversalWorkoutLanguageParser.FORTIME_KW);
            {
            this.state = 332;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 35, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 329;
                    this.intermediate_movement();
                    }
                    }
                }
                this.state = 334;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 35, this.context);
            }
            }
            this.state = 335;
            this.final_movement();
            this.state = 338;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 3) {
                {
                this.state = 336;
                localContext._sets_num = this.match(UniversalWorkoutLanguageParser.NUMBER);
                this.state = 337;
                this.match(UniversalWorkoutLanguageParser.FORTIME_SET_KW);
                }
            }

            this.state = 343;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 22 || _la === 23) {
                {
                {
                this.state = 340;
                localContext._workout_comment = this.workout_comment();
                localContext._comments.push(localContext._workout_comment!);
                }
                }
                this.state = 345;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public intermediate_movement(): Intermediate_movementContext {
        let localContext = new Intermediate_movementContext(this.context, this.state);
        this.enterRule(localContext, 54, UniversalWorkoutLanguageParser.RULE_intermediate_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 346;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 349;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 14) {
                {
                this.state = 347;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 348;
                localContext._STRING = this.match(UniversalWorkoutLanguageParser.STRING);
                localContext._rest.push(localContext._STRING!);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public final_movement(): Final_movementContext {
        let localContext = new Final_movementContext(this.context, this.state);
        this.enterRule(localContext, 56, UniversalWorkoutLanguageParser.RULE_final_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 351;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 358;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 14) {
                {
                this.state = 352;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 356;
                this.errorHandler.sync(this);
                switch (this.interpreter.adaptivePredict(this.tokenStream, 39, this.context) ) {
                case 1:
                    {
                    this.state = 353;
                    localContext._component_rest = this.match(UniversalWorkoutLanguageParser.STRING);
                    this.state = 354;
                    this.match(UniversalWorkoutLanguageParser.INTO_KW);
                    }
                    break;
                case 2:
                    {
                    this.state = 355;
                    localContext._round_rest = this.match(UniversalWorkoutLanguageParser.STRING);
                    }
                    break;
                }
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_amrap(): Shorthand_amrapContext {
        let localContext = new Shorthand_amrapContext(this.context, this.state);
        this.enterRule(localContext, 58, UniversalWorkoutLanguageParser.RULE_shorthand_amrap);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 360;
            this.match(UniversalWorkoutLanguageParser.NUMBER);
            this.state = 361;
            this.match(UniversalWorkoutLanguageParser.TIME_UNIT);
            this.state = 362;
            this.match(UniversalWorkoutLanguageParser.AMRAP_KW);
            this.state = 363;
            localContext._movement = this.movement();
            localContext._movements.push(localContext._movement!);
            this.state = 368;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 12583040) !== 0)) {
                {
                this.state = 366;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 364;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 365;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 370;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public any_workout(): Any_workoutContext {
        let localContext = new Any_workoutContext(this.context, this.state);
        this.enterRule(localContext, 60, UniversalWorkoutLanguageParser.RULE_any_workout);
        try {
            this.state = 377;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 43, this.context) ) {
            case 1:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 371;
                this.workout();
                }
                break;
            case 2:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 372;
                this.shorthand_strength();
                }
                break;
            case 3:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 373;
                this.shorthand_generic();
                }
                break;
            case 4:
                this.enterOuterAlt(localContext, 4);
                {
                this.state = 374;
                this.shorthand_fortime();
                }
                break;
            case 5:
                this.enterOuterAlt(localContext, 5);
                {
                this.state = 375;
                this.shorthand_amrap();
                }
                break;
            case 6:
                this.enterOuterAlt(localContext, 6);
                {
                this.state = 376;
                this.emom();
                }
                break;
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public alternation(): AlternationContext {
        let localContext = new AlternationContext(this.context, this.state);
        this.enterRule(localContext, 62, UniversalWorkoutLanguageParser.RULE_alternation);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 379;
            this.match(UniversalWorkoutLanguageParser.NUMBER);
            this.state = 380;
            this.match(UniversalWorkoutLanguageParser.ALT_KW);
            this.state = 381;
            this.match(UniversalWorkoutLanguageParser.LBRACE);
            this.state = 383;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 16991384) !== 0)) {
                {
                this.state = 382;
                localContext._any_workout = this.any_workout();
                localContext._workouts.push(localContext._any_workout!);
                }
            }

            this.state = 389;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 5) {
                {
                {
                this.state = 385;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                this.state = 386;
                localContext._any_workout = this.any_workout();
                localContext._workouts.push(localContext._any_workout!);
                }
                }
                this.state = 391;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 392;
            this.match(UniversalWorkoutLanguageParser.RBRACE);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_block_title(): Option_block_titleContext {
        let localContext = new Option_block_titleContext(this.context, this.state);
        this.enterRule(localContext, 64, UniversalWorkoutLanguageParser.RULE_option_block_title);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 394;
            this.match(UniversalWorkoutLanguageParser.CHOICE_KW);
            this.state = 396;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 40) {
                {
                this.state = 395;
                this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_title(): Option_titleContext {
        let localContext = new Option_titleContext(this.context, this.state);
        this.enterRule(localContext, 66, UniversalWorkoutLanguageParser.RULE_option_title);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 398;
            this.match(UniversalWorkoutLanguageParser.OPTION_KW);
            this.state = 399;
            this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option(): OptionContext {
        let localContext = new OptionContext(this.context, this.state);
        this.enterRule(localContext, 68, UniversalWorkoutLanguageParser.RULE_option);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 401;
            this.match(UniversalWorkoutLanguageParser.OPTION_KW);
            this.state = 402;
            this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
            this.state = 403;
            this.match(UniversalWorkoutLanguageParser.COLON);
            this.state = 406;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 47, this.context) ) {
            case 1:
                {
                this.state = 404;
                this.any_workout();
                }
                break;
            case 2:
                {
                this.state = 405;
                this.alternation();
                }
                break;
            }
            this.state = 415;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 49, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 408;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                    this.state = 411;
                    this.errorHandler.sync(this);
                    switch (this.interpreter.adaptivePredict(this.tokenStream, 48, this.context) ) {
                    case 1:
                        {
                        this.state = 409;
                        this.any_workout();
                        }
                        break;
                    case 2:
                        {
                        this.state = 410;
                        this.alternation();
                        }
                        break;
                    }
                    }
                    }
                }
                this.state = 417;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 49, this.context);
            }
            this.state = 419;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 5) {
                {
                this.state = 418;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_block(): Option_blockContext {
        let localContext = new Option_blockContext(this.context, this.state);
        this.enterRule(localContext, 70, UniversalWorkoutLanguageParser.RULE_option_block);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 421;
            this.match(UniversalWorkoutLanguageParser.CHOICE_KW);
            this.state = 423;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 40) {
                {
                this.state = 422;
                this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
                }
            }

            this.state = 425;
            this.match(UniversalWorkoutLanguageParser.LBRACE);
            this.state = 427;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            do {
                {
                {
                this.state = 426;
                localContext._option = this.option();
                localContext._options.push(localContext._option!);
                }
                }
                this.state = 429;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            } while (_la === 9);
            this.state = 431;
            this.match(UniversalWorkoutLanguageParser.RBRACE);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public emom(): EmomContext {
        let localContext = new EmomContext(this.context, this.state);
        this.enterRule(localContext, 72, UniversalWorkoutLanguageParser.RULE_emom);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 433;
            this.match(UniversalWorkoutLanguageParser.EMOM_KW);
            this.state = 435;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 98) {
                {
                this.state = 434;
                this.match(UniversalWorkoutLanguageParser.EMOM_X_SEP);
                }
            }

            this.state = 437;
            localContext._emom_low_bound = this.match(UniversalWorkoutLanguageParser.EMOM_NUM);
            this.state = 440;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 99) {
                {
                this.state = 438;
                this.match(UniversalWorkoutLanguageParser.EMOM_DASH);
                this.state = 439;
                localContext._emom_high_bound = this.match(UniversalWorkoutLanguageParser.EMOM_NUM);
                }
            }

            this.state = 443;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 101) {
                {
                this.state = 442;
                this.match(UniversalWorkoutLanguageParser.EMOM_MIN_KW);
                }
            }

            this.state = 445;
            this.match(UniversalWorkoutLanguageParser.EMOM_COLON);
            this.state = 462;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 15) {
                {
                {
                this.state = 446;
                this.match(UniversalWorkoutLanguageParser.MIN_KW);
                this.state = 450;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                while (_la === 22 || _la === 23) {
                    {
                    {
                    this.state = 447;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    }
                    this.state = 452;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                }
                this.state = 453;
                localContext._movement = this.movement();
                localContext._movements.push(localContext._movement!);
                this.state = 457;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                while (_la === 22 || _la === 23) {
                    {
                    {
                    this.state = 454;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    }
                    this.state = 459;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                }
                }
                }
                this.state = 464;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public wod(): WodContext {
        let localContext = new WodContext(this.context, this.state);
        this.enterRule(localContext, 74, UniversalWorkoutLanguageParser.RULE_wod);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 474;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 60, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 468;
                    this.errorHandler.sync(this);
                    switch (this.interpreter.adaptivePredict(this.tokenStream, 59, this.context) ) {
                    case 1:
                        {
                        this.state = 465;
                        this.any_workout();
                        }
                        break;
                    case 2:
                        {
                        this.state = 466;
                        this.option_block();
                        }
                        break;
                    case 3:
                        {
                        this.state = 467;
                        this.alternation();
                        }
                        break;
                    }
                    this.state = 470;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                    }
                    }
                }
                this.state = 476;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 60, this.context);
            }
            this.state = 480;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 61, this.context) ) {
            case 1:
                {
                this.state = 477;
                this.any_workout();
                }
                break;
            case 2:
                {
                this.state = 478;
                this.option_block();
                }
                break;
            case 3:
                {
                this.state = 479;
                this.alternation();
                }
                break;
            }
            this.state = 483;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 5) {
                {
                this.state = 482;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_id(): Def_idContext {
        let localContext = new Def_idContext(this.context, this.state);
        this.enterRule(localContext, 76, UniversalWorkoutLanguageParser.RULE_def_id);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 485;
            this.match(UniversalWorkoutLanguageParser.DEF_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super_kw(): Def_super_kwContext {
        let localContext = new Def_super_kwContext(this.context, this.state);
        this.enterRule(localContext, 78, UniversalWorkoutLanguageParser.RULE_def_super_kw);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 487;
            this.match(UniversalWorkoutLanguageParser.DEF_SUPER_KW);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super_param(): Def_super_paramContext {
        let localContext = new Def_super_paramContext(this.context, this.state);
        this.enterRule(localContext, 80, UniversalWorkoutLanguageParser.RULE_def_super_param);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 489;
            this.match(UniversalWorkoutLanguageParser.DEF_REGEX);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super(): Def_superContext {
        let localContext = new Def_superContext(this.context, this.state);
        this.enterRule(localContext, 82, UniversalWorkoutLanguageParser.RULE_def_super);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 491;
            this.def_super_kw();
            this.state = 492;
            this.match(UniversalWorkoutLanguageParser.DEF_PAREN_L);
            this.state = 494;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 67) {
                {
                this.state = 493;
                this.def_super_param();
                }
            }

            this.state = 496;
            this.match(UniversalWorkoutLanguageParser.DEF_PAREN_R);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_name(): Def_param_nameContext {
        let localContext = new Def_param_nameContext(this.context, this.state);
        this.enterRule(localContext, 84, UniversalWorkoutLanguageParser.RULE_def_param_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 498;
            this.match(UniversalWorkoutLanguageParser.DEF_REGEX);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_enum_entry(): Def_param_enum_entryContext {
        let localContext = new Def_param_enum_entryContext(this.context, this.state);
        this.enterRule(localContext, 86, UniversalWorkoutLanguageParser.RULE_def_param_enum_entry);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 500;
            this.match(UniversalWorkoutLanguageParser.DEF_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_enum(): Def_param_enumContext {
        let localContext = new Def_param_enumContext(this.context, this.state);
        this.enterRule(localContext, 88, UniversalWorkoutLanguageParser.RULE_def_param_enum);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 502;
            localContext._def_param_enum_entry = this.def_param_enum_entry();
            localContext._def_param_enum_entries.push(localContext._def_param_enum_entry!);
            this.state = 507;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 72) {
                {
                {
                this.state = 503;
                this.match(UniversalWorkoutLanguageParser.DEF_COMMA);
                this.state = 504;
                localContext._def_param_enum_entry = this.def_param_enum_entry();
                localContext._def_param_enum_entries.push(localContext._def_param_enum_entry!);
                }
                }
                this.state = 509;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_type(): Def_param_typeContext {
        let localContext = new Def_param_typeContext(this.context, this.state);
        this.enterRule(localContext, 90, UniversalWorkoutLanguageParser.RULE_def_param_type);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 516;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.DEF_TYPES:
                {
                this.state = 510;
                this.match(UniversalWorkoutLanguageParser.DEF_TYPES);
                }
                break;
            case UniversalWorkoutLanguageParser.DEF_ENUM_KW:
                {
                {
                this.state = 511;
                this.match(UniversalWorkoutLanguageParser.DEF_ENUM_KW);
                this.state = 512;
                this.match(UniversalWorkoutLanguageParser.DEF_PAREN_L);
                this.state = 513;
                this.def_param_enum();
                this.state = 514;
                this.match(UniversalWorkoutLanguageParser.DEF_PAREN_R);
                }
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param(): Def_paramContext {
        let localContext = new Def_paramContext(this.context, this.state);
        this.enterRule(localContext, 92, UniversalWorkoutLanguageParser.RULE_def_param);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 518;
            this.def_param_name();
            this.state = 519;
            this.match(UniversalWorkoutLanguageParser.DEF_COLON);
            {
            this.state = 520;
            this.def_param_type();
            }
            this.state = 522;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 71) {
                {
                this.state = 521;
                this.match(UniversalWorkoutLanguageParser.DEF_OPTIONAL);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public definition(): DefinitionContext {
        let localContext = new DefinitionContext(this.context, this.state);
        this.enterRule(localContext, 94, UniversalWorkoutLanguageParser.RULE_definition);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 524;
            this.match(UniversalWorkoutLanguageParser.DEF_KW);
            this.state = 525;
            this.def_id();
            this.state = 528;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 70) {
                {
                this.state = 526;
                this.match(UniversalWorkoutLanguageParser.DEF_COLON);
                this.state = 527;
                this.def_super();
                }
            }

            this.state = 530;
            this.match(UniversalWorkoutLanguageParser.DEF_BRACE_L);
            this.state = 531;
            this.def_param();
            this.state = 536;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 72) {
                {
                {
                this.state = 532;
                this.match(UniversalWorkoutLanguageParser.DEF_COMMA);
                this.state = 533;
                this.def_param();
                }
                }
                this.state = 538;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 539;
            this.match(UniversalWorkoutLanguageParser.DEF_BRACE_R);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public defs(): DefsContext {
        let localContext = new DefsContext(this.context, this.state);
        this.enterRule(localContext, 96, UniversalWorkoutLanguageParser.RULE_defs);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 547;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 12294) !== 0)) {
                {
                this.state = 545;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.DEF_KW:
                    {
                    this.state = 541;
                    this.definition();
                    }
                    break;
                case UniversalWorkoutLanguageParser.NEW_MOV_KW:
                    {
                    this.state = 542;
                    this.new_movement();
                    }
                    break;
                case UniversalWorkoutLanguageParser.WS:
                    {
                    this.state = 543;
                    this.match(UniversalWorkoutLanguageParser.WS);
                    }
                    break;
                case UniversalWorkoutLanguageParser.NL:
                    {
                    this.state = 544;
                    this.match(UniversalWorkoutLanguageParser.NL);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 549;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 550;
            this.match(UniversalWorkoutLanguageParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement_name(): New_movement_nameContext {
        let localContext = new New_movement_nameContext(this.context, this.state);
        this.enterRule(localContext, 98, UniversalWorkoutLanguageParser.RULE_new_movement_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 552;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement_params(): New_movement_paramsContext {
        let localContext = new New_movement_paramsContext(this.context, this.state);
        this.enterRule(localContext, 100, UniversalWorkoutLanguageParser.RULE_new_movement_params);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 554;
            localContext._NEW_MOV_PARAM = this.match(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
            localContext._nmp.push(localContext._NEW_MOV_PARAM!);
            {
            this.state = 555;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_COMMA);
            this.state = 556;
            localContext._NEW_MOV_PARAM = this.match(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
            localContext._nmp.push(localContext._NEW_MOV_PARAM!);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement(): New_movementContext {
        let localContext = new New_movementContext(this.context, this.state);
        this.enterRule(localContext, 102, UniversalWorkoutLanguageParser.RULE_new_movement);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 558;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_KW);
            this.state = 559;
            this.new_movement_name();
            this.state = 560;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_COLON);
            this.state = 561;
            this.new_movement_params();
            this.state = 562;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_SEMI);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public day(): DayContext {
        let localContext = new DayContext(this.context, this.state);
        this.enterRule(localContext, 104, UniversalWorkoutLanguageParser.RULE_day);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 565;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 11) {
                {
                this.state = 564;
                this.match(UniversalWorkoutLanguageParser.WOD_KW);
                }
            }

            this.state = 567;
            localContext._wod = this.wod();
            localContext._wods.push(localContext._wod!);
            this.state = 572;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 11) {
                {
                {
                this.state = 568;
                this.match(UniversalWorkoutLanguageParser.WOD_KW);
                this.state = 569;
                localContext._wod = this.wod();
                localContext._wods.push(localContext._wod!);
                }
                }
                this.state = 574;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 575;
            this.match(UniversalWorkoutLanguageParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }

    public static readonly _serializedATN: number[] = [
        4,1,105,578,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,
        7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,
        13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,
        20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,
        26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,
        33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,
        39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,
        46,7,46,2,47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,
        52,1,0,1,0,1,1,1,1,1,1,1,1,3,1,113,8,1,1,2,1,2,1,2,1,2,5,2,119,8,
        2,10,2,12,2,122,9,2,1,3,1,3,1,3,5,3,127,8,3,10,3,12,3,130,9,3,1,
        4,1,4,1,4,3,4,135,8,4,1,5,1,5,1,5,1,5,1,5,3,5,142,8,5,1,5,3,5,145,
        8,5,1,6,1,6,1,6,5,6,150,8,6,10,6,12,6,153,9,6,1,6,1,6,1,7,1,7,1,
        7,1,7,5,7,161,8,7,10,7,12,7,164,9,7,1,7,1,7,1,8,1,8,1,8,1,8,5,8,
        172,8,8,10,8,12,8,175,9,8,1,9,1,9,1,9,1,9,5,9,181,8,9,10,9,12,9,
        184,9,9,1,10,1,10,1,10,1,10,5,10,190,8,10,10,10,12,10,193,9,10,1,
        10,1,10,1,11,1,11,1,11,1,11,1,11,5,11,202,8,11,10,11,12,11,205,9,
        11,1,12,1,12,3,12,209,8,12,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,
        13,1,13,1,13,3,13,221,8,13,4,13,223,8,13,11,13,12,13,224,3,13,227,
        8,13,1,13,4,13,230,8,13,11,13,12,13,231,1,14,1,14,1,15,1,15,1,16,
        1,16,1,17,1,17,1,18,1,18,1,19,1,19,1,20,1,20,1,21,1,21,1,21,1,21,
        1,22,1,22,1,23,1,23,3,23,256,8,23,1,23,1,23,3,23,260,8,23,1,23,1,
        23,5,23,264,8,23,10,23,12,23,267,9,23,1,23,3,23,270,8,23,1,23,1,
        23,1,23,5,23,275,8,23,10,23,12,23,278,9,23,1,24,3,24,281,8,24,1,
        24,1,24,3,24,285,8,24,1,24,1,24,1,24,1,24,1,24,5,24,292,8,24,10,
        24,12,24,295,9,24,1,25,3,25,298,8,25,1,25,1,25,1,25,3,25,303,8,25,
        1,25,1,25,5,25,307,8,25,10,25,12,25,310,9,25,1,25,3,25,313,8,25,
        1,25,3,25,316,8,25,1,25,1,25,5,25,320,8,25,10,25,12,25,323,9,25,
        1,26,1,26,3,26,327,8,26,1,26,1,26,5,26,331,8,26,10,26,12,26,334,
        9,26,1,26,1,26,1,26,3,26,339,8,26,1,26,5,26,342,8,26,10,26,12,26,
        345,9,26,1,27,1,27,1,27,3,27,350,8,27,1,28,1,28,1,28,1,28,1,28,3,
        28,357,8,28,3,28,359,8,28,1,29,1,29,1,29,1,29,1,29,1,29,5,29,367,
        8,29,10,29,12,29,370,9,29,1,30,1,30,1,30,1,30,1,30,1,30,3,30,378,
        8,30,1,31,1,31,1,31,1,31,3,31,384,8,31,1,31,1,31,5,31,388,8,31,10,
        31,12,31,391,9,31,1,31,1,31,1,32,1,32,3,32,397,8,32,1,33,1,33,1,
        33,1,34,1,34,1,34,1,34,1,34,3,34,407,8,34,1,34,1,34,1,34,3,34,412,
        8,34,5,34,414,8,34,10,34,12,34,417,9,34,1,34,3,34,420,8,34,1,35,
        1,35,3,35,424,8,35,1,35,1,35,4,35,428,8,35,11,35,12,35,429,1,35,
        1,35,1,36,1,36,3,36,436,8,36,1,36,1,36,1,36,3,36,441,8,36,1,36,3,
        36,444,8,36,1,36,1,36,1,36,5,36,449,8,36,10,36,12,36,452,9,36,1,
        36,1,36,5,36,456,8,36,10,36,12,36,459,9,36,5,36,461,8,36,10,36,12,
        36,464,9,36,1,37,1,37,1,37,3,37,469,8,37,1,37,1,37,5,37,473,8,37,
        10,37,12,37,476,9,37,1,37,1,37,1,37,3,37,481,8,37,1,37,3,37,484,
        8,37,1,38,1,38,1,39,1,39,1,40,1,40,1,41,1,41,1,41,3,41,495,8,41,
        1,41,1,41,1,42,1,42,1,43,1,43,1,44,1,44,1,44,5,44,506,8,44,10,44,
        12,44,509,9,44,1,45,1,45,1,45,1,45,1,45,1,45,3,45,517,8,45,1,46,
        1,46,1,46,1,46,3,46,523,8,46,1,47,1,47,1,47,1,47,3,47,529,8,47,1,
        47,1,47,1,47,1,47,5,47,535,8,47,10,47,12,47,538,9,47,1,47,1,47,1,
        48,1,48,1,48,1,48,5,48,546,8,48,10,48,12,48,549,9,48,1,48,1,48,1,
        49,1,49,1,50,1,50,1,50,1,50,1,51,1,51,1,51,1,51,1,51,1,51,1,52,3,
        52,566,8,52,1,52,1,52,1,52,5,52,571,8,52,10,52,12,52,574,9,52,1,
        52,1,52,1,52,0,0,53,0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,
        34,36,38,40,42,44,46,48,50,52,54,56,58,60,62,64,66,68,70,72,74,76,
        78,80,82,84,86,88,90,92,94,96,98,100,102,104,0,6,1,0,49,50,2,0,49,
        49,63,63,1,0,22,23,1,0,33,34,2,0,35,35,37,37,1,0,33,37,613,0,106,
        1,0,0,0,2,112,1,0,0,0,4,114,1,0,0,0,6,123,1,0,0,0,8,134,1,0,0,0,
        10,136,1,0,0,0,12,146,1,0,0,0,14,156,1,0,0,0,16,167,1,0,0,0,18,176,
        1,0,0,0,20,185,1,0,0,0,22,196,1,0,0,0,24,208,1,0,0,0,26,210,1,0,
        0,0,28,233,1,0,0,0,30,235,1,0,0,0,32,237,1,0,0,0,34,239,1,0,0,0,
        36,241,1,0,0,0,38,243,1,0,0,0,40,245,1,0,0,0,42,247,1,0,0,0,44,251,
        1,0,0,0,46,253,1,0,0,0,48,280,1,0,0,0,50,297,1,0,0,0,52,326,1,0,
        0,0,54,346,1,0,0,0,56,351,1,0,0,0,58,360,1,0,0,0,60,377,1,0,0,0,
        62,379,1,0,0,0,64,394,1,0,0,0,66,398,1,0,0,0,68,401,1,0,0,0,70,421,
        1,0,0,0,72,433,1,0,0,0,74,474,1,0,0,0,76,485,1,0,0,0,78,487,1,0,
        0,0,80,489,1,0,0,0,82,491,1,0,0,0,84,498,1,0,0,0,86,500,1,0,0,0,
        88,502,1,0,0,0,90,516,1,0,0,0,92,518,1,0,0,0,94,524,1,0,0,0,96,547,
        1,0,0,0,98,552,1,0,0,0,100,554,1,0,0,0,102,558,1,0,0,0,104,565,1,
        0,0,0,106,107,5,26,0,0,107,1,1,0,0,0,108,113,5,56,0,0,109,113,5,
        50,0,0,110,111,5,49,0,0,111,113,5,63,0,0,112,108,1,0,0,0,112,109,
        1,0,0,0,112,110,1,0,0,0,113,3,1,0,0,0,114,115,5,58,0,0,115,120,5,
        49,0,0,116,117,5,57,0,0,117,119,5,49,0,0,118,116,1,0,0,0,119,122,
        1,0,0,0,120,118,1,0,0,0,120,121,1,0,0,0,121,5,1,0,0,0,122,120,1,
        0,0,0,123,128,3,8,4,0,124,125,5,57,0,0,125,127,3,8,4,0,126,124,1,
        0,0,0,127,130,1,0,0,0,128,126,1,0,0,0,128,129,1,0,0,0,129,7,1,0,
        0,0,130,128,1,0,0,0,131,135,5,49,0,0,132,135,5,50,0,0,133,135,3,
        10,5,0,134,131,1,0,0,0,134,132,1,0,0,0,134,133,1,0,0,0,135,9,1,0,
        0,0,136,144,5,51,0,0,137,141,5,52,0,0,138,139,5,54,0,0,139,142,5,
        49,0,0,140,142,5,49,0,0,141,138,1,0,0,0,141,140,1,0,0,0,142,143,
        1,0,0,0,143,145,5,53,0,0,144,137,1,0,0,0,144,145,1,0,0,0,145,11,
        1,0,0,0,146,151,7,0,0,0,147,148,5,57,0,0,148,150,7,0,0,0,149,147,
        1,0,0,0,150,153,1,0,0,0,151,149,1,0,0,0,151,152,1,0,0,0,152,154,
        1,0,0,0,153,151,1,0,0,0,154,155,5,55,0,0,155,13,1,0,0,0,156,157,
        5,58,0,0,157,162,7,0,0,0,158,159,5,57,0,0,159,161,7,0,0,0,160,158,
        1,0,0,0,161,164,1,0,0,0,162,160,1,0,0,0,162,163,1,0,0,0,163,165,
        1,0,0,0,164,162,1,0,0,0,165,166,5,63,0,0,166,15,1,0,0,0,167,168,
        5,59,0,0,168,173,7,1,0,0,169,170,5,57,0,0,170,172,7,1,0,0,171,169,
        1,0,0,0,172,175,1,0,0,0,173,171,1,0,0,0,173,174,1,0,0,0,174,17,1,
        0,0,0,175,173,1,0,0,0,176,177,5,60,0,0,177,182,3,2,1,0,178,179,5,
        57,0,0,179,181,3,2,1,0,180,178,1,0,0,0,181,184,1,0,0,0,182,180,1,
        0,0,0,182,183,1,0,0,0,183,19,1,0,0,0,184,182,1,0,0,0,185,186,5,61,
        0,0,186,191,7,0,0,0,187,188,5,57,0,0,188,190,7,0,0,0,189,187,1,0,
        0,0,190,193,1,0,0,0,191,189,1,0,0,0,191,192,1,0,0,0,192,194,1,0,
        0,0,193,191,1,0,0,0,194,195,5,63,0,0,195,21,1,0,0,0,196,197,5,43,
        0,0,197,203,5,42,0,0,198,199,5,44,0,0,199,200,5,43,0,0,200,202,5,
        42,0,0,201,198,1,0,0,0,202,205,1,0,0,0,203,201,1,0,0,0,203,204,1,
        0,0,0,204,23,1,0,0,0,205,203,1,0,0,0,206,209,5,42,0,0,207,209,3,
        22,11,0,208,206,1,0,0,0,208,207,1,0,0,0,209,25,1,0,0,0,210,211,5,
        7,0,0,211,212,3,24,12,0,212,226,5,46,0,0,213,221,3,6,3,0,214,221,
        3,14,7,0,215,221,3,16,8,0,216,221,3,18,9,0,217,221,3,20,10,0,218,
        221,3,4,2,0,219,221,3,12,6,0,220,213,1,0,0,0,220,214,1,0,0,0,220,
        215,1,0,0,0,220,216,1,0,0,0,220,217,1,0,0,0,220,218,1,0,0,0,220,
        219,1,0,0,0,221,223,1,0,0,0,222,220,1,0,0,0,223,224,1,0,0,0,224,
        222,1,0,0,0,224,225,1,0,0,0,225,227,1,0,0,0,226,222,1,0,0,0,226,
        227,1,0,0,0,227,229,1,0,0,0,228,230,5,62,0,0,229,228,1,0,0,0,230,
        231,1,0,0,0,231,229,1,0,0,0,231,232,1,0,0,0,232,27,1,0,0,0,233,234,
        7,2,0,0,234,29,1,0,0,0,235,236,5,24,0,0,236,31,1,0,0,0,237,238,5,
        38,0,0,238,33,1,0,0,0,239,240,7,3,0,0,240,35,1,0,0,0,241,242,5,36,
        0,0,242,37,1,0,0,0,243,244,7,4,0,0,244,39,1,0,0,0,245,246,7,5,0,
        0,246,41,1,0,0,0,247,248,3,32,16,0,248,249,5,30,0,0,249,250,3,40,
        20,0,250,43,1,0,0,0,251,252,7,2,0,0,252,45,1,0,0,0,253,255,3,30,
        15,0,254,256,5,33,0,0,255,254,1,0,0,0,255,256,1,0,0,0,256,257,1,
        0,0,0,257,259,5,28,0,0,258,260,3,42,21,0,259,258,1,0,0,0,259,260,
        1,0,0,0,260,265,1,0,0,0,261,262,5,31,0,0,262,264,3,42,21,0,263,261,
        1,0,0,0,264,267,1,0,0,0,265,263,1,0,0,0,265,266,1,0,0,0,266,269,
        1,0,0,0,267,265,1,0,0,0,268,270,5,31,0,0,269,268,1,0,0,0,269,270,
        1,0,0,0,270,271,1,0,0,0,271,276,5,29,0,0,272,275,3,26,13,0,273,275,
        3,44,22,0,274,272,1,0,0,0,274,273,1,0,0,0,275,278,1,0,0,0,276,274,
        1,0,0,0,276,277,1,0,0,0,277,47,1,0,0,0,278,276,1,0,0,0,279,281,5,
        16,0,0,280,279,1,0,0,0,280,281,1,0,0,0,281,284,1,0,0,0,282,283,5,
        14,0,0,283,285,5,16,0,0,284,282,1,0,0,0,284,285,1,0,0,0,285,286,
        1,0,0,0,286,293,3,26,13,0,287,288,5,14,0,0,288,292,5,16,0,0,289,
        292,3,44,22,0,290,292,3,26,13,0,291,287,1,0,0,0,291,289,1,0,0,0,
        291,290,1,0,0,0,292,295,1,0,0,0,293,291,1,0,0,0,293,294,1,0,0,0,
        294,49,1,0,0,0,295,293,1,0,0,0,296,298,5,16,0,0,297,296,1,0,0,0,
        297,298,1,0,0,0,298,299,1,0,0,0,299,315,5,17,0,0,300,302,5,18,0,
        0,301,303,3,42,21,0,302,301,1,0,0,0,302,303,1,0,0,0,303,308,1,0,
        0,0,304,305,5,31,0,0,305,307,3,42,21,0,306,304,1,0,0,0,307,310,1,
        0,0,0,308,306,1,0,0,0,308,309,1,0,0,0,309,312,1,0,0,0,310,308,1,
        0,0,0,311,313,5,31,0,0,312,311,1,0,0,0,312,313,1,0,0,0,313,314,1,
        0,0,0,314,316,5,29,0,0,315,300,1,0,0,0,315,316,1,0,0,0,316,321,1,
        0,0,0,317,320,3,26,13,0,318,320,3,44,22,0,319,317,1,0,0,0,319,318,
        1,0,0,0,320,323,1,0,0,0,321,319,1,0,0,0,321,322,1,0,0,0,322,51,1,
        0,0,0,323,321,1,0,0,0,324,325,5,3,0,0,325,327,5,88,0,0,326,324,1,
        0,0,0,326,327,1,0,0,0,327,328,1,0,0,0,328,332,5,4,0,0,329,331,3,
        54,27,0,330,329,1,0,0,0,331,334,1,0,0,0,332,330,1,0,0,0,332,333,
        1,0,0,0,333,335,1,0,0,0,334,332,1,0,0,0,335,338,3,56,28,0,336,337,
        5,3,0,0,337,339,5,90,0,0,338,336,1,0,0,0,338,339,1,0,0,0,339,343,
        1,0,0,0,340,342,3,44,22,0,341,340,1,0,0,0,342,345,1,0,0,0,343,341,
        1,0,0,0,343,344,1,0,0,0,344,53,1,0,0,0,345,343,1,0,0,0,346,349,3,
        26,13,0,347,348,5,14,0,0,348,350,5,16,0,0,349,347,1,0,0,0,349,350,
        1,0,0,0,350,55,1,0,0,0,351,358,3,26,13,0,352,356,5,14,0,0,353,354,
        5,16,0,0,354,357,5,6,0,0,355,357,5,16,0,0,356,353,1,0,0,0,356,355,
        1,0,0,0,357,359,1,0,0,0,358,352,1,0,0,0,358,359,1,0,0,0,359,57,1,
        0,0,0,360,361,5,3,0,0,361,362,5,91,0,0,362,363,5,96,0,0,363,368,
        3,26,13,0,364,367,3,44,22,0,365,367,3,26,13,0,366,364,1,0,0,0,366,
        365,1,0,0,0,367,370,1,0,0,0,368,366,1,0,0,0,368,369,1,0,0,0,369,
        59,1,0,0,0,370,368,1,0,0,0,371,378,3,46,23,0,372,378,3,48,24,0,373,
        378,3,50,25,0,374,378,3,52,26,0,375,378,3,58,29,0,376,378,3,72,36,
        0,377,371,1,0,0,0,377,372,1,0,0,0,377,373,1,0,0,0,377,374,1,0,0,
        0,377,375,1,0,0,0,377,376,1,0,0,0,378,61,1,0,0,0,379,380,5,3,0,0,
        380,381,5,89,0,0,381,383,5,19,0,0,382,384,3,60,30,0,383,382,1,0,
        0,0,383,384,1,0,0,0,384,389,1,0,0,0,385,386,5,5,0,0,386,388,3,60,
        30,0,387,385,1,0,0,0,388,391,1,0,0,0,389,387,1,0,0,0,389,390,1,0,
        0,0,390,392,1,0,0,0,391,389,1,0,0,0,392,393,5,20,0,0,393,63,1,0,
        0,0,394,396,5,8,0,0,395,397,5,40,0,0,396,395,1,0,0,0,396,397,1,0,
        0,0,397,65,1,0,0,0,398,399,5,9,0,0,399,400,5,40,0,0,400,67,1,0,0,
        0,401,402,5,9,0,0,402,403,5,40,0,0,403,406,5,21,0,0,404,407,3,60,
        30,0,405,407,3,62,31,0,406,404,1,0,0,0,406,405,1,0,0,0,407,415,1,
        0,0,0,408,411,5,5,0,0,409,412,3,60,30,0,410,412,3,62,31,0,411,409,
        1,0,0,0,411,410,1,0,0,0,412,414,1,0,0,0,413,408,1,0,0,0,414,417,
        1,0,0,0,415,413,1,0,0,0,415,416,1,0,0,0,416,419,1,0,0,0,417,415,
        1,0,0,0,418,420,5,5,0,0,419,418,1,0,0,0,419,420,1,0,0,0,420,69,1,
        0,0,0,421,423,5,8,0,0,422,424,5,40,0,0,423,422,1,0,0,0,423,424,1,
        0,0,0,424,425,1,0,0,0,425,427,5,19,0,0,426,428,3,68,34,0,427,426,
        1,0,0,0,428,429,1,0,0,0,429,427,1,0,0,0,429,430,1,0,0,0,430,431,
        1,0,0,0,431,432,5,20,0,0,432,71,1,0,0,0,433,435,5,10,0,0,434,436,
        5,98,0,0,435,434,1,0,0,0,435,436,1,0,0,0,436,437,1,0,0,0,437,440,
        5,102,0,0,438,439,5,99,0,0,439,441,5,102,0,0,440,438,1,0,0,0,440,
        441,1,0,0,0,441,443,1,0,0,0,442,444,5,101,0,0,443,442,1,0,0,0,443,
        444,1,0,0,0,444,445,1,0,0,0,445,462,5,100,0,0,446,450,5,15,0,0,447,
        449,3,44,22,0,448,447,1,0,0,0,449,452,1,0,0,0,450,448,1,0,0,0,450,
        451,1,0,0,0,451,453,1,0,0,0,452,450,1,0,0,0,453,457,3,26,13,0,454,
        456,3,44,22,0,455,454,1,0,0,0,456,459,1,0,0,0,457,455,1,0,0,0,457,
        458,1,0,0,0,458,461,1,0,0,0,459,457,1,0,0,0,460,446,1,0,0,0,461,
        464,1,0,0,0,462,460,1,0,0,0,462,463,1,0,0,0,463,73,1,0,0,0,464,462,
        1,0,0,0,465,469,3,60,30,0,466,469,3,70,35,0,467,469,3,62,31,0,468,
        465,1,0,0,0,468,466,1,0,0,0,468,467,1,0,0,0,469,470,1,0,0,0,470,
        471,5,5,0,0,471,473,1,0,0,0,472,468,1,0,0,0,473,476,1,0,0,0,474,
        472,1,0,0,0,474,475,1,0,0,0,475,480,1,0,0,0,476,474,1,0,0,0,477,
        481,3,60,30,0,478,481,3,70,35,0,479,481,3,62,31,0,480,477,1,0,0,
        0,480,478,1,0,0,0,480,479,1,0,0,0,481,483,1,0,0,0,482,484,5,5,0,
        0,483,482,1,0,0,0,483,484,1,0,0,0,484,75,1,0,0,0,485,486,5,68,0,
        0,486,77,1,0,0,0,487,488,5,77,0,0,488,79,1,0,0,0,489,490,5,67,0,
        0,490,81,1,0,0,0,491,492,3,78,39,0,492,494,5,73,0,0,493,495,3,80,
        40,0,494,493,1,0,0,0,494,495,1,0,0,0,495,496,1,0,0,0,496,497,5,74,
        0,0,497,83,1,0,0,0,498,499,5,67,0,0,499,85,1,0,0,0,500,501,5,68,
        0,0,501,87,1,0,0,0,502,507,3,86,43,0,503,504,5,72,0,0,504,506,3,
        86,43,0,505,503,1,0,0,0,506,509,1,0,0,0,507,505,1,0,0,0,507,508,
        1,0,0,0,508,89,1,0,0,0,509,507,1,0,0,0,510,517,5,78,0,0,511,512,
        5,79,0,0,512,513,5,73,0,0,513,514,3,88,44,0,514,515,5,74,0,0,515,
        517,1,0,0,0,516,510,1,0,0,0,516,511,1,0,0,0,517,91,1,0,0,0,518,519,
        3,84,42,0,519,520,5,70,0,0,520,522,3,90,45,0,521,523,5,71,0,0,522,
        521,1,0,0,0,522,523,1,0,0,0,523,93,1,0,0,0,524,525,5,12,0,0,525,
        528,3,76,38,0,526,527,5,70,0,0,527,529,3,82,41,0,528,526,1,0,0,0,
        528,529,1,0,0,0,529,530,1,0,0,0,530,531,5,75,0,0,531,536,3,92,46,
        0,532,533,5,72,0,0,533,535,3,92,46,0,534,532,1,0,0,0,535,538,1,0,
        0,0,536,534,1,0,0,0,536,537,1,0,0,0,537,539,1,0,0,0,538,536,1,0,
        0,0,539,540,5,76,0,0,540,95,1,0,0,0,541,546,3,94,47,0,542,546,3,
        102,51,0,543,546,5,1,0,0,544,546,5,2,0,0,545,541,1,0,0,0,545,542,
        1,0,0,0,545,543,1,0,0,0,545,544,1,0,0,0,546,549,1,0,0,0,547,545,
        1,0,0,0,547,548,1,0,0,0,548,550,1,0,0,0,549,547,1,0,0,0,550,551,
        5,0,0,1,551,97,1,0,0,0,552,553,5,82,0,0,553,99,1,0,0,0,554,555,5,
        86,0,0,555,556,5,84,0,0,556,557,5,86,0,0,557,101,1,0,0,0,558,559,
        5,13,0,0,559,560,3,98,49,0,560,561,5,83,0,0,561,562,3,100,50,0,562,
        563,5,85,0,0,563,103,1,0,0,0,564,566,5,11,0,0,565,564,1,0,0,0,565,
        566,1,0,0,0,566,567,1,0,0,0,567,572,3,74,37,0,568,569,5,11,0,0,569,
        571,3,74,37,0,570,568,1,0,0,0,571,574,1,0,0,0,572,570,1,0,0,0,572,
        573,1,0,0,0,573,575,1,0,0,0,574,572,1,0,0,0,575,576,5,0,0,1,576,
        105,1,0,0,0,73,112,120,128,134,141,144,151,162,173,182,191,203,208,
        220,224,226,231,255,259,265,269,274,276,280,284,291,293,297,302,
        308,312,315,319,321,326,332,338,343,349,356,358,366,368,377,383,
        389,396,406,411,415,419,423,429,435,440,443,450,457,462,468,474,
        480,483,494,507,516,522,528,536,545,547,565,572
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!UniversalWorkoutLanguageParser.__ATN) {
            UniversalWorkoutLanguageParser.__ATN = new antlr.ATNDeserializer().deserialize(UniversalWorkoutLanguageParser._serializedATN);
        }

        return UniversalWorkoutLanguageParser.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(UniversalWorkoutLanguageParser.literalNames, UniversalWorkoutLanguageParser.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return UniversalWorkoutLanguageParser.vocabulary;
    }

    private static readonly decisionsToDFA = UniversalWorkoutLanguageParser._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}

export class ErrorContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public ERROR_CHAR(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.ERROR_CHAR, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_error;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitError) {
            return visitor.visitError(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Mov_timeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_TIME, 0);
    }
    public MC_MAX(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, 0);
    }
    public MC_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, 0);
    }
    public MC_CONTENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_mov_time;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMov_time) {
            return visitor.visitMov_time(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class SetsContext extends antlr.ParserRuleContext {
    public _sets_number?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public LOAD_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LOAD_MARKER, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_sets;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitSets) {
            return visitor.visitSets(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class RepsContext extends antlr.ParserRuleContext {
    public _rep_value?: Rep_valueContext;
    public _values: Rep_valueContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public rep_value(): Rep_valueContext[];
    public rep_value(i: number): Rep_valueContext | null;
    public rep_value(i?: number): Rep_valueContext[] | Rep_valueContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Rep_valueContext);
        }

        return this.getRuleContext(i, Rep_valueContext);
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_reps;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitReps) {
            return visitor.visitReps(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Rep_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, 0);
    }
    public MC_MAX(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, 0);
    }
    public amrap_value(): Amrap_valueContext | null {
        return this.getRuleContext(0, Amrap_valueContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_rep_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitRep_value) {
            return visitor.visitRep_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Amrap_valueContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public AMRAP_UNIT_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.AMRAP_UNIT_KW, 0)!;
    }
    public MC_PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PAREN_L, 0);
    }
    public MC_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_PAREN_R, 0);
    }
    public MC_DASH(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_DASH, 0);
    }
    public MC_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_amrap_value;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAmrap_value) {
            return visitor.visitAmrap_value(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class CaloriesContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _cals: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public __tset129?: Token | null;
    public __tset140?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_CAL_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CAL_KW, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_calories;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitCalories) {
            return visitor.visitCalories(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class LoadContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _loads: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public __tset158?: Token | null;
    public __tset171?: Token | null;
    public _load_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public LOAD_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LOAD_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_load;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitLoad) {
            return visitor.visitLoad(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class TempoContext extends antlr.ParserRuleContext {
    public _MC_CONTENT?: Token | null;
    public _tempos: antlr.Token[] = [];
    public _MC_NUMBER?: Token | null;
    public __tset191?: Token | null;
    public __tset204?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public TEMPO_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.TEMPO_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode[];
    public MC_CONTENT(i: number): antlr.TerminalNode | null;
    public MC_CONTENT(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_CONTENT);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, i);
    	}
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_tempo;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitTempo) {
            return visitor.visitTempo(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DurationContext extends antlr.ParserRuleContext {
    public _mov_time?: Mov_timeContext;
    public _durations: Mov_timeContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DURATION_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DURATION_MARKER, 0)!;
    }
    public mov_time(): Mov_timeContext[];
    public mov_time(i: number): Mov_timeContext | null;
    public mov_time(i?: number): Mov_timeContext[] | Mov_timeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Mov_timeContext);
        }

        return this.getRuleContext(i, Mov_timeContext);
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_duration;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDuration) {
            return visitor.visitDuration(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DistanceContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _distances: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public __tset241?: Token | null;
    public __tset252?: Token | null;
    public _distance_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DISTANCE_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DISTANCE_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_distance;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDistance) {
            return visitor.visitDistance(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Complex_movementContext extends antlr.ParserRuleContext {
    public _MOVEMENT_NAME_REPS?: Token | null;
    public _mov_reps: antlr.Token[] = [];
    public _SIMPLE_MOVEMENT?: Token | null;
    public _mov_name: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MOVEMENT_NAME_REPS(): antlr.TerminalNode[];
    public MOVEMENT_NAME_REPS(i: number): antlr.TerminalNode | null;
    public MOVEMENT_NAME_REPS(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS, i);
    	}
    }
    public SIMPLE_MOVEMENT(): antlr.TerminalNode[];
    public SIMPLE_MOVEMENT(i: number): antlr.TerminalNode | null;
    public SIMPLE_MOVEMENT(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT, i);
    	}
    }
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(): antlr.TerminalNode[];
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_complex_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitComplex_movement) {
            return visitor.visitComplex_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Movement_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public SIMPLE_MOVEMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT, 0);
    }
    public complex_movement(): Complex_movementContext | null {
        return this.getRuleContext(0, Complex_movementContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_movement_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMovement_name) {
            return visitor.visitMovement_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class MovementContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MOVEMENT_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_MARKER, 0)!;
    }
    public movement_name(): Movement_nameContext {
        return this.getRuleContext(0, Movement_nameContext)!;
    }
    public MOVEMENT_NAME_TERMINATOR(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_TERMINATOR, 0)!;
    }
    public MOVEMENT_TERM(): antlr.TerminalNode[];
    public MOVEMENT_TERM(i: number): antlr.TerminalNode | null;
    public MOVEMENT_TERM(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_TERM);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_TERM, i);
    	}
    }
    public reps(): RepsContext[];
    public reps(i: number): RepsContext | null;
    public reps(i?: number): RepsContext[] | RepsContext | null {
        if (i === undefined) {
            return this.getRuleContexts(RepsContext);
        }

        return this.getRuleContext(i, RepsContext);
    }
    public load(): LoadContext[];
    public load(i: number): LoadContext | null;
    public load(i?: number): LoadContext[] | LoadContext | null {
        if (i === undefined) {
            return this.getRuleContexts(LoadContext);
        }

        return this.getRuleContext(i, LoadContext);
    }
    public tempo(): TempoContext[];
    public tempo(i: number): TempoContext | null;
    public tempo(i?: number): TempoContext[] | TempoContext | null {
        if (i === undefined) {
            return this.getRuleContexts(TempoContext);
        }

        return this.getRuleContext(i, TempoContext);
    }
    public duration(): DurationContext[];
    public duration(i: number): DurationContext | null;
    public duration(i?: number): DurationContext[] | DurationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DurationContext);
        }

        return this.getRuleContext(i, DurationContext);
    }
    public distance(): DistanceContext[];
    public distance(i: number): DistanceContext | null;
    public distance(i?: number): DistanceContext[] | DistanceContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DistanceContext);
        }

        return this.getRuleContext(i, DistanceContext);
    }
    public sets(): SetsContext[];
    public sets(i: number): SetsContext | null;
    public sets(i?: number): SetsContext[] | SetsContext | null {
        if (i === undefined) {
            return this.getRuleContexts(SetsContext);
        }

        return this.getRuleContext(i, SetsContext);
    }
    public calories(): CaloriesContext[];
    public calories(i: number): CaloriesContext | null;
    public calories(i?: number): CaloriesContext[] | CaloriesContext | null {
        if (i === undefined) {
            return this.getRuleContexts(CaloriesContext);
        }

        return this.getRuleContext(i, CaloriesContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMovement) {
            return visitor.visitMovement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class CommentContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public INLINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INLINE_COMMENT, 0);
    }
    public MULTILINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MULTILINE_COMMENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_comment;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitComment) {
            return visitor.visitComment(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_idenContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_iden;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_iden) {
            return visitor.visitWorkout_iden(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_idContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_IDEN(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_IDEN, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_id;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_id) {
            return visitor.visitWorkout_attr_id(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_strContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_ML_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_ML_STRING, 0);
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_str;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_str) {
            return visitor.visitWorkout_attr_val_str(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_numberContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_NUMBER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_number;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_number) {
            return visitor.visitWorkout_attr_val_number(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_timeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_TIME, 0);
    }
    public WORKOUT_UNIT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_UNIT_TIME, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_time;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_time) {
            return visitor.visitWorkout_attr_val_time(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_valContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_ML_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_ML_STRING, 0);
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public WORKOUT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_TIME, 0);
    }
    public WORKOUT_UNIT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_UNIT_TIME, 0);
    }
    public WORKOUT_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_NUMBER, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val) {
            return visitor.visitWorkout_attr_val(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attributeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout_attr_id(): Workout_attr_idContext {
        return this.getRuleContext(0, Workout_attr_idContext)!;
    }
    public WORKOUT_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COLON, 0)!;
    }
    public workout_attr_val(): Workout_attr_valContext {
        return this.getRuleContext(0, Workout_attr_valContext)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attribute;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attribute) {
            return visitor.visitWorkout_attribute(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_commentContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public INLINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INLINE_COMMENT, 0);
    }
    public MULTILINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MULTILINE_COMMENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_comment;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_comment) {
            return visitor.visitWorkout_comment(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class WorkoutContext extends antlr.ParserRuleContext {
    public _workout_attribute?: Workout_attributeContext;
    public _attrs: Workout_attributeContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout_iden(): Workout_idenContext {
        return this.getRuleContext(0, Workout_idenContext)!;
    }
    public WORKOUT_PAREN_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_L, 0)!;
    }
    public WORKOUT_PAREN_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R, 0)!;
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public WORKOUT_COMMA(): antlr.TerminalNode[];
    public WORKOUT_COMMA(i: number): antlr.TerminalNode | null;
    public WORKOUT_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COMMA, i);
    	}
    }
    public workout_attribute(): Workout_attributeContext[];
    public workout_attribute(i: number): Workout_attributeContext | null;
    public workout_attribute(i?: number): Workout_attributeContext[] | Workout_attributeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_attributeContext);
        }

        return this.getRuleContext(i, Workout_attributeContext);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout) {
            return visitor.visitWorkout(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_strengthContext extends antlr.ParserRuleContext {
    public _name?: Token | null;
    public _STRING?: Token | null;
    public _rest: antlr.Token[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public REST_KW(): antlr.TerminalNode[];
    public REST_KW(i: number): antlr.TerminalNode | null;
    public REST_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.REST_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.REST_KW, i);
    	}
    }
    public STRING(): antlr.TerminalNode[];
    public STRING(i: number): antlr.TerminalNode | null;
    public STRING(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.STRING);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.STRING, i);
    	}
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_strength;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_strength) {
            return visitor.visitShorthand_strength(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_genericContext extends antlr.ParserRuleContext {
    public _workout_attribute?: Workout_attributeContext;
    public _attrs: Workout_attributeContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public GENERIC_STRING(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.GENERIC_STRING, 0)!;
    }
    public STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.STRING, 0);
    }
    public PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.PAREN_L, 0);
    }
    public WORKOUT_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R, 0);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public WORKOUT_COMMA(): antlr.TerminalNode[];
    public WORKOUT_COMMA(i: number): antlr.TerminalNode | null;
    public WORKOUT_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COMMA, i);
    	}
    }
    public workout_attribute(): Workout_attributeContext[];
    public workout_attribute(i: number): Workout_attributeContext | null;
    public workout_attribute(i?: number): Workout_attributeContext[] | Workout_attributeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_attributeContext);
        }

        return this.getRuleContext(i, Workout_attributeContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_generic;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_generic) {
            return visitor.visitShorthand_generic(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_fortimeContext extends antlr.ParserRuleContext {
    public _rounds_num?: Token | null;
    public _rounds_word?: Token | null;
    public _sets_num?: Token | null;
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public FORTIME_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.FORTIME_KW, 0)!;
    }
    public final_movement(): Final_movementContext {
        return this.getRuleContext(0, Final_movementContext)!;
    }
    public FORTIME_SET_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.FORTIME_SET_KW, 0);
    }
    public NUMBER(): antlr.TerminalNode[];
    public NUMBER(i: number): antlr.TerminalNode | null;
    public NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NUMBER, i);
    	}
    }
    public ROUNDS_WORD(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.ROUNDS_WORD, 0);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public intermediate_movement(): Intermediate_movementContext[];
    public intermediate_movement(i: number): Intermediate_movementContext | null;
    public intermediate_movement(i?: number): Intermediate_movementContext[] | Intermediate_movementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Intermediate_movementContext);
        }

        return this.getRuleContext(i, Intermediate_movementContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_fortime;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_fortime) {
            return visitor.visitShorthand_fortime(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Intermediate_movementContext extends antlr.ParserRuleContext {
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _STRING?: Token | null;
    public _rest: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public movement(): MovementContext {
        return this.getRuleContext(0, MovementContext)!;
    }
    public REST_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_KW, 0);
    }
    public STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.STRING, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_intermediate_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitIntermediate_movement) {
            return visitor.visitIntermediate_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Final_movementContext extends antlr.ParserRuleContext {
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _component_rest?: Token | null;
    public _round_rest?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public movement(): MovementContext {
        return this.getRuleContext(0, MovementContext)!;
    }
    public REST_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.REST_KW, 0);
    }
    public INTO_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INTO_KW, 0);
    }
    public STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.STRING, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_final_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitFinal_movement) {
            return visitor.visitFinal_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_amrapContext extends antlr.ParserRuleContext {
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NUMBER, 0)!;
    }
    public TIME_UNIT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.TIME_UNIT, 0)!;
    }
    public AMRAP_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.AMRAP_KW, 0)!;
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_amrap;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_amrap) {
            return visitor.visitShorthand_amrap(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Any_workoutContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout(): WorkoutContext | null {
        return this.getRuleContext(0, WorkoutContext);
    }
    public shorthand_strength(): Shorthand_strengthContext | null {
        return this.getRuleContext(0, Shorthand_strengthContext);
    }
    public shorthand_generic(): Shorthand_genericContext | null {
        return this.getRuleContext(0, Shorthand_genericContext);
    }
    public shorthand_fortime(): Shorthand_fortimeContext | null {
        return this.getRuleContext(0, Shorthand_fortimeContext);
    }
    public shorthand_amrap(): Shorthand_amrapContext | null {
        return this.getRuleContext(0, Shorthand_amrapContext);
    }
    public emom(): EmomContext | null {
        return this.getRuleContext(0, EmomContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_any_workout;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAny_workout) {
            return visitor.visitAny_workout(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class AlternationContext extends antlr.ParserRuleContext {
    public _any_workout?: Any_workoutContext;
    public _workouts: Any_workoutContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NUMBER, 0)!;
    }
    public ALT_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.ALT_KW, 0)!;
    }
    public LBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LBRACE, 0)!;
    }
    public RBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.RBRACE, 0)!;
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_alternation;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAlternation) {
            return visitor.visitAlternation(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_block_titleContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public CHOICE_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_block_title;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_block_title) {
            return visitor.visitOption_block_title(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_titleContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public OPTION_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.OPTION_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_title;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_title) {
            return visitor.visitOption_title(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class OptionContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public OPTION_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.OPTION_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0)!;
    }
    public COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.COLON, 0)!;
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public alternation(): AlternationContext[];
    public alternation(i: number): AlternationContext | null;
    public alternation(i?: number): AlternationContext[] | AlternationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(AlternationContext);
        }

        return this.getRuleContext(i, AlternationContext);
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption) {
            return visitor.visitOption(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_blockContext extends antlr.ParserRuleContext {
    public _option?: OptionContext;
    public _options: OptionContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public CHOICE_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_KW, 0)!;
    }
    public LBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LBRACE, 0)!;
    }
    public RBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.RBRACE, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0);
    }
    public option(): OptionContext[];
    public option(i: number): OptionContext | null;
    public option(i?: number): OptionContext[] | OptionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(OptionContext);
        }

        return this.getRuleContext(i, OptionContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_block;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_block) {
            return visitor.visitOption_block(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class EmomContext extends antlr.ParserRuleContext {
    public _emom_low_bound?: Token | null;
    public _emom_high_bound?: Token | null;
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EMOM_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_KW, 0)!;
    }
    public EMOM_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_COLON, 0)!;
    }
    public EMOM_NUM(): antlr.TerminalNode[];
    public EMOM_NUM(i: number): antlr.TerminalNode | null;
    public EMOM_NUM(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.EMOM_NUM);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.EMOM_NUM, i);
    	}
    }
    public EMOM_X_SEP(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_X_SEP, 0);
    }
    public EMOM_DASH(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_DASH, 0);
    }
    public EMOM_MIN_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.EMOM_MIN_KW, 0);
    }
    public MIN_KW(): antlr.TerminalNode[];
    public MIN_KW(i: number): antlr.TerminalNode | null;
    public MIN_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MIN_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MIN_KW, i);
    	}
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_emom;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitEmom) {
            return visitor.visitEmom(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class WodContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public option_block(): Option_blockContext[];
    public option_block(i: number): Option_blockContext | null;
    public option_block(i?: number): Option_blockContext[] | Option_blockContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Option_blockContext);
        }

        return this.getRuleContext(i, Option_blockContext);
    }
    public alternation(): AlternationContext[];
    public alternation(i: number): AlternationContext | null;
    public alternation(i?: number): AlternationContext[] | AlternationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(AlternationContext);
        }

        return this.getRuleContext(i, AlternationContext);
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_wod;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWod) {
            return visitor.visitWod(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_idContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_id;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_id) {
            return visitor.visitDef_id(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_super_kwContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_SUPER_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_SUPER_KW, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super_kw;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super_kw) {
            return visitor.visitDef_super_kw(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_super_paramContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_REGEX(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_REGEX, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super_param;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super_param) {
            return visitor.visitDef_super_param(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_superContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_super_kw(): Def_super_kwContext {
        return this.getRuleContext(0, Def_super_kwContext)!;
    }
    public DEF_PAREN_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_L, 0)!;
    }
    public DEF_PAREN_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_R, 0)!;
    }
    public def_super_param(): Def_super_paramContext | null {
        return this.getRuleContext(0, Def_super_paramContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super) {
            return visitor.visitDef_super(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_REGEX(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_REGEX, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_name) {
            return visitor.visitDef_param_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_enum_entryContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_enum_entry;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_enum_entry) {
            return visitor.visitDef_param_enum_entry(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_enumContext extends antlr.ParserRuleContext {
    public _def_param_enum_entry?: Def_param_enum_entryContext;
    public _def_param_enum_entries: Def_param_enum_entryContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_param_enum_entry(): Def_param_enum_entryContext[];
    public def_param_enum_entry(i: number): Def_param_enum_entryContext | null;
    public def_param_enum_entry(i?: number): Def_param_enum_entryContext[] | Def_param_enum_entryContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Def_param_enum_entryContext);
        }

        return this.getRuleContext(i, Def_param_enum_entryContext);
    }
    public DEF_COMMA(): antlr.TerminalNode[];
    public DEF_COMMA(i: number): antlr.TerminalNode | null;
    public DEF_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.DEF_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.DEF_COMMA, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_enum;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_enum) {
            return visitor.visitDef_param_enum(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_typeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_TYPES(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_TYPES, 0);
    }
    public DEF_ENUM_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_ENUM_KW, 0);
    }
    public DEF_PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_L, 0);
    }
    public def_param_enum(): Def_param_enumContext | null {
        return this.getRuleContext(0, Def_param_enumContext);
    }
    public DEF_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_R, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_type;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_type) {
            return visitor.visitDef_param_type(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_paramContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_param_name(): Def_param_nameContext {
        return this.getRuleContext(0, Def_param_nameContext)!;
    }
    public DEF_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_COLON, 0)!;
    }
    public def_param_type(): Def_param_typeContext | null {
        return this.getRuleContext(0, Def_param_typeContext);
    }
    public DEF_OPTIONAL(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_OPTIONAL, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param) {
            return visitor.visitDef_param(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DefinitionContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_KW, 0)!;
    }
    public def_id(): Def_idContext {
        return this.getRuleContext(0, Def_idContext)!;
    }
    public DEF_BRACE_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_BRACE_L, 0)!;
    }
    public def_param(): Def_paramContext[];
    public def_param(i: number): Def_paramContext | null;
    public def_param(i?: number): Def_paramContext[] | Def_paramContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Def_paramContext);
        }

        return this.getRuleContext(i, Def_paramContext);
    }
    public DEF_BRACE_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_BRACE_R, 0)!;
    }
    public DEF_COLON(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_COLON, 0);
    }
    public def_super(): Def_superContext | null {
        return this.getRuleContext(0, Def_superContext);
    }
    public DEF_COMMA(): antlr.TerminalNode[];
    public DEF_COMMA(i: number): antlr.TerminalNode | null;
    public DEF_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.DEF_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.DEF_COMMA, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_definition;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDefinition) {
            return visitor.visitDefinition(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DefsContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EOF(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EOF, 0)!;
    }
    public definition(): DefinitionContext[];
    public definition(i: number): DefinitionContext | null;
    public definition(i?: number): DefinitionContext[] | DefinitionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DefinitionContext);
        }

        return this.getRuleContext(i, DefinitionContext);
    }
    public new_movement(): New_movementContext[];
    public new_movement(i: number): New_movementContext | null;
    public new_movement(i?: number): New_movementContext[] | New_movementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(New_movementContext);
        }

        return this.getRuleContext(i, New_movementContext);
    }
    public WS(): antlr.TerminalNode[];
    public WS(i: number): antlr.TerminalNode | null;
    public WS(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WS);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WS, i);
    	}
    }
    public NL(): antlr.TerminalNode[];
    public NL(i: number): antlr.TerminalNode | null;
    public NL(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NL);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NL, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_defs;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDefs) {
            return visitor.visitDefs(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movement_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement_name) {
            return visitor.visitNew_movement_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movement_paramsContext extends antlr.ParserRuleContext {
    public _NEW_MOV_PARAM?: Token | null;
    public _nmp: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_PARAM(): antlr.TerminalNode[];
    public NEW_MOV_PARAM(i: number): antlr.TerminalNode | null;
    public NEW_MOV_PARAM(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_PARAM, i);
    	}
    }
    public NEW_MOV_COMMA(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_COMMA, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement_params;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement_params) {
            return visitor.visitNew_movement_params(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movementContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_KW, 0)!;
    }
    public new_movement_name(): New_movement_nameContext {
        return this.getRuleContext(0, New_movement_nameContext)!;
    }
    public NEW_MOV_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_COLON, 0)!;
    }
    public new_movement_params(): New_movement_paramsContext {
        return this.getRuleContext(0, New_movement_paramsContext)!;
    }
    public NEW_MOV_SEMI(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_SEMI, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement) {
            return visitor.visitNew_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DayContext extends antlr.ParserRuleContext {
    public _wod?: WodContext;
    public _wods: WodContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EOF(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EOF, 0)!;
    }
    public wod(): WodContext[];
    public wod(i: number): WodContext | null;
    public wod(i?: number): WodContext[] | WodContext | null {
        if (i === undefined) {
            return this.getRuleContexts(WodContext);
        }

        return this.getRuleContext(i, WodContext);
    }
    public WOD_KW(): antlr.TerminalNode[];
    public WOD_KW(i: number): antlr.TerminalNode | null;
    public WOD_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WOD_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WOD_KW, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_day;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDay) {
            return visitor.visitDay(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
