import { IRange, languages } from 'monaco-editor';

import { logger } from '../../Logger';

import { PATTERNS } from './types';

const GENERIC_BLOCK = {
  kind: languages.CompletionItemKind.Struct,
  insertText: '`\n${1:description}\n`',
  insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
  preselect: true,
  documentation:
    'Creates a workout block with a description\n\nExample:\n`\n15/11 Echo Bike Cals\n15 Wallball\n15 Power Snatch @ 75/55#\n15 TTB\n15/11 Cal Echo Bike Cals\nRest 90sec\n`',
};

export function createWorkoutProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: 'Strength',
      kind: languages.CompletionItemKind.Struct,
      insertText: '>${1:movementName:} ; rest "${2}"\n*${3:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates a simplified strength block without parameters\n\nExample:\n>Back Squat: 5 x 185 lbs @ 3110 ; rest "120 sec" \n*Keep core tight throughout movement',
    },
    {
      label: 'Generic',
      ...GENERIC_BLOCK,
      range: range,
    },
    {
      label: 'Something Else',
      ...GENERIC_BLOCK,
      range: range,
    },
    {
      label: 'For Time (No Rest)',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:4} rounds for time\n>${2:movementName:}\n>${3:movementName:}\n>${4:movementName:}\n*${5:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Classic CrossFit-style "For Time" format with no prescribed rest.\n\nExample:\n4 rounds for time\n>Deadlift: 3 x 15 lbs\n>Air Squat: 3\n>Run: for 300 m\n*Notes',
    },
    {
      label: 'For Time (Rest After All)',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:4} rounds for time\n>${2:movementName:}\n>${3:movementName:}\nrest "${4:2 min}" into\n*${5:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'For Time format with rest period after completing all rounds.\n\nExample:\n4 rounds for time\n>Deadlift: 3 x 15 lbs\n>Air Squat: 3\nrest "2 min" into\n*Notes',
    },
    {
      label: 'For Time (Rest Between Movements)',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        'For time\n>${1:movementName:}\nrest "${2:30 sec}"\n>${3:movementName:}\nrest "${4:30 sec}"\n>${5:movementName:}\nrest "${6:45 sec}"\n>${7:movementName:}\n*${8:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'For Time format with rest periods between each movement.\n\nExample:\nFor time\n>Deadlift: 3 x 15 lbs\nrest "30 sec"\n>Air Squat: 3\nrest "30 sec"\n>Bench Dip: 3\nrest "45 sec"\n>Plank Walkout: 3\n*my notes',
    },
    {
      label: 'For Time (Rest Between Rounds)',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:3} rounds for time\n>${2:movementName:}\n>${3:movementName:}\nrest "${4:3 min}"\n*${5:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'For Time format with rest periods after each round.\n\nExample:\n3 rounds, for time\n>Deadlift: 3 x 15 lbs\n>Air Squat: 3\nrest "3 min"\n*Notes',
    },
    {
      label: 'For Time (Rest Between Rounds & Sets)',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        'For time\n>${1:movementName:}\nrest "${2:30 sec}"\n>${3:movementName:}\nrest "${4:30 sec}"\n>${5:movementName:}\nrest "${6:45 sec}"\n>${7:movementName:}\nrest "${8:3 min}"\n${9:5} sets\n*${10:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'For Time format with rest periods between movements and sets.\n\nExample:\nFor time\n>Deadlift: 3 x 15 lbs\nrest "30 sec"\n>Air Squat: 3\nrest "30 sec"\n>Bench Dip: 5\nrest "45 sec"\n>Walk: for 300 m in 30 sec\nrest "3 min"\n5 sets\n*Notes',
    },
    {
      label: 'Alternating',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:3} alt sets {\n>${2:movementName:} ; rest "${3}"\n*${4:notes}\n+\n>${5:movementName:} ; rest "${6}"\n*${7:notes}\n}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates alternating sets between two movements\nExample:\n3 alt sets {\n>Back Squat: 5 x 185 lbs @ 3110 ; rest "120 sec"\n*notes\n+\n>Front Squat: 5 x 165 lbs @ 3110 ; rest "120 sec"\n*notes\n}',
    },
    {
      label: 'AMRAP',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:20} ${2|min,sec,s,m|} AMRAP\n>${3:movementName:}\n>${4:movementName:}\n>${5:movementName:}\n*${6:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates an AMRAP workout block\nExample:\n10 min AMRAP\n>Run: for 200m\n>Chest-to-bar Pull-up: 13\n>Abmat Wall Ball: 13 x 30 lbs\n*Compare to previous scores',
    },
    {
      label: 'EMOM',
      kind: languages.CompletionItemKind.Struct,
      insertText: 
        'EMOM ${1:6} - ${2:10} min:\nMin:\n>${3:movementName:}\nMin:\n>${4:movementName:}\n*${5:notes}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates an EMOM workout block\n\nExample:\nEMOM 18 - 30 min:\nMin-1: >Burpee Barbell Thruster: 10 x 95 lbs\nMin-2: >Double-under: 40\nMin-3: >Wall Ball Shots: 20\nMin-4: >Drag Rope Double Under: 40\nMin-5: >Dumbbell Box Step-up: 10 x 50 lbs\n* 24" Box Height\nMin-6: >Skier: in 30 s x 85 %RM'
    }
  ];
}

export function createParameterProposals(
  current_line: string,
  range: IRange,
): languages.CompletionItem[] {
  const match = current_line.toLowerCase().match(PATTERNS.PARAMETERS.SCORE_BY);
  logger.debug(current_line);
  if (match) {
    return [
      {
        label: 'score_by',
        kind: languages.CompletionItemKind.EnumMember,
        insertText:
          '"${1|Not Scored,Time (lower is better),Time (higher is better),Reps (higher is better),Reps (lower is better),Rounds and Reps (higher is better),Rounds and Reps (lower is better),Distance (higher is better),Distance (lower is better),Weight (higher is better),Weight (lower is better),Points / anything else (higher is better),Points / anything else (lower is better),Peak Watts,Max Speed|}"${0}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range,
      },
    ];
  }

  return [];
}

export function createEmomMinuteCompletion(
  match: RegExpMatchArray,
  range: IRange,
): languages.CompletionItem {
  const next_num: number = parseInt(match[1]!) + 1;
  return {
    label: `Min-${next_num}`,
    kind: languages.CompletionItemKind.Keyword,
    insertText: `Min-${next_num}: \${1:movement_name}`,
    range: range,
    insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
    preselect: true,
    sortText: '_',
  };
}
