import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import GenericIcon from '@mui/icons-material/Description';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import AlternatingIcon from '@mui/icons-material/SwapHoriz';
import { SvgIconProps } from '@mui/material/SvgIcon';

export const TEMPLATES: {
  [key: string]: {
    id: string;
    icon: React.ComponentType<SvgIconProps>;
    label: string;
    template: string;
    tooltip: string;
    subTemplates?: Array<{
      id: string;
      label: string;
      template: string;
      tooltip: string;
    }>;
  };
} = {
  STRENGTH: {
    id: 'strength',
    icon: FitnessCenter,
    label: 'Strength',
    template: '>${1:movementName:} ; rest "${2}"\n*${3:notes}',
    tooltip: `Creates a strength-focused workout block.<br /><br />Format:<br /> >movementName: ; rest ""<br />*notes<br /><br /> Example:<br /> >Front Squat: 10 ; rest "Rest as needed b/t each"<br />* 8/10 RPE - 2nd set heavier than first but leave 2 reps in the tank<br /><br />Use for: Single movement strength work, technique practice, or skill development.<br /><br />Shortcut: Type 's'`,
  },
  ALTERNATING: {
    id: 'alternating',
    icon: AlternatingIcon,
    label: 'Alternating',
    template:
      '${1:3} alt sets {\n>${2:movementName:} ; rest "${3}"\n*${4:notes}\n+\n>${5:movementName:} ; rest "${6}"\n*${7:notes}\n}',
    tooltip: `Creates an alternating workout block.<br /><br />Format:<br />3 alt sets {<br />>movementName: ; rest ""<br />*notes<br />+<br />>movementName: ; rest ""<br />*notes<br />}<br /><br />Example:<br />3 alt sets {<br />>Front Squat: 10 ; rest "Rest as needed b/t each"<br />* 8/10 RPE - 2nd set heavier than first<br />+<br />>Back Squat: 5 x 185 lbs @ 3110 ; rest "Rest as needed b/t each"<br />*Keep core tight<br />}<br /><br />Use for: Alternating between several movements.<br /><br />Shortcut: Type 'a'`,
  },
  FOR_TIME: {
    id: 'forTime',
    icon: AccessAlarmIcon,
    label: 'For Time',
    template: '',
    tooltip:
      'Various "For Time" workout formats including standard rounds, rest periods, and interval work.',
    subTemplates: [
      {
        id: 'forTime-standard',
        label: 'No Rest',
        template:
          '${1:4} rounds for time\n>${2:movementName:}\n>${3:movementName:}\n>${4:movementName:}\n*${5:notes}',
        tooltip:
          'Classic CrossFit-style "For Time" format with no prescribed rest.<br /><br />Example:<br />4 rounds for time<br />>Deadlift: 3 x 15 lbs<br />>Air Squat: 3<br />>Run: for 300 m<br />*Notes',
      },
      {
        id: 'forTime-restAfter',
        label: 'Rest After All',
        template:
          '${1:4} rounds for time\n>${2:movementName:}\n>${3:movementName:}\nrest "${4:2 min}" into\n*${5:notes}',
        tooltip:
          'For Time format with rest period after completing all rounds.<br /><br />Example:<br />4 rounds for time<br />>Deadlift: 3 x 15 lbs<br />>Air Squat: 3<br />rest "2 min" into<br />*Notes',
      },
      {
        id: 'forTime-restBetweenMovements',
        label: 'Rest Between Movements',
        template:
          'For time\n>${1:movementName:}\nrest "${2:30 sec}"\n>${3:movementName:}\nrest "${4:30 sec}"\n>${5:movementName:}\nrest "${6:45 sec}"\n>${7:movementName:}\n*${8:notes}',
        tooltip:
          'For Time format with rest periods between each movement.<br /><br />Example:<br />For time<br />>Deadlift: 3 x 15 lbs<br />rest "30 sec"<br />>Air Squat: 3<br />rest "30 sec"<br />>Bench Dip: 3<br />rest "45 sec"<br />>Plank Walkout: 3<br />*my notes',
      },
      {
        id: 'forTime-restAfterRounds',
        label: 'Rest Between Rounds',
        template:
          '${1:3} rounds for time\n>${2:movementName:}\n>${3:movementName:}\nrest "${4:3 min}"\n*${5:notes}',
        tooltip:
          'For Time format with rest periods after each round.<br /><br />Example:<br />3 rounds, for time<br />>Deadlift: 3 x 15 lbs<br />>Air Squat: 3<br />rest "3 min"<br />*Notes',
      },
      {
        id: 'forTime-restBetweenSets',
        label: 'Rest Between Rounds & Sets',
        template:
          'For time\n>${1:movementName:}\nrest "${2:30 sec}"\n>${3:movementName:}\nrest "${4:30 sec}"\n>${5:movementName:}\nrest "${6:45 sec}"\n>${7:movementName:}\nrest "${8:3 min}"\n${9:5} sets\n*${10:notes}',
        tooltip:
          'For Time format with rest periods between movements and sets.<br /><br />Example:<br />For time<br />>Deadlift: 3 x 15 lbs<br />rest "30 sec"<br />>Air Squat: 3<br />rest "30 sec"<br />>Bench Dip: 5<br />rest "45 sec"<br />>Walk: for 300 m in 30 sec<br />rest "3 min"<br />5 sets<br />*Notes',
      },
    ],
  },
  AMRAP: {
    id: 'amrap',
    icon: AccessAlarmIcon,
    label: 'AMRAP',
    template:
      '${1:20} ${2|min,sec,s,m|} AMRAP\n>${3:movementName:}\n>${4:movementName:}\n>${5:movementName:}\n*${6:notes}',
    tooltip: `Creates an AMRAP workout block.<br /><br />Format:<br />## min AMRAP<br />>movementName:<br />>movementName:<br />>movementName:<br />*notes<br /><br />Example:<br />10 min AMRAP<br />>Run: for 200m<br />>Chest-to-bar Pull-up: 13<br />>Abmat Wall Ball: 13 x 30 lbs<br />*Compare to previous scores<br /><br />Use for: Fixed-time workouts where the goal is to complete as many rounds as possible.<br /><br />Shortcut: Type 'amrap'`,
  },
  EMOM: {
    id: 'emom',
    icon: AvTimerIcon,
    label: 'EMOM',
    template: 
      'EMOM ${1:6} - ${2:10} min:\nMin:\n>${3:movementName:}\nMin:\n>${4:movementName:}\n*${5:notes}',
    tooltip: `Creates an EMOM workout block.<br /><br />Format:<br />EMOM ## - ## min:<br />Min: >movementName:<br />Min: >movementName:<br />*notes<br />The upper time bound is optional.<br /><br />Example:<br />EMOM 18 - 30 min:<br />Min-1: >Burpee Barbell Thruster: 10 x 95 lbs<br />Min-2: >Double-under: 40<br />Min-3: >Wall Ball Shots: 20<br />Min-4: >Drag Rope Double Under: 40<br />Min-5: >Dumbbell Box Step-up: 10 x 50 lbs<br />* 24" Box Height<br />Min-6: >Skier: in 30 s x 85 %RM<br /><br/ >Use for: Multiple rounds of multiple movements in a sequence.<br /><br />Shortcut: Type 'emom'`
  },
  GENERIC: {
    id: 'generic',
    icon: GenericIcon,
    label: 'Something Else',
    template: '`\n${1:description}\n`',
    tooltip: `Creates a generic workout block.<br /><br />Format:<br /> \`write content here\`<br /><br />Example:<br />\`Find a Max of the following Complex: 3 x Snatch + 2 x OHS + 1 x Hang Squat Snatch\`<br /><br />Use for: Generic workout blocks that don't fit into the other categories.<br /><br />Shortcut: Type anything between \`\` (backticks)`,
  },
  MOVEMENT: {
    id: 'movement',
    icon: SportsGymnasticsIcon,
    label: 'Movement',
    template: '>${1:movementName:} ; rest "${2}"\n*${3:notes}',
    tooltip: `Creates a movement.<br /><br />Format:<br />>movementName: <br /><br /> Example:<br /> >Air Squat: 25, 25, 25<br /><br />Use for: Single movement<br /><br />Shortcut: Type '>' and then the movement name`,
  },
};
