import { editor, IPosition } from 'monaco-editor';

export function isCursorInsideAlternating(model: editor.ITextModel, position: IPosition) {
  let insideAlternating = false;
  let openingBracketLine = -1;
  let upperClosingBracketLine = -1;

  for (let lineNumber = position.lineNumber; lineNumber > 0; lineNumber--) {
    const lineContent = model.getLineContent(lineNumber);
    if (lineContent) {
      const closingBracketIndex = lineContent.indexOf('}');
      const openingBracketIndex = lineContent.indexOf('{');

      if (closingBracketIndex !== -1 && (lineNumber < position.lineNumber || (lineNumber === position.lineNumber && position.column > closingBracketIndex))) {
        upperClosingBracketLine = lineNumber;
        break;
      }

      if (openingBracketIndex !== -1 && (lineNumber < position.lineNumber || (lineNumber === position.lineNumber && position.column > openingBracketIndex))) {
        insideAlternating = true;
        openingBracketLine = lineNumber;
        break;
      }
    }
  }

  return { insideAlternating, openingBracketLine, upperClosingBracketLine };
}

export function isOpeningBracketLine(model: editor.ITextModel, position: IPosition): boolean {
  const lineContent = model.getLineContent(position.lineNumber);
  const openingBracketIndex = lineContent.indexOf('{');
  return openingBracketIndex !== -1;
}

export function findClosingBracketLine(model: editor.ITextModel, startLine: number, totalLines: number) {
  for (let lineNumber = startLine; lineNumber <= totalLines; lineNumber++) {
    const lineContent = model.getLineContent(lineNumber);
    if (lineContent?.includes('}')) {
      return lineNumber;
    }
  }
  return -1;
}

export function isBeforeAlternatingClosingBracket(model: editor.ITextModel, position: IPosition, closingBracketLine: number): boolean {
  return closingBracketLine === -1 || position.lineNumber < closingBracketLine || 
    (position.lineNumber === closingBracketLine && position.column <= model.getLineContent(closingBracketLine).indexOf('}'));
}

export function handleCursorOnEmptyLine(
  template: string,
  model: editor.ITextModel,
  position: IPosition,
  totalLines: number,
  closingBracketLine?: number
): { updatedTemplate: string, insertPosition: number } {
  if (closingBracketLine) {
    totalLines = closingBracketLine;
  }
  const insertPosition = position.lineNumber;
  const hasContentAbove = position.lineNumber > 1 && model.getLineContent(position.lineNumber - 1).trim() !== '';
  const hasContentBelow = position.lineNumber < totalLines && model.getLineContent(position.lineNumber + 1).trim() !== '';
  const hasPlusAbove = hasContentAbove && model.getLineContent(position.lineNumber - 1).trim().endsWith('+');
  if (hasContentAbove && hasPlusAbove) {
    return { updatedTemplate: `${template}\n+`, insertPosition };
  } else if (!hasContentAbove && !hasContentBelow) {
    return {updatedTemplate: template, insertPosition};
  } else if (hasContentAbove) {
    return { updatedTemplate: `+\n${template}`, insertPosition };
  } else {
    return { updatedTemplate: `${template}\n+`, insertPosition };
  }
}

export function findPlusAfterCursor(model: editor.ITextModel, position: IPosition, totalLines: number): { isPlusAfterCursor: boolean, insertPosition: number } {
  let isPlusAfterCursor = false;
  let insertPosition = -1;

  for (let lineNumber = position.lineNumber; lineNumber <= totalLines; lineNumber++) {
    const lineContent = model.getLineContent(lineNumber);
    const plusIndex = lineContent.indexOf('+', lineNumber === position.lineNumber ? position.column - 1 : 0);
    if (plusIndex !== -1) {
      isPlusAfterCursor = true;
      insertPosition = lineNumber;
      break;
    }
  }
  return { isPlusAfterCursor, insertPosition };
}

export function handleCursorOnNonEmptyLine(
  template: string,
  model: editor.ITextModel,
  position: IPosition,
  totalLines: number,
  insertPosition: number,
  closingBracketLine?: number
): { updatedTemplate: string, insertPosition: number } {

  if (closingBracketLine) {
    totalLines = closingBracketLine;
  }
  const lineContentBeforeCursor = model.getLineContent(position.lineNumber).slice(0, position.column - 1).trim();
  const isOpeningBracketBeforeCursor = lineContentBeforeCursor.endsWith('{');
  if (isOpeningBracketBeforeCursor) {
    return { updatedTemplate: `${template}\n+\n`, insertPosition: insertPosition + 1 };
  }
  const isPlusJustBeforeCursor = lineContentBeforeCursor.endsWith('+');
  if (isPlusJustBeforeCursor) {
    return { updatedTemplate: `${template}\n+\n`, insertPosition: insertPosition + 1 };
  }

  if (isOpeningBracketLine(model, position)) {
    insertPosition = findClosingBracketLine(model, position.lineNumber, totalLines);
    if (insertPosition === totalLines) {
      return { updatedTemplate: `\n+\n${template}`, insertPosition: insertPosition + 1 };
    }
    return { updatedTemplate: `${template}\n+\n`, insertPosition: insertPosition + 1 };
  }

  const { isPlusAfterCursor, insertPosition: plusAfterCursorInsertPosition } = findPlusAfterCursor(model, position, totalLines);

  insertPosition = plusAfterCursorInsertPosition;

  if (!isPlusJustBeforeCursor && !isPlusAfterCursor  && closingBracketLine) {
    return { updatedTemplate: `+\n${template}\n`, insertPosition: closingBracketLine };
  } else if (!isPlusJustBeforeCursor && isPlusAfterCursor) {
    return { updatedTemplate: `${template}\n+\n`, insertPosition: insertPosition + 1 };
  } else if (!isPlusJustBeforeCursor && !isPlusAfterCursor) {
    return { updatedTemplate: `\n+\n${template}`, insertPosition: totalLines + 1 };
  }
  return { updatedTemplate: template, insertPosition };
}

export function formatTemplateAndSetInsertPosition(
  template: string,
  model: editor.ITextModel,
  position: IPosition,
  totalLines: number,
  closingBracketLine?: number
): { updatedTemplate: string, insertPosition: number } {
  const insertPosition = position.lineNumber;
  if (model.getLineContent(position.lineNumber) === '') {
    const { updatedTemplate, insertPosition: newInsertPosition } = handleCursorOnEmptyLine(template, model, position, totalLines, closingBracketLine);
    return { updatedTemplate: updatedTemplate, insertPosition: newInsertPosition };
  }
   
  const { updatedTemplate, insertPosition: newInsertPosition } = handleCursorOnNonEmptyLine(template, model, position, totalLines, insertPosition, closingBracketLine);

  return { updatedTemplate: updatedTemplate, insertPosition: newInsertPosition };
}
