import { UNIT_RULES } from '../@monaco-uwl/rules';
import { Movement } from '../@monaco-uwl/UWLVisitor/Movement';
import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';
import { RestType, LoadUnit, DurationUnit, DistanceUnit } from '../wodupParser/wodup.types';

import { StrengthComponent } from './parser.types';
import { prepareNotes } from './utils';

function getLongestArrayLength(movement: Movement): number {
  if (!movement?.attributes) return 0;
  const arrayKeys = ['reps', 'load', 'distance', 'duration', 'tempo'] as const;

  const lengths = arrayKeys.map((key) => {
    const value = movement.attributes[key];
    return Array.isArray(value) ? value.length : 0;
  });

  return Math.max(...lengths, 0);
}

function normalizeMovementAttributes(
  movement: Movement,
  shouldBeInsideAlternating: boolean,
  setsFromAlternation: number,
): Movement {
  if (!movement || !movement.attributes) {
    return movement;
  }

  const attributes = movement.attributes;
  const arrayKeys = ['reps', 'load', 'distance', 'duration', 'tempo'] as const;

  const nonEmptyArrays = arrayKeys
    .filter((key) => {
      const value = attributes[key];
      return Array.isArray(value) && value.length > 0;
    })
    .map((key) => ({
      key,
      value: attributes[key] as string[],
    }));

  if (nonEmptyArrays.length === 0) {
    return movement;
  }

  // Determine the target length based on the hierarchy
  const targetLength = shouldBeInsideAlternating
    ? setsFromAlternation
    : (movement.attributes.sets ?? getLongestArrayLength(movement));

  nonEmptyArrays.forEach(({ key, value }) => {
    if (value.length < targetLength) {
      const lastValue = value[value.length - 1];
      const extension = new Array(targetLength - value.length).fill(lastValue);
      attributes[key] = [...value, ...extension];
    }
  });

  return movement;
}

function normalizeUnit(movement: Movement): void {
  if (!movement?.attributes) return;
  const attrs = movement.attributes;

  const convertToEnum = <T extends LoadUnit | DurationUnit | DistanceUnit>(
    value: string | undefined,
    unitType: keyof typeof UNIT_RULES,
  ): T | undefined => {
    if (!value) return undefined;
    const normalizedValue = value.toLowerCase().trim();
    return UNIT_RULES[unitType].mappings[normalizedValue] as T;
  };

  if (attrs.load_unit) {
    attrs.load_unit = convertToEnum<LoadUnit>(attrs.load_unit, 'LOAD');
  }
  if (attrs.duration_unit) {
    attrs.duration_unit = convertToEnum<DurationUnit>(attrs.duration_unit, 'TIME');
  }
  if (attrs.distance_unit) {
    attrs.distance_unit = convertToEnum<DistanceUnit>(attrs.distance_unit, 'DISTANCE');
  }
}

function mapMToX(movement: Movement): Movement {
  if (!movement?.attributes) return movement;
  const attributesToMap = ['reps', 'load', 'distance', 'duration'] as const;

  attributesToMap.forEach((key) => {
    const value = movement.attributes[key];
    if (Array.isArray(value)) {
      movement.attributes[key] = value.map((item) => (item === 'M' ? 'X' : item));
    }
  });

  return movement;
}

function parseRest(rest: string): { type: RestType; duration?: number } {
  if (!rest) return { type: RestType.AsNeeded };

  // Remove enclosing quotes if present
  if (rest.startsWith('"') && rest.endsWith('"')) {
    rest = rest.slice(1, -1);
  }

  const normalizedRest = rest.toLowerCase().trim();

  // Check for "None" case
  if (normalizedRest === 'none') {
    return { type: RestType.Superset };
  }

  const typeMappings: { [key: string]: RestType } = {
    'as needed': RestType.AsNeeded,
    as_needed: RestType.AsNeeded,
    superset: RestType.Superset,
    emom: RestType.Emom,
    fixed: RestType.Fixed,
    ratio: RestType.Ratio,
    none: RestType.Superset,
  };

  const matchedType = Object.keys(typeMappings).find((type) => normalizedRest.startsWith(type));

  if (matchedType) {
    const restType = typeMappings[matchedType];
    const durationPart = normalizedRest.slice(matchedType.length).trim();

    let duration: number | undefined;
    if (restType === RestType.Fixed || restType === RestType.Emom) {
      duration = parseDurationToSeconds(durationPart);
    } else if (restType === RestType.Ratio) {
      duration = parseFloat(durationPart);
    }

    return { type: restType, duration };
  }

  const standaloneSeconds = parseDurationToSeconds(normalizedRest);
  if (standaloneSeconds !== undefined) {
    return {
      type: RestType.Fixed,
      duration: standaloneSeconds,
    };
  }

  return { type: RestType.AsNeeded };
}

function parseDurationToSeconds(duration: string): number | undefined {
  if (!duration) return undefined;

  // Match exactly MM:SS format where MM and SS are 1-2 digits and SS is between 0-59
  const timePattern = /^(\d{1,2}):([0-5]\d)$/;
  const match = duration.match(timePattern);
  if (match) {
    const minutes = parseInt(match[1], 10);
    const seconds = parseInt(match[2], 10);
    return minutes * 60 + seconds;
  }

  const unitPattern = /(\d+)\s*(s|sec|seconds|m|min|minutes)/;
  const unitMatch = duration.match(unitPattern);
  if (unitMatch) {
    const value = parseInt(unitMatch[1], 10);
    const unit = unitMatch[2];
    if (unit.startsWith('s')) return value;
    if (unit.startsWith('m')) return value * 60;
  }

  return undefined;
}

function determineNumberOfSets(
  movement: Movement,
  shouldBeInsideAlternating: boolean,
  setsFromAlternation: number,
): number {
  // First priority: if shouldBeInsideAlternating is true, always use setsFromAlternation
  if (shouldBeInsideAlternating) {
    return setsFromAlternation;
  }

  // Second priority: use movement.sets if available
  if (movement?.attributes?.sets) {
    return movement.attributes.sets as number;
  }

  // Third priority: use normalization (getLongestArrayLength)
  return getLongestArrayLength(movement);
}

export function parseUWLStrength(
  w: Workout,
  prefix: string,
  shouldBeInsideAlternating: boolean,
): StrengthComponent {
  const movement = normalizeMovementAttributes(
    w.movements?.[0] ?? new Movement(['Unknown Movement'], [], {}),
    shouldBeInsideAlternating,
    w.setsFromAlternation,
  );

  const mappedMovement = mapMToX(movement);
  normalizeUnit(mappedMovement);

  const { type: restType, duration: restDuration } = parseRest(w.attrs?.['rest'] ?? '');

  return {
    type: 'Strength',
    is_alternating: w.is_alternating,
    should_be_inside_alternating: shouldBeInsideAlternating,
    name: w.name ?? '',
    prefix,
    rest_type: restType,
    rest_duration: restDuration,
    movement: mappedMovement,
    notes: prepareNotes(w.notes),
    numberOfSets: determineNumberOfSets(
      mappedMovement,
      shouldBeInsideAlternating,
      w.setsFromAlternation,
    ),
  };
}
