import { Monaco } from '@monaco-editor/react';
import CloseIcon from '@mui/icons-material/Close';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import { editor, IPosition } from 'monaco-editor';
import React, { useEffect, useState, useRef } from 'react';

import MonacoEditor from '../../@monaco-uwl/MonacoEditor';
import { Workout } from '../../@monaco-uwl/UWLVisitor/Workout';
import { parseUWLContent } from '../../generalParser/sessionParser';
import { logger } from '../../Logger';
import { parseSession } from '../../wodupParser/sessionWodupParser';
import WodUpPreview from '../WodUpPreview/WodUpPreview';

import { styles } from './ExpandedViewDialog.styles';
import { ExpandedViewDialogProps, SnippetController } from './ExpandedViewDialog.types';
import {
  isCursorInsideAlternating,
  isBeforeAlternatingClosingBracket,
  findClosingBracketLine,
  formatTemplateAndSetInsertPosition
} from './TemplateTooltip/insertTemplateUtils';
import TemplateItem from './TemplateTooltip/TemplateItem';
import { TEMPLATES } from './TemplateTooltip/templates';
import { useTooltip } from './TemplateTooltip/useTooltip';


const DialogHeader: React.FC<{ weekNumber: number; dayNumber: number }> = ({
  weekNumber,
  dayNumber,
}) => <DialogTitle sx={styles.dialogTitle}>{`Week ${weekNumber} - Day ${dayNumber}`}</DialogTitle>;

const EditSection: React.FC<{
  content: string;
  onContentChange: (content: string) => void;
  onEditorDidMount?: (editor: editor.IStandaloneCodeEditor, monacoInstance: Monaco) => void;
  initialCursorPosition?: IPosition | null;
}> = ({ content, onContentChange, onEditorDidMount, initialCursorPosition }) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const {
    tooltipVisible,
    setTooltipVisible,
    tooltipPosition,
    setTooltipPosition,
    hoveredTemplate,
    setHoveredTemplate,
  } = useTooltip();

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setTooltipVisible(!tooltipVisible);
    setTooltipPosition({ top: 100, left: 150 });
  };

  const handleEditorMount = (editor: editor.IStandaloneCodeEditor, monacoInstance: Monaco) => {
    editorRef.current = editor;
    if (initialCursorPosition) {
      editor.setPosition(initialCursorPosition);
      editor.revealPositionInCenter(initialCursorPosition);
      editor.focus();
    }
    onEditorDidMount?.(editor, monacoInstance);
  };

  function insertTemplate(template: string) {
    const editor = editorRef.current;
    if (editor) {
      const model = editor.getModel();
      if (!model) return;

      const position = editor.getPosition();
      const totalLines = model.getLineCount();
      const snippetController = editor.getContribution('snippetController2') as SnippetController;

      if (!position) {
        logger.warn('Editor position is null. Cannot insert template.');
        return;
      }

      const { insideAlternating, upperClosingBracketLine } = isCursorInsideAlternating(model, position);

      if (insideAlternating && template === TEMPLATES.ALTERNATING?.template) {
        logger.warn('Cannot insert an ALTERNATING block inside another ALTERNATING block.');
        setErrorSnackbarOpen(true);
        return;
      }

      let updatedTemplate = template;
      let insertPosition = position.lineNumber;

      if (insideAlternating && isBeforeAlternatingClosingBracket(model, position, upperClosingBracketLine)) {
        const lowerClosingBracketLine = findClosingBracketLine(model, position.lineNumber, totalLines);
        const { updatedTemplate: updatedTemplateValue, insertPosition: insertPositionValue } = formatTemplateAndSetInsertPosition(template, model, position, totalLines, lowerClosingBracketLine);
        updatedTemplate = updatedTemplateValue;
        insertPosition = insertPositionValue;
      } else {
        const { updatedTemplate: updatedTemplateValue, insertPosition: insertPositionValue } = formatTemplateAndSetInsertPosition(template, model, position, totalLines);
        updatedTemplate = updatedTemplateValue;
        insertPosition = insertPositionValue;
      }

      editor.setPosition({ lineNumber: insertPosition, column: 1 });
      editor.revealPositionInCenter({ lineNumber: insertPosition, column: 1 });

      snippetController.insert(`${updatedTemplate}`);

      setTooltipVisible(false);
      editor.focus();
    }
  }

  return (
    <Paper elevation={3} sx={styles.editPaper}>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" sx={{ ...styles.sectionTitle, marginBottom: '0px' }}>
          Edit Content
        </Typography>
        <Tooltip title="Show content templates" placement="right">
          <IconButton id="library-icon" onClick={handleIconClick} sx={{ marginLeft: 0 }}>
            <LibraryBooksIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={styles.editorBox}>
        <MonacoEditor
          value={content}
          onChange={(value: string | undefined) => onContentChange(value ?? '')}
          onMount={handleEditorMount}
        />
      </Box>
      {tooltipVisible && tooltipPosition && (
        <Box
          id="tooltip"
          sx={{
            position: 'absolute',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            backgroundColor: '#fff',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
            padding: '16px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            zIndex: 1300,
            width: '350px',
            maxHeight: '500px',
            overflowY: 'auto',
            '& .MuiTypography-root': {
              fontSize: '0.875rem',
              lineHeight: '1.4',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            '& .MuiIconButton-root': {
              padding: '4px',
              marginLeft: '8px',
            },
            '& .MuiBox-root': {
              '&:not(:last-child)': {
                marginBottom: '4px',
              },
            },
          }}
        >
          {Object.values(TEMPLATES).map((template) => (
            <TemplateItem
              key={template.id}
              template={template}
              onInsert={insertTemplate}
              hoveredTemplate={hoveredTemplate}
              setHoveredTemplate={setHoveredTemplate}
            />
          ))}
        </Box>
      )}
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          Cannot insert an Alternating block inside another Alternating block.
        </Alert>
      </Snackbar>
    </Paper>
  );
};

const PreviewSection: React.FC<{ Uwlcontent: Workout[][] }> = ({ Uwlcontent }) => (
  <Paper elevation={3} sx={styles.previewPaper}>
    <Typography variant="body1" sx={styles.sectionTitle}>
      Preview
    </Typography>
    <Box sx={styles.previewBox}>
      <WodUpPreview Uwlcontent={Uwlcontent} />
    </Box>
  </Paper>
);

const ExpandedViewDialog: React.FC<ExpandedViewDialogProps> = ({
  open,
  onClose,
  content,
  onContentChange,
  weekNumber,
  dayNumber,
  UwlContent,
  onEditorDidMount,
  initialCursorPosition,
}) => {
  const [debugOutput, setDebugOutput] = useState<string>('');
  const [showDebugWindow, setShowDebugWindow] = useState(false);

  const handleDebugParse = () => {
    try {
      const sessions = parseUWLContent(UwlContent);
      const wod = parseSession(sessions[0]);
      setDebugOutput(JSON.stringify(wod, null, 2));
      setShowDebugWindow(true);
    } catch (error) {
      setDebugOutput('Error parsing content');
    }
  };

  useEffect(() => {
    if (open && content) {
      onContentChange(content);
    }
  }, [open, content, onContentChange]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth sx={styles.dialog}>
      <DialogHeader weekNumber={weekNumber} dayNumber={dayNumber} />
      {process.env.REACT_APP_SHOW_DEBUG_TOOLS === 'true' && (
        <Button variant="contained" onClick={handleDebugParse} sx={styles.debugButton}>
          Debug Parse
        </Button>
      )}
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.contentWrapper}>
          <EditSection
            content={content}
            onContentChange={onContentChange}
            onEditorDidMount={onEditorDidMount}
            initialCursorPosition={initialCursorPosition}
          />
          <PreviewSection Uwlcontent={UwlContent} />
          {process.env.REACT_APP_SHOW_DEBUG_TOOLS === 'true' && showDebugWindow && (
            <Paper sx={styles.debugWindow}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h6">Debug Output</Typography>
                <IconButton onClick={() => setShowDebugWindow(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography sx={styles.debugOutput}>{debugOutput}</Typography>
            </Paper>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandedViewDialog;
