import { Movement } from '../@monaco-uwl/UWLVisitor/Movement';

interface BaseComponent {
  type: 'Generic' | 'Strength' | 'ForTime' | 'Amrap' | 'WarmUp' | 'Emom';
  name: string;
  notes: string;
  is_alternating: boolean;
  should_be_inside_alternating: boolean;
  alternating_group_id?: string;
  prefix: string;
}

export interface GenericComponent extends BaseComponent {
  type: 'Generic';
  score_by: string;
  description: string;
  movements: string[];
  time_cap: number;
}

export interface StrengthComponent extends BaseComponent {
  type: 'Strength';
  rest_type: string;
  rest_duration?: number;
  movement: Movement;
  numberOfSets: number;
}

export interface ForTimeComponent extends BaseComponent {
  type: 'ForTime';
  movements: Movement[];
  rounds?: number;
  roundRest?: string;
  componentRest?: string;
  isMultipleRounds: boolean;
}

export interface WarmUpComponent extends BaseComponent {
  type: 'WarmUp';
  description: string;
  movements: string[];
}

export interface AmrapComponent extends BaseComponent {
  type: 'Amrap';
  movements: Movement[];
  duration: number;
  duration_unit: string;
}

export interface EmomComponent extends BaseComponent {
  type: 'Emom';
  movements: Movement[];
  low_bound: number;
  up_bound: number | null;
  rounds_low: number;
  rounds_high: number | null
}

export type Component = GenericComponent | StrengthComponent | ForTimeComponent | AmrapComponent | EmomComponent;

export interface Session {
  type: SessionType;
  components: Component[];
}

export enum SessionType {
  AM = 'AM',
  PM = 'PM',
}
