import {
  ANTLRErrorListener,
  ATNConfigSet,
  ATNSimulator,
  BitSet,
  ConsoleErrorListener,
  DefaultErrorStrategy,
  DFA,
  IntervalSet,
  Parser,
  RecognitionException,
  Recognizer,
  Token,
} from 'antlr4ng';
import { editor, MarkerSeverity, Uri } from 'monaco-editor';

import { logger } from '../Logger';

import { createLexer, createParserFromLexer } from './UWLParserFacade';
import { UWLVisitor_a } from './UWLVisitor';
import { Workout } from './UWLVisitor/Workout';

export class UWLError implements editor.IMarkerData {
  startLineNumber: number;
  endLineNumber: number;
  startColumn: number;
  endColumn: number;
  message: string;
  severity: MarkerSeverity;
  code?: string | {
    value: string;
    target: Uri;
};
  constructor(
    startLineNumber: number,
    endLineNumber: number,
    startColumn: number,
    endColumn: number,
    message: string,
    severity: MarkerSeverity,
  ) {
    this.startLineNumber = startLineNumber;
    this.endLineNumber = endLineNumber;
    this.startColumn = startColumn;
    this.endColumn = endColumn;
    this.message = message;
    this.severity = severity;
  }
}

class UWLErrorListener implements ANTLRErrorListener {
  private errors: UWLError[] = [];

  constructor(errors: UWLError[]) {
    this.errors = errors;
  }

  syntaxError<S extends Token, T extends ATNSimulator>(
    recognizer: Recognizer<T>,
    offendingSymbol: S | null,
    line: number,
    charPositionInLine: number,
    msg: string,
    _e: RecognitionException | null,
  ): void {
    const errorEndColumn = this.calculateErrorEndColumn(charPositionInLine, offendingSymbol);
    const errorMessage = this.getErrorMessage(offendingSymbol, recognizer, msg);
    this.logTokenTypeInfo(offendingSymbol, recognizer);
    this.errors.push(
      new UWLError(
        line,
        line,
        charPositionInLine,
        errorEndColumn,
        errorMessage,
        MarkerSeverity.Error,
      ),
    );

    logger.debug(this.errors);
  }

  private calculateErrorEndColumn(charPosition: number, symbol: Token | null): number {
    if (symbol?.text !== undefined && symbol?.text !== null) {
      return charPosition + symbol.text.length;
    }
    return charPosition + 1;
  }

  private getErrorMessage(
    _symbol: Token | null,
    _recognizer: Recognizer<ATNSimulator>,
    _defaultMsg: string,
  ): string {
    // if (symbol?.type === recognizer.getTokenType('ERROR_CHAR')) {
    //   return 'Unrecognized symbol';
    // }
    // return defaultMsg;
    // TODO: Implement proper error messages based on context
    // For now, just show a friendly message to users
    return 'Invalid syntax. A more detailed error handling is coming soon';
  }

  private logTokenTypeInfo(symbol: Token | null, recognizer: Recognizer<ATNSimulator>): void {
    logger.debug(
      symbol?.type,
      ' -- ',
      [...recognizer.getTokenTypeMap()].filter(({ 1: v }) => v === symbol?.type).map(([k]) => k),
    );
  }
  reportAmbiguity(
    recognizer: Parser,
    dfa: DFA,
    startIndex: number,
    stopIndex: number,
    exact: boolean,
    ambigAlts: BitSet | undefined,
    configs: ATNConfigSet,
  ): void {
    // throw new Error("Method not implemented.");
    //logger.info("reportAmbiguity")
  }
  reportAttemptingFullContext(
    recognizer: Parser,
    dfa: DFA,
    startIndex: number,
    stopIndex: number,
    conflictingAlts: BitSet | undefined,
    configs: ATNConfigSet,
  ): void {
    // throw new Error("Method not implemented.");
    //logger.info("reportAttemptingFullContext")
  }
  reportContextSensitivity(
    recognizer: Parser,
    dfa: DFA,
    startIndex: number,
    stopIndex: number,
    prediction: number,
    configs: ATNConfigSet,
  ): void {
    // throw new Error("Method not implemented.");
    //logger.info("reportContextSensitivity")
  }
}

class UWLErrorStrategy extends DefaultErrorStrategy {
  reportUnwantedToken(recognizer: Parser): void {
    logger.debug('reportUnwantedToken');
    return super.reportUnwantedToken(recognizer);
  }

  singleTokenDeletion(recognizer: Parser): Token | null {
    logger.debug('singleTokenDeletion');
    return super.singleTokenDeletion(recognizer);
    // let nextTokenType = recognizer.tokenStream.LA(2);
    // if (recognizer.tokenStream.LA(1) === UniversalWorkoutLanguageParser.MOVEMENT_TERM) {
    //     return null
    // }
    // let expecting = this.getExpectedTokens(recognizer);
    // if (expecting.contains(nextTokenType)) {
    //     this.reportUnwantedToken(recognizer);
    //     recognizer.consume();
    //     let matchedSymbol = recognizer.getCurrentToken();
    //     this.reportMatch(recognizer);
    //     return matchedSymbol;
    // }
    // return null;
  }

  getExpectedTokens(recognizer: Parser): IntervalSet {
    return recognizer.getExpectedTokens();
  }

  reportMatch(recognizer: Parser): void {
    logger.debug('reportMatch');
    this.endErrorCondition(recognizer);
  }

  reportError(recognizer: Parser, e: RecognitionException): void {
    logger.debug('reportError');
    super.reportError(recognizer, e);
  }

  recover(recognizer: Parser, _e: RecognitionException): void {
    logger.debug('recover');
    super.recover(recognizer, _e);
  }

  sync(recognizer: Parser): void {
    logger.debug('sync');
    logger.debug('error rec? == ', this.inErrorRecoveryMode(recognizer));
    /* The default strategy seems to work well enough for now
        if (recognizer.tokenStream.LA(1) === recognizer.getTokenType("MOVEMENT_TERM")) {
            logger.info(recognizer.vocabulary.getDisplayName(recognizer.tokenStream.LA(-1)), "-- !!", );
            logger.info("MT::", recognizer.tokenStream.LT(1)?.text || "NO_!_TEXT");
            logger.info("MN::", recognizer.tokenStream.LT(2)?.text || "NO_!_TEXT");
            if (recognizer.tokenStream.LT(2)?.text === "<EOF>") {
                logger.info("MMM:::", recognizer.vocabulary.getDisplayName(recognizer.tokenStream.LT(2)?.type || 0));
            }
        }

        if (recognizer.tokenStream.LA(1) === recognizer.getTokenType("MOVEMENT_TERM") &&
            recognizer.tokenStream.LA(2) === recognizer.getTokenType("EOF")) {
            logger.info("act");
            // recognizer.
        }
        logger.info( "T::", recognizer.context?.getText())

        logger.info(recognizer.vocabulary.getDisplayName(recognizer.tokenStream.LA(1)));
        logger.info(recognizer.getExpectedTokens().toStringWithVocabulary(recognizer.vocabulary));
        recognizer.tokenStream.LA(1)
        */

    super.sync(recognizer);
  }

  recoverInline(recognizer: Parser): Token {
    logger.debug('recoverInline');
    logger.debug(recognizer.getRuleInvocationStack());

    return super.recoverInline(recognizer);
  }
}

export function validate(input: string): { wods: Workout[][]; errors: UWLError[] } {
  const errors: UWLError[] = [];

  const lexer = createLexer(input);

  lexer.removeErrorListeners();

  if (process.env.REACT_APP_LOG_LEVEL?.toUpperCase() === 'DEBUG') {
    lexer.addErrorListener(new ConsoleErrorListener());
  }

  const parser = createParserFromLexer(lexer);
  parser.removeErrorListeners();
  parser.errorHandler = new UWLErrorStrategy();
  parser.addErrorListener(new UWLErrorListener(errors));

  const v: UWLVisitor_a = new UWLVisitor_a();

  const res = v.GetDay(parser.day());

  errors.push(...v.errors);

  return { wods: res, errors: errors };
}
