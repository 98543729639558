import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { styles } from './TemplateTooltip.styles';
import { TemplateItemProps, SubTemplate } from './types';

const InfoTooltip: React.FC<{ tooltip: string }> = ({ tooltip }) => (
  <Tooltip title={<div dangerouslySetInnerHTML={{ __html: tooltip }} />} placement="right">
    <span>
      <IconButton size="small" sx={styles.infoButton}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </span>
  </Tooltip>
);

const SubTemplateList: React.FC<{
  subTemplates: SubTemplate[];
  onInsert: (template: string) => void;
}> = ({ subTemplates, onInsert }) => (
  <Box sx={styles.subItemsContainer}>
    {subTemplates.map((subTemplate) => (
      <Box
        key={subTemplate.id}
        sx={styles.subItem}
        onClick={(e) => {
          e.stopPropagation();
          onInsert(subTemplate.template);
        }}
      >
        <Typography variant="body2" sx={styles.itemText}>
          {subTemplate.label}
        </Typography>
        <InfoTooltip tooltip={subTemplate.tooltip} />
      </Box>
    ))}
  </Box>
);

const TemplateItem: React.FC<TemplateItemProps> = ({ template, onInsert, hoveredTemplate }) => {
  const [showSubTemplates, setShowSubTemplates] = useState(false);

  useEffect(() => {
    if (!hoveredTemplate) {
      setShowSubTemplates(false);
    }
  }, [hoveredTemplate]);

  const handleClick = () => {
    if (template.subTemplates) {
      setShowSubTemplates(!showSubTemplates);
    } else {
      onInsert(template.template);
    }
  };

  return (
    <Box sx={styles.itemContainer}>
      <Box
        sx={{
          ...styles.mainItem,
          backgroundColor: hoveredTemplate === template.id ? '#e0e0e0' : 'transparent',
          marginBottom: template.subTemplates ? '0px' : '4px',
        }}
        onClick={handleClick}
      >
        <template.icon fontSize="small" sx={styles.icon} />
        <Typography variant="body2" sx={styles.itemText}>
          {template.label}
        </Typography>
        {template.subTemplates ? (
          <ExpandMoreIcon fontSize="small" sx={styles.expandIcon(showSubTemplates)} />
        ) : (
          <InfoTooltip tooltip={template.tooltip} />
        )}
      </Box>

      {template.subTemplates && showSubTemplates && (
        <SubTemplateList subTemplates={template.subTemplates} onInsert={onInsert} />
      )}
    </Box>
  );
};

export default TemplateItem;
