import { useState, useEffect } from 'react';

export const useTooltip = () => {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | null>(null);
  const [hoveredTemplate, setHoveredTemplate] = useState<string | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tooltip = document.getElementById('tooltip');
      const libraryIcon = document.getElementById('library-icon');
      if (
        tooltip && !tooltip.contains(event.target as Node) &&
        libraryIcon && !libraryIcon.contains(event.target as Node)
      ) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return {
    tooltipVisible,
    setTooltipVisible,
    tooltipPosition,
    setTooltipPosition,
    hoveredTemplate,
    setHoveredTemplate
  };
};