import { MarkerSeverity } from 'monaco-editor';

import { logger } from '../../Logger';
import {
  AlternationContext,
  Any_workoutContext,
  DayContext,
  Option_blockContext,
  OptionContext,
  Shorthand_genericContext,
  Shorthand_fortimeContext,
  Shorthand_strengthContext,
  WodContext,
  Workout_attributeContext,
  Workout_commentContext,
  WorkoutContext,
  Shorthand_amrapContext,
  EmomContext,
} from '../antlr/.antlr/UniversalWorkoutLanguageParser';
import { UniversalWorkoutLanguageParserVisitor } from '../antlr/.antlr/UniversalWorkoutLanguageParserVisitor';
import { UWLError } from '../UWLErrorHandler';
import { createParser } from '../UWLParserFacade';

import { workoutDefs } from './constants';
import {
  addDuplicateAttributeError,
  addMissingAttributeErrors,
  addUnrecognizedWorkoutError,
  validateAttribute,
  validateEmomBounds,
} from './errorHandlers';
import { Movement } from './Movement';
import { MovementParser } from './parsers/MovementParser';
import { Dict, AttributeMessageOverride } from './types';
import { normalizeName } from './utils';
import { Workout } from './Workout';

export class UWLVisitor_a extends UniversalWorkoutLanguageParserVisitor<Workout[]> {
  private movementParser: MovementParser;
  errors: UWLError[] = [];

  constructor() {
    super();
    this.movementParser = new MovementParser(this.errors);
  }

  /*
   * Somewhat better performance, though I'm still not sure as to what's causing the issues
   * The parser itself does a decent job, and w/o the Visitor the performance drop is alost unnoticeable from just writing text
   * (The profiler claims <= 1ms per run, which seems to agree w/ the timers in code)
   * Maybe the visit() function is slow?
   */
  public GetDay(ctx: DayContext): Workout[][] {
    const d: Workout[][] = [];

    for (const element of ctx._wods) {
      const w = this.visit(element);
      if (w !== null) {
        d.push(w);
      }
    }
    return d;
  }

  /**
   * ParseDay
   */
  public ParseDay(content: string): Workout[][] {
    const parser = createParser(content);

    return this.GetDay(parser.day());
  }

  private prependName(w: Workout[], s: string): Workout[] {
    w.forEach((_, i: number, a: Workout[]): void => {
      a[i].name = s + a[i].name;
    });
    return w;
  }

  visitWod?: ((ctx: WodContext) => Workout[]) | undefined = (ctx) => {
    const w: Workout[] = [];

    for (let i = 0; i < ctx.getChildCount(); i++) {
      const component = this.visit(ctx.children[i]);
      if (component === null) {
        continue;
      }
      w.push(...component);
    }
    return w;
  };

  visitOption_block?: ((ctx: Option_blockContext) => Workout[]) | undefined = (ctx) => {
    let block_name: string = ctx.CHOICE_NAME()?.toString().trim() || '';

    if (block_name !== null) {
      block_name = block_name + ' - ';
    }

    const o: Workout[] = [];

    for (const opt of ctx._options) {
      const option = this.visit(opt);
      if (option) {
        o.push(...this.prependName(option, block_name));
      }
    }
    return o;
  };

  visitOption?: ((ctx: OptionContext) => Workout[]) | undefined = (ctx) => {
    const o: Workout[] = [];

    const option_title: string = ctx.CHOICE_NAME().getText().trim() + ' | ';

    for (let i = 0; i < ctx.children.length; i++) {
      const element: Workout[] | null = this.visit(ctx.children[i]);
      if (element == null) {
        continue;
      }
      o.push(...this.prependName(element, option_title));
    }
    return o;
  };

  visitAlternation?: ((ctx: AlternationContext) => Workout[]) | undefined = (ctx) => {
    const w: Workout[] = [];
    const alternationNumber = parseInt(ctx.NUMBER().getText());

    for (const workout of ctx._workouts) {
      const c = this.visit(workout);
      if (!c || c.length === 0) continue;

      c.forEach((workout: Workout): void => {
        if (workout) {
          workout.is_alternating = true;
          workout.setsFromAlternation = alternationNumber;
        }
      });
      w.push(...c);
    }

    // Only set last workout's is_alternating to false if we have workouts
    if (w.length > 0) {
      w[w.length - 1].is_alternating = false;
    }

    return w;
  };

  visitAny_workout?: ((ctx: Any_workoutContext) => Workout[]) | undefined = (ctx) => {
    // any_workout is an alternation of possible workout syntaxes and will always have exactly one child
    logger.debug('Children ==>>', ctx.children);

    const result = this.visitChildren(ctx);
    return result ?? [];
  };

  private parseWorkoutMovements(
    ctx:
      | Shorthand_strengthContext
      // | Shorthand_fortimeContext
      | Shorthand_amrapContext
      | WorkoutContext
      | EmomContext,
  ): Movement[] {
    return (
      ctx._movements
        ?.map((movement) => this.movementParser.parseMovement(movement))
        .filter((movement): movement is Movement => movement !== undefined) ?? []
    );
  }

  visitShorthand_strength?: ((ctx: Shorthand_strengthContext) => Workout[]) | undefined = (ctx) => {
    if (!ctx) {
      logger.warn('Received undefined context in visitShorthand_strength');
      return [];
    }
    const strengthWorkout = {
      rest: ctx._rest?.[0]?.text ?? '',
      movements: this.parseWorkoutMovements(ctx),
      comments: this.parseComments(ctx._comments),
      text: ctx.getText() ?? '',
    };
    return [this.createStrengthWorkout(strengthWorkout)];
  };

  private createStrengthWorkout(data: {
    movements: Movement[];
    rest: string;
    comments: string;
    text: string;
  }): Workout {
    const attrs: Dict<string> = { rest: data.rest };
    return this.createWorkoutInstance(
      '', // name
      'strength',
      data.text,
      data.comments,
      attrs,
      data.movements,
    );
  }

  visitShorthand_generic?: ((ctx: Shorthand_genericContext) => Workout[]) | undefined = (ctx) => {
    const name = ctx.STRING()?.getText().trim() ?? '';

    const override: AttributeMessageOverride = {
      name: 'description',
      suppressOtherErrors: true,
      severity: MarkerSeverity.Warning,
      message: 'Description already provided, this will be ignored',
    };

    const [attrs, errors] = this.parseWorkoutAttrs(ctx._attrs, 'generic', [], [override]);

    attrs['description'] = ctx.GENERIC_STRING().getText().replace(/`/g, '');

    this.errors.push(...errors);

    const movements = ctx._movements.map((movement) => this.movementParser.parseMovement(movement));
    const comments = this.parseComments(ctx._comments);

    return [this.createWorkoutInstance(name, 'generic', ctx.getText(), comments, attrs, movements)];
  };

  visitWorkout?: ((ctx: WorkoutContext) => Workout[]) | undefined = (ctx) => {
    const name: string | null = ctx.WORKOUT_STRING()?.getText().trim() || null;
    const type: string = ctx.workout_iden().WORKOUT_IDENTIFIER().getText().trim();

    const workout_attr_list = Object.keys(workoutDefs[normalizeName(type)] ?? {});

    const [attrs, errors] = this.parseWorkoutAttrs(ctx._attrs, type, workout_attr_list);

    addMissingAttributeErrors(ctx, workout_attr_list, normalizeName(type), errors);

    this.errors.push(...errors);

    const m: Movement[] = [];
    for (const mo of ctx._movements) {
      m.push(this.movementParser.parseMovement(mo));
    }

    if (!(normalizeName(type) in workoutDefs)) {
      addUnrecognizedWorkoutError(ctx, type, this.errors);
    }

    const comments = this.parseComments(ctx._comments);

    return [this.createWorkoutInstance(name, type, ctx.getText(), comments, attrs, m)];
  };

  private parseComments(ctx: Workout_commentContext[] | undefined): string {
    if (!ctx) return '';

    let text = '';
    for (const c of ctx) {
      text += c.getText();
      text += '\n';
    }
    return text.trimEnd();
  }

  private parseWorkoutAttrs(
    ctx: Workout_attributeContext[],
    type: string,
    workout_attr_list: string[],
    overrides: AttributeMessageOverride[] | null = null,
  ): [Dict<string>, UWLError[]] {
    const d: Dict<string> = {};
    const e: UWLError[] = [];

    const norm_type = normalizeName(type);

    const existing_attrs: string[] = [];

    for (const attr of ctx) {
      const name: string = attr.workout_attr_id().getText().trim();
      const val: string = attr.workout_attr_val().getText().trim();

      const wati = workout_attr_list.indexOf(name);

      if (wati !== -1) {
        workout_attr_list.splice(wati, 1);
      }

      if (existing_attrs.indexOf(name) === -1) {
        d[name] = val;
        existing_attrs.push(name);
      } else {
        addDuplicateAttributeError(attr, name, e);
      }

      validateAttribute(name, norm_type, val, attr, e, overrides);
    }

    return [d, e];
  }

  protected createWorkoutInstance(
    name: string | null,
    type: string,
    rawText: string,
    comments: string,
    attributes: Dict<string>,
    movements: Movement[],
  ): Workout {
    return new Workout(name, type, rawText, comments, attributes, movements);
  }

  // Fortime
  visitShorthand_fortime?: ((ctx: Shorthand_fortimeContext) => Workout[]) | undefined = (ctx) => {
    if (!ctx) {
      return [];
    }
    const fortimeWorkout = this.parseForTimeWorkoutComponents(ctx);
    return [this.createForTimeWorkout(fortimeWorkout)];
  };

  private parseForTimeWorkoutComponents(ctx: Shorthand_fortimeContext) {
    // Get all intermediate movements and their rests
    const intermediateMovements =
      ctx.intermediate_movement()?.map((im) => {
        const movement = this.movementParser.parseMovement(im);
        movement.restAfter = im._rest?.[0]?.text;
        return movement;
      }) ?? [];

    // Get final movement and handle both types of rest
    const finalMovementCtx = ctx.final_movement();
    const finalMovement = finalMovementCtx
      ? {
          movement: this.movementParser.parseMovement(finalMovementCtx),
          roundRest: finalMovementCtx._round_rest?.text,
          componentRest: finalMovementCtx._component_rest?.text,
        }
      : undefined;

    // Combine all movements
    const movements = [
      ...intermediateMovements,
      ...(finalMovement?.movement ? [finalMovement.movement] : []),
    ].filter((m): m is Movement => m !== undefined);

    return {
      rounds: ctx._rounds_num?.text || ctx._sets_num?.text || undefined,
      movements,
      roundRest: finalMovement?.roundRest,
      componentRest: finalMovement?.componentRest,
      comments: this.parseComments(ctx._comments),
      text: ctx.getText() ?? '',
    };
  }

  private createForTimeWorkout(data: {
    rounds?: string;
    movements: Movement[];
    roundRest?: string;
    componentRest?: string;
    comments: string;
    text: string;
  }): Workout {
    const attrs: Dict<string> = {
      rounds: data.rounds ?? '',
      round_rest: data.roundRest ?? '',
      component_rest: data.componentRest ?? '',
    };

    return this.createWorkoutInstance(
      '', // name
      'ForTime',
      data.text,
      data.comments,
      attrs,
      data.movements,
    );
  }

  visitShorthand_amrap?: ((ctx: Shorthand_amrapContext) => Workout[]) | undefined = (ctx) => {
    if (!ctx) {
      return [];
    }
    const amrapWorkout = this.parseAmrapWorkoutComponents(ctx);
    return [this.createAmrapWorkout(amrapWorkout)];
  };

  private parseAmrapWorkoutComponents(ctx: Shorthand_amrapContext) {
    return {
      duration: ctx.NUMBER().getText(),
      duration_unit: ctx.TIME_UNIT().getText().trim(),
      movements: this.parseWorkoutMovements(ctx),
      comments: this.parseComments(ctx._comments),
      text: ctx.getText() ?? '',
    };
  }

  private createAmrapWorkout(data: {
    duration: string;
    duration_unit: string;
    movements: Movement[];
    comments: string;
    text: string;
  }): Workout {
    const attrs: Dict<string> = {
      duration: data.duration,
      duration_unit: data.duration_unit,
    };

    return this.createWorkoutInstance(
      '', // name
      'Amrap',
      data.text,
      data.comments,
      attrs,
      data.movements,
    );
  }

  visitEmom?: ((ctx: EmomContext) => Workout[]) | undefined = (ctx) =>{
    // Note: Right now we are under the assumption that there is only one movement per minute
    // If this changes we will likely need to add a new attribute

    const errors : UWLError[] = [];
    
    const emom_low_bound : number | null = ctx._emom_low_bound?.text !== undefined ? Number.parseInt(ctx._emom_low_bound.text) : null;
    const emom_high_bound : number | null = ctx._emom_high_bound?.text !== undefined ? Number.parseInt(ctx._emom_high_bound.text) : null;
    logger.debug("EMOM::", emom_low_bound, "--", emom_high_bound);

    const attrs : Dict<string> = {
        "emom_low_bound": ctx._emom_low_bound?.text ?? '',
        "emom_high_bound": ctx._emom_high_bound?.text ?? ''
      }

    const w = new Workout(
      null,
      'emom',
      ctx.getText() ?? '',
      this.parseComments(ctx._comments),
      attrs,
      this.parseWorkoutMovements(ctx)
    )

    const no_mins = w.movements.length

    validateEmomBounds(no_mins, ctx._emom_low_bound, ctx._emom_high_bound, errors)

    this.errors.push(...errors);

    logger.debug(w)

    return [w]    
  };
}
