export const styles = {
  // Dialog styles
  dialog: {
    '& .MuiDialog-paper': {
      maxHeight: '90vh',
      mt: 2,
    },
  },

  dialogTitle: {
    bgcolor: '#2A2A2A',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 2,
  },

  closeIcon: {
    color: 'white',
  },

  dialogContent: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mt: 2,
  },

  dialogActions: {
    p: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },

  section: {
    mb: 1,
  },

  sectionLabel: {
    mb: 0.5,
    fontWeight: 500,
  },

  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1976d2',
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#1976d2',
    },
  },

  attributesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 0.5,
  },

  priorityLabel: {
    display: 'flex',
    flexDirection: 'column',
  },

  // Alias section styles
  aliasContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },

  aliasChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },

  aliasChip: {
    backgroundColor: '#F0F2F5',
    borderRadius: '4px',
    height: '32px',
    '& .MuiChip-label': {
      fontSize: '14px',
      padding: '0 12px',
      fontWeight: 500,
    },
    '& .MuiChip-deleteIcon': {
      fontSize: '18px',
      margin: '0 6px 0 -4px',
      color: '#666',
      '&:hover': {
        color: '#E31B23',
      },
    },
    '&:hover': {
      backgroundColor: '#E8EAF0',
    },
  },

  aliasInputContainer: {
    display: 'flex',
    gap: 0.5,
    marginTop: 0.5,
  },

  aliasInput: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      height: '36px',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
      fontSize: '14px',
    },
  },

  aliasButton: {
    height: '36px',
    minWidth: '64px',
    backgroundColor: '#1976d2',
    borderRadius: '4px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    padding: '0 16px',
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },

  removeButton: {
    color: '#E31B23',
    borderColor: '#E31B23',
    '&:hover': {
      borderColor: '#E31B23',
      bgcolor: 'rgba(227, 27, 35, 0.04)',
    },
  },

  cancelButton: {
    mr: 1,
    textTransform: 'none',
  },

  saveButton: {
    textTransform: 'none',
    bgcolor: '#1976d2',
    '&:hover': {
      bgcolor: '#1565c0',
    },
    '&.Mui-disabled': {
      bgcolor: 'rgba(25, 118, 210, 0.12)',
    },
  },

  videoUrlContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },

  videoUrlField: {
    flex: 1,
  },

  descriptionField: {
    mt: 2,
  },
} as const;
