import { CancellationToken, editor, IRange, languages, Position } from 'monaco-editor';

import { logger } from '../../Logger';

import {
  createDistanceUnitProposals,
  createLoadProposals,
  createMovementProposals,
  createRestProposals,
  createTimeProposals,
} from './movementProposals';
import {
  createParameterProposals,
  createWorkoutProposals,
  createEmomMinuteCompletion,
} from './proposals';
import { PATTERNS } from './types';

/**
 *
 *
 * @param {Position} position
 * @param {editor.IWordAtPosition} word
 * @return {*}  {IRange}
 */
function createRange(position: Position, word: editor.IWordAtPosition): IRange {
  return {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: word.startColumn,
    endColumn: word.endColumn,
  };
}

/**
 * Returns the value of a line from its beginning to the **beginning** of the word under the caret
 *
 * @param {editor.ITextModel} model Model from which to extract text
 * @param {Position} position Position of the caret
 * @param {editor.IWordAtPosition} word Word pointed to by the caret
 * @return {*}  {string} The text in the specified range
 */
function getTextUntilPosition(
  model: editor.ITextModel,
  position: Position,
  word: editor.IWordAtPosition,
): string {
  return model.getValueInRange({
    startLineNumber: position.lineNumber,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: word.startColumn,
  });
}

function getPrecedingText(
  model: editor.ITextModel,
  position: Position,
  word: editor.IWordAtPosition,
): string {
  return model.getValueInRange({
    startLineNumber: 1,
    endLineNumber: position.lineNumber,
    startColumn: 1,
    endColumn: word.endColumn,
  });
}

function getWithPrevLine(
  model: editor.ITextModel,
  position: Position,
  word: editor.IWordAtPosition,
): string {
  return model.getValueInRange({
    startLineNumber: Math.max(1, position.lineNumber - 1),
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: word.startColumn,
  });
}

function getIndexOfFirstNonWhitespaceAfterSymbol(text: string, symbol: string, ) {
  if (symbol.length === 0) {
    throw new Error("Passed empty string as symbol");
  }
  const symbolIndex = text.indexOf(symbol[0]);

  const index = (text.substring(symbolIndex + 1).match(/\S/)?.index ?? 0) + symbolIndex;
  return index;
}

export const UWLAutocompletionProvider: languages.CompletionItemProvider = {
  triggerCharacters: ['%', '*', '>', ' '],
  provideCompletionItems: function (
    model: editor.ITextModel,
    position: Position,
    context: languages.CompletionContext,
    token: CancellationToken,
  ): languages.ProviderResult<languages.CompletionList> {
    logger.info(
      `provideCompletionItems:: ${position} -- ${context.triggerKind} : ${context.triggerCharacter || 'None'} -- ${token.isCancellationRequested}`,
    );
    const word = model.getWordUntilPosition(position);
    const range = createRange(position, word);
    const textUntilPosition = getTextUntilPosition(model, position, word);

    let match = textUntilPosition.match(PATTERNS.UNIT.DISTANCE);
    if (match) {
      return { suggestions: createDistanceUnitProposals(range) };
    }

    match = textUntilPosition.match(PATTERNS.UNIT.LOAD);
    if (match) {
      return { suggestions: createLoadProposals(range) };
    }

    match = textUntilPosition.match(PATTERNS.UNIT.TIME);
    if (match) {
      return { suggestions: createTimeProposals(range)};
    }

    match = textUntilPosition.match(PATTERNS.REST); // there are some inconsistencies with quotes
    if (match) {
      return { suggestions: createRestProposals(range) };
    }
    match = textUntilPosition.match(PATTERNS.MOVEMENT);
    // Movements may be multiple words
    if (match) {
      const movRange : IRange = {
        startLineNumber: position.lineNumber,
        startColumn: getIndexOfFirstNonWhitespaceAfterSymbol(textUntilPosition, '>') + 2,
        endLineNumber: position.lineNumber,
        endColumn: word.endColumn
      }
      const movementProposals = createMovementProposals(movRange);
      return { suggestions: movementProposals };
    }

    const precedingText = model.getValueInRange({
      startLineNumber: 1,
      endLineNumber: position.lineNumber,
      startColumn: 1,
      endColumn: word.endColumn,
    });

    match = precedingText.match(PATTERNS.PARAMETER);

    if (match) {
      return { suggestions: createParameterProposals(textUntilPosition, range) };
    }

    match = precedingText.match(PATTERNS.SHORT_GENERIC);

    if (match) {
      return { suggestions: [] };
    }

    const ret: languages.ProviderResult<languages.CompletionList> = {
      suggestions: createWorkoutProposals(range),

    };

    const withPrevLine = model.getValueInRange({
      startLineNumber: Math.max(1, position.lineNumber - 1),
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: word.startColumn,
    });

    const emomMatch = withPrevLine.match(PATTERNS.EMOM_ADD);
    if (emomMatch) {
      ret.suggestions.push(createEmomMinuteCompletion(emomMatch, range));
    }

    return ret;
  },
};
