import { UNIT_RULES } from '../@monaco-uwl/rules';
import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { GenericComponent } from './parser.types';

function prepareGenericAttribute(value: string | undefined | null, defaultValue = ''): string {
  if (!value) {
    return defaultValue;
  }

  const hasQuotes = value.startsWith('"') && value.endsWith('"');
  let processedValue = hasQuotes ? value.slice(1, -1) : value;

  if (processedValue.startsWith('\n')) {
    processedValue = processedValue.slice(1);
  }
  if (processedValue.endsWith('\n')) {
    processedValue = processedValue.slice(0, -1);
  }

  return processedValue;
}

function parseTimeToSeconds(time: string): number {
  const [minutes, seconds] = time.split(':').map(Number);
  return (minutes || 0) * 60 + (seconds || 0);
}

export function parseUWLGeneric(w: Workout, prefix: string): GenericComponent {
  const rawScoreBy = prepareGenericAttribute(w.attrs['score_by'], 'none');
  const normalizedScore = rawScoreBy.toLowerCase().trim();
  const score_by = UNIT_RULES.SCORE.mappings[normalizedScore] || 'none';

  const timeCapInSeconds = w.attrs['time_cap'] ? parseTimeToSeconds(w.attrs['time_cap']) : 0;

  return {
    type: 'Generic',
    prefix: prefix,
    is_alternating: w.is_alternating,
    should_be_inside_alternating: false,
    movements: w.movementsToString(),
    // currently we are hardcoding the name to simplify language for coaches
    name: 'Custom Component', // prepareGenericAttribute(w.name, 'Custom Component'),
    score_by,
    description: prepareGenericAttribute(w.attrs['description'], ''),
    time_cap: timeCapInSeconds,
    notes: w.notes,
  };
}
