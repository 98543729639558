import { useState, useCallback } from 'react';

export const useWeeks = (initialWeeks: number) => {
  const [weeks, setWeeks] = useState(initialWeeks);

  const addWeeks = useCallback(
    (numberOfWeeks: number) => setWeeks((prev) => prev + numberOfWeeks),
    [],
  );

  const removeWeeks = useCallback(
    (numberOfWeeks: number) => setWeeks((prev) => Math.max(1, prev - numberOfWeeks)),
    [],
  );

  return { weeks, setWeeks, addWeeks, removeWeeks };
};
