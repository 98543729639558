import { CSSProperties } from 'react';

export const styles = {
  wrapper: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  innerBox: {
    display: 'flex',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  buttonContainer: {
    display: 'flex',
    backgroundColor: '#1976d2',
  },
  textField: {
    width: '24px',
    textAlign: 'center' as CSSProperties['textAlign'],
    padding: '8px',
  },
  button: {
    color: 'white',
    fontSize: '13px',
    padding: '8px 16px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  addButton: {
    borderRight: '1px solid rgba(255,255,255,0.2)',
  },
};
