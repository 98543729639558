import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { styles } from '../../WodUpPreview.styles';
import { TimeType } from '../../WodUpPreview.types';
import { areArrayValuesEqual } from '../StrengthPreview/StrengthPreviewUtils';

import { SetRowProps } from './SetRow.types';

export const SetRow: React.FC<SetRowProps> = ({
  index,
  numberOfSets,
  attributes,
  exerciseName,
}) => {
  const {
    reps = [],
    load = [],
    tempo = [],
    duration = [],
    distance = [],
    load_unit: loadUnit = '',
    distance_unit: distanceUnit = '',
  } = attributes;

  const parts = [];

  if (reps.length > 0) {
    const repCount = index !== null ? reps[index] : reps[0];
    const isAmrap = typeof repCount === 'string' && repCount.toUpperCase().startsWith('AMRAP');

    if (isAmrap || repCount === 'X') {
      const timeMatch = repCount.match(/\(([-\d]+)\)/);
      parts.push(timeMatch ? `AMRAP(${timeMatch[1]})` : 'AMRAP');
    } else {
      parts.push(`${repCount} ${repCount === '1' ? 'rep' : 'reps'}`);
    }
  }

  if (duration.length > 0) {
    const currentDuration = index !== null ? duration[index] : duration[0];
    parts.push(currentDuration === 'X' ? TimeType.MAX_TIME : `for ${currentDuration}`);
  }

  if (distance.length > 0) {
    const currentDistance = index !== null ? distance[index] : distance[0];
    parts.push(
      currentDistance === 'X' ? TimeType.MAX_DISTANCE : `${currentDistance} ${distanceUnit}`,
    );
  }

  if (load.length > 0) {
    const currentLoad = index !== null ? load[index] : load[0];
    parts.push(currentLoad === 'X' ? '@ max load' : `@ ${currentLoad} ${loadUnit}`);
  }

  if (tempo.length > 0 && (!areArrayValuesEqual(tempo) || numberOfSets === undefined)) {
    const currentTempo = index !== null ? tempo[index] : tempo[0];
    parts.push(`(${currentTempo} tempo)`);
  }

  return (
    <Box sx={styles.setRowWrapper}>
      <ArrowRightIcon sx={styles.setRowIcon} />
      <Typography sx={{ ...styles.sectionContent, ...styles.setRowContent }}>
        {exerciseName && <span>{exerciseName}:</span>}{' '}
        {index !== null
          ? `Set ${index + 1} - ${parts.join(' ')}`
          : numberOfSets
            ? `${numberOfSets} ${numberOfSets === 1 ? 'set' : 'sets'} of ${parts.join(' ')}`
            : parts.join(' ')}
      </Typography>
    </Box>
  );
};
