import { Box, Typography } from "@mui/material";

import { Movement } from "../../../../@monaco-uwl/UWLVisitor/Movement";
import { SessionType } from "../../../../generalParser/parser.types";
import { logger } from "../../../../Logger";
import { styles } from "../../WodUpPreview.styles"
import { MovementDefaults } from "../../WodUpPreview.types";
import { SetRow } from "../SetRow/SetRow";

import { EmomPreviewProps } from "./EmomPreview.types";

const getMovementName = (movement: Movement): string => {
  if (!movement?.names?.length) return MovementDefaults.UNNAMED_MOVEMENT;
  return movement.names.join(' + ');
};

export const EmomPreview: React.FC<EmomPreviewProps> = ({ component, sessionType }) => {
  const renderMovement = (movement: Movement, index: number) => {
    const movementName = getMovementName(movement);

    return (
      <Box key={index} sx={styles.movementItem}>
        <SetRow index={null} exerciseName={movementName} attributes={movement.attributes} />
      </Box>
    );
  };
  
  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          {`${component.name || 'EMOM'} ${component.low_bound}${component.up_bound ? ' - ' : ''}${component.up_bound || ''} Min`}
        </Typography>
      </Box>
      
      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.sectionContent}> 
          {`${component.rounds_low === -1 ? '???' : component.rounds_low}${component.rounds_high ? ' - ' : ''}${component.rounds_high === null ? '' : ( component.rounds_high === -1 ? '???' : component.rounds_high )} Rounds`}
        </Typography>
        <Typography sx={styles.sectionTitle}>Minutes:</Typography>
        <Box sx={styles.sectionContent}>
          {component.movements && component.movements.length > 0 ? (
            component.movements.map(renderMovement)
          ) : (
            <Typography sx={styles.noMovements}>No minutes specified</Typography>
          )}
        </Box>

        <Typography sx={styles.sectionTitle}>Notes:</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};

