import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import SettingsIcon from '@mui/icons-material/Settings';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Paper,
  CircularProgress,
  Box,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../api/base';
import CreateProgramForm from '../../components/CreateProgramForm';
import { logger } from '../../Logger';

import { styles } from './ProgramList.styles';

export interface Program {
  id: number;
  name: string;
  description: string;
  days: number;
  clientCount: number;
}

const ProgramsList: React.FC = () => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editingProgram, setEditingProgram] = useState<Program | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPrograms();
  }, []);

  const fetchPrograms = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/programs');
      setPrograms(response.data);
    } catch (error) {
      logger.error('Error fetching programs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProgramSelect = (programId: number) => {
    navigate(`/workout-planner/${programId}`);
  };

  const handleCreateProgram = async (program: Program) => {
    setPrograms([...programs, program]);
    setShowCreateForm(false);
    await fetchPrograms();
  };

  const handleEditProgram = (program: Program) => {
    setEditingProgram(program);
    setShowCreateForm(true);
  };

  const handleUpdateProgram = async (updatedProgram: Program) => {
    try {
      await api.put(`/api/programs/${updatedProgram.id}`, updatedProgram);
      setPrograms(programs.map((p) => (p.id === updatedProgram.id ? updatedProgram : p)));
      setShowCreateForm(false);
      setEditingProgram(null);
    } catch (error) {
      logger.error('Error updating program:', error);
    }
  };

  const handleDeleteProgram = async (programId: number) => {
    if (window.confirm('Are you sure you want to delete this program?')) {
      try {
        await api.delete(`/api/programs/${programId}`);
        setPrograms(programs.filter((p) => p.id !== programId));
      } catch (error) {
        logger.error('Error deleting program:', error);
      }
    }
  };

  if (showCreateForm) {
    return (
      <CreateProgramForm
        onSubmit={editingProgram ? handleUpdateProgram : handleCreateProgram}
        onCancel={() => {
          setShowCreateForm(false);
          setEditingProgram(null);
        }}
        initialProgram={editingProgram}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={styles.container}>
      <Box sx={styles.headerBox}>
        <Typography variant="h4" fontWeight="bold">
          Programs
        </Typography>
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setShowCreateForm(true)}
            sx={styles.createButton}
          >
            Create program
          </Button>
        </Box>
      </Box>

      <Box sx={styles.searchBox}>
        <TextField fullWidth variant="outlined" placeholder="Search" sx={styles.searchField} />
      </Box>

      <Paper elevation={3}>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <List disablePadding>
            <ListItem sx={styles.listHeader}>
              <Box width="10%" fontWeight="bold">
                Days
              </Box>
              <Box width="75%" fontWeight="bold">
                Name
              </Box>
              <Box width="15%" />
            </ListItem>
            {programs.map((program) => (
              <ListItem disablePadding key={program.id} sx={styles.listItem}>
                <ListItemButton
                  onClick={() => handleProgramSelect(program.id)}
                  sx={styles.listItemButton}
                >
                  <Box sx={styles.daysColumn}>
                    <CalendarTodayIcon sx={{ mr: 1, color: 'text.secondary' }} />
                    <Typography>{program.days}</Typography>
                  </Box>
                  <Box sx={styles.nameColumn}>
                    <Typography variant="subtitle1" fontWeight="medium">
                      {program.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {program.description}
                    </Typography>
                  </Box>
                  <Box sx={styles.actionsColumn}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditProgram(program);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProgram(program.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Container>
  );
};

export default ProgramsList;
