export const styles = {
  container: {
    mt: 4,
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 4,
  },
  createButton: {
    mr: 2,
    textTransform: 'none',
    bgcolor: '#1976d2',
    '&:hover': { bgcolor: '#1565c0' },
  },
  searchBox: {
    display: 'flex',
    mb: 4,
  },
  searchField: {
    mr: 2,
    bgcolor: 'background.paper',
  },
  listHeader: {
    bgcolor: 'grey.100',
    py: 2,
    px: 3,
  },
  listItem: {
    borderBottom: '1px solid',
    borderColor: 'grey.200',
  },
  listItemButton: {
    py: 2,
    px: 3,
  },
  daysColumn: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
  },
  nameColumn: {
    width: '75%',
  },
  actionsColumn: {
    width: '15%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};
