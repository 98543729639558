import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { AmrapComponent } from './parser.types';
import { prepareNotes } from './utils';

export function parseUWLAmrap(w: Workout, prefix: string): AmrapComponent {
  return {
    type: 'Amrap',
    is_alternating: w.is_alternating,
    should_be_inside_alternating: false,
    prefix: prefix,
    duration: parseDurationToSeconds(w.attrs.duration, w.attrs.duration_unit),
    duration_unit: w.attrs.duration_unit,
    name: '',
    movements: w.movements,
    notes: prepareNotes(w.notes),
  };
}

export function parseDurationToSeconds(duration: string, durationUnit: string): number {
  const durationNumber = parseInt(duration);
  const normalizedUnit = durationUnit.toLowerCase();

  if (normalizedUnit === 'min' || normalizedUnit === 'm') {
    return durationNumber * 60;
  }

  if (normalizedUnit === 'sec' || normalizedUnit === 's') {
    return durationNumber;
  }

  return durationNumber;
}
