import { Movement } from '../../services/movementsService';

export function mapToMovementModel(data: any): Movement {
  return {
    id: data.id,
    name: data.name,
    aliases: data.aliases || [],
    has_reps: data.has_reps,
    has_load: data.has_load,
    has_duration: data.has_duration,
    has_distance: data.has_distance,
    has_height: data.has_height,
    has_power: data.has_power,
    has_calories: data.has_calories,
    is_complex: data.is_complex,
    complex_details: data.complex_details || undefined,
    category: data.category || undefined,
    videos: data.videos || [],
    gym_id: data.gym_id || undefined,
    created_at: data.created_at || undefined,
    updated_at: data.updated_at || undefined,
    video_url: data.video_url || undefined,
    is_priority: data.is_priority || false,
  };
}   

export function mapToMovementModels(dataArray: any[]): Movement[] {
  return dataArray.map(mapToMovementModel);
}
