import { Box, Typography } from '@mui/material';
import React from 'react';

import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';
import { SessionType } from '../../../../generalParser/parser.types';
import { styles } from '../../WodUpPreview.styles';
import { MovementDefaults } from '../../WodUpPreview.types';
import { SetRow } from '../SetRow/SetRow';

import { ForTimePreviewProps } from './ForTimePreview.types';

const getMovementName = (movement: Movement): string => {
  if (!movement?.names?.length) return MovementDefaults.UNNAMED_MOVEMENT;
  return movement.names.join(' + ');
};

export const ForTimePreview: React.FC<ForTimePreviewProps> = ({ component, sessionType }) => {
  const renderMovement = (movement: Movement, index: number) => {
    const movementName = getMovementName(movement);

    return (
      <Box key={index} sx={styles.movementItem}>
        <Box sx={styles.movementRow}>
          <SetRow index={null} exerciseName={movementName} attributes={movement.attributes} />
        </Box>
        {movement.restAfter && (
          <Typography sx={styles.restAfterMovement}>Rest: {movement.restAfter}</Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          {`${component.prefix || ''}${component.prefix ? '. ' : ''}${component.name || 'For Time'}`}
        </Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.sectionTitle}>Movements:</Typography>
        <Box sx={styles.sectionContent}>
          {component.movements && component.movements.length > 0 ? (
            component.movements.map(renderMovement)
          ) : (
            <Typography sx={styles.noMovements}>No movements specified</Typography>
          )}
        </Box>

        {component.roundRest && (
          <>
            <Typography sx={styles.sectionTitle}>Rest after each round:</Typography>
            <Typography sx={styles.sectionContent}>{component.roundRest}</Typography>
          </>
        )}

        {component.componentRest && (
          <>
            <Typography sx={styles.sectionTitle}>Rest after all rounds:</Typography>
            <Typography sx={styles.sectionContent}>{component.componentRest}</Typography>
          </>
        )}

        <Typography sx={styles.sectionTitle}>Notes</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};
