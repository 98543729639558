import { IMarkdownString, IRange, languages } from 'monaco-editor';

import { UNIT_RULES } from '../../@monaco-uwl/rules';
import movementsData from '../../assets/basic_movements.json';
import { movementCache } from '../../services/MovementCache';
import { Movement } from '../../services/movementsService';
import { mapToMovementModels } from '../utils/getMovementsFromFE';

const STRINGS = {
  LOAD: UNIT_RULES.LOAD.suggested.join(','),
  DISTANCE: UNIT_RULES.DISTANCE.suggested.join(','),
  DURATION: UNIT_RULES.TIME.suggested.join(','),
};

const mappedMovementsData: Movement[] = process.env.REACT_APP_USE_MOVEMENTS_FROM_FE === 'true'
  ? mapToMovementModels(movementsData)
  : movementCache.getMovements();

export function generateMovementInsertText(movement: Movement): string {
  let tabstopIndex = 1;
  let text = `${movement.name}:`;

  // TODO: Improve movement proposals to handle different valid combinations
  // @see https://www.notion.so/Fix-autocomplete-proposals-for-certain-movements-16f2e3ae409080509524d51c8f11be62?pvs=23
  if (movement.has_reps || movement.has_load) {
    if (movement.has_reps) {
      text += ` \${${tabstopIndex}:reps}`;
      tabstopIndex += 1;
    }

    if (movement.has_load) {
      text += ` x \${${tabstopIndex}:load} \${${tabstopIndex + 1}|${STRINGS.LOAD}|}`;
      tabstopIndex += 2;
    }

    if (movement.has_reps && movement.has_load) {
      text += ` @ \${${tabstopIndex}:tempo}`;
      tabstopIndex += 1;
    }
  } else if (movement.has_height) {
    text += ` \${${tabstopIndex}:height} \${${tabstopIndex + 1}|cm,inches|}`;
  }
  // TODO: Enable power once language supports it
  // else if (movement.has_power) {
  //   text += ` \${${tabstopIndex}:watts} W`;
  // }
  // TODO: Enable calories once preview is working
  // else if (movement.has_calories) {
  //   text += ` \${${tabstopIndex}:calories} cal`;
  // }
  else if (movement.has_distance) {
    text += ` for \${${tabstopIndex}:distance} \${${tabstopIndex + 1}|${STRINGS.DISTANCE}|}`;
  } else if (movement.has_duration) {
    text += ` in \${${tabstopIndex}:duration} \${${tabstopIndex + 1}|${STRINGS.DURATION}|}`;
  }

  return text;
}

function generateMovementDocumentation(movement: Movement, label: string): IMarkdownString {
  const parts: string[] = [];

  if (label !== movement.name) {
    parts.push(`**Alias of:** ${movement.name}\n`);
  } else if (movement.aliases && movement.aliases.length > 0) {
    const aliasNames = movement.aliases.map((a) => a.alias);
    parts.push(`**Aliases:** ${aliasNames.join(', ')}\n`);
  }

  const capabilities = [
    movement.has_reps && 'repetitions',
    movement.has_load && 'load/weight',
    movement.has_duration && 'duration (time)',
    movement.has_distance && 'distance',
    movement.has_calories && 'calories',
    movement.has_power && 'power (watts)',
    movement.has_height && 'height',
  ].filter(Boolean);

  if (capabilities.length > 0) {
    parts.push(`**Supports:** ${capabilities.join(', ')}\n`);
  }

  if (movement.is_complex) {
    parts.push(
      `**Complex movement:** ${movement.complex_details || 'Multiple movements combined'}\n`,
    );
  }

  const examples = [];
  if (movement.has_reps && movement.has_load) {
    examples.push('**Examples:**');
    if (movement.category === 'weightlifting') {
      examples.push('- 5 x 185 lbs @ 3110');
      examples.push('- 3,3,3 x 70,75,80 %RM @ 31X0');
    } else {
      examples.push('- 10 x 1.5 %BW @ 2011');
      examples.push('- 8,8,8 x 135 lbs @ 20X0');
    }
  } else if (movement.has_reps) {
    examples.push('**Examples:**');
    examples.push('- 21,15,9');
    examples.push('- 10 each side');
  } else if (movement.has_distance) {
    examples.push('**Examples:**');
    examples.push('- for 400 m');
    examples.push('- for 1 mile');
  } else if (movement.has_duration) {
    examples.push('**Examples:**');
    examples.push('- in 2:00');
    examples.push('- in 0:30');
  } else if (movement.has_calories) {
    examples.push('**Example:** 15 cal');
  }

  if (examples.length > 0) {
    parts.push(examples.join('\n'));
  }

  if (movement.video_url) {
    parts.push('\n**Watch demonstration video:**');
    if (movement.video_url.includes('youtube.com')) {
      const videoId = movement.video_url.split('v=')[1];
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      parts.push(`[![video](${thumbnailUrl})](${movement.video_url})`);
    } else {
      parts.push(movement.video_url);
    }
  }

  return {
    value: parts.join('\n'),
    isTrusted: true,
  };
}

export function createMovementProposals(range: IRange): languages.CompletionItem[] {
  const proposals: languages.CompletionItem[] = [];

  mappedMovementsData.forEach((movement) => {
    proposals.push(createMovementProposal(movement, movement.name, range));

    if (movement.aliases) {
      movement.aliases.forEach((alias) => {
        proposals.push(createMovementProposal(movement, alias.alias, range));
      });
    }
  });

  return proposals;
}

function createMovementProposal(
  movement: Movement,
  label: string,
  range: IRange,
): languages.CompletionItem {
  const priorityPrefix = movement.is_priority ? 'a' : 'b';
  const FIRST_LETTER_ASCII = 97;
  const MAX_SORTABLE_LENGTH = 25;
  const sortableLength = Math.min(label.length, MAX_SORTABLE_LENGTH);
  const lengthAsLetter = String.fromCharCode(FIRST_LETTER_ASCII + sortableLength);
  const sortPriority = `${priorityPrefix}${lengthAsLetter}_${label}`;

  return {
    label,
    kind: languages.CompletionItemKind.Text,
    insertText: generateMovementInsertText(movement),
    insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
    range: range,
    documentation: generateMovementDocumentation(movement, label),
    sortText: sortPriority,
  };
}

export function createRestProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: 'None',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: '"None"',
      insertTextRules: languages.CompletionItemInsertTextRule.None,
      range: range,
      documentation: 'No rest period between sets\nExample: rest: "None"',
    },
    {
      label: 'As Needed',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: '"As Needed"',
      insertTextRules: languages.CompletionItemInsertTextRule.None,
      range: range,
      documentation:
        'Athlete determines rest period based on readiness\nExample: rest: "As Needed"',
    },
    {
      label: 'Fixed',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: '"Fixed ${1:mm}:${2:ss}"',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation: 'Set rest period in minutes and seconds\nExample: rest: "Fixed 2:00"',
    },
    {
      label: 'Every X seconds',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: '"On a running clock, start a set every ${1:mm}:${2:ss}"',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation:
        'EMOM-style timing with fixed intervals\nExample: rest: "On a running clock, start a set every 1:30"',
    },
    {
      label: 'Time taken to complete set',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: '"ratio ${1:}"',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation:
        'Rest period relative to work period\nExample: rest: "Rest 1.5 X the set\'s duration"',
    },
  ];
}

export function createLoadProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: '%RM',
      kind: languages.CompletionItemKind.Keyword,
      insertText: '%RM',
      range: range,
      documentation: 'Percentage of 1 Rep Max\nExample: Back Squat: 5 x 75 %RM',
    },
    {
      label: '%Bodyweight',
      kind: languages.CompletionItemKind.Keyword,
      insertText: '%Bodyweight',
      range: range,
      documentation: "Percentage of athlete's bodyweight\nExample: Deadlift: 3 x 150 %Bodyweight",
    },
    {
      label: 'kg',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'kg',
      range: range,
      documentation: 'Weight in kilograms\nExample: Clean: 3 x 60 kg',
    },
    {
      label: 'lbs',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'lbs',
      range: range,
      documentation: 'Weight in pounds\nExample: Snatch: 2 x 135 lbs',
    },
    {
      label: 'pood',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'pood',
      range: range,
      documentation:
        'Traditional kettlebell weight measurement (1 pood ≈ 16kg/35lbs)\nExample: Kettlebell Swing: 20 x 2 pood',
    },
  ];
}

export function createDistanceUnitProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: 'ft',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'ft',
      range: range,
      documentation: 'Distance in feet\nExample: Walking Lunge: 10 x BW for 100 ft',
    },
    {
      label: 'yards',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'yards',
      range: range,
      documentation: 'Distance in yards\nExample: Running: for 400 yards',
    },
    {
      label: 'miles',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'miles',
      range: range,
      documentation: 'Distance in miles\nExample: Running: for 1 miles',
    },
    {
      label: 'm',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'm',
      range: range,
      documentation: 'Distance in meters\nExample: Running: for 400 m',
    },
    {
      label: 'km',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'km',
      range: range,
      documentation: 'Distance in kilometers\nExample: Running: for 5 km',
    },
  ];
}

export function createTimeProposals(range: IRange): languages.CompletionItem[] {
  return [
    {
      label: 's',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 's',
      range: range,
      documentation: 'Time in seconds',
    },
    {
      label: 'min',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'min',
      range: range,
      documentation: 'Time in minutes',
    },
  ];
}
