export const PATTERNS = {
  REST: /[Rr]est:?\s*/,
  PARAMETER: /.*\([^)]*$/,
  PARAMETERS: {
    SCORE_BY: /^\s*score_by\s*:.*/,
  },
  EMOM_ADD: /^Min-([0-9]+)[ \t]*:.*\n/,
  MOVEMENT: /^\s*((>(.* \+ )*)|(\s*[Mm][Ii][Nn]\s*(-\s*)?[0-9]*\s*:)\s+)/,
  UNIT: {
    //LOAD_OLD: /.*x( )*(([0-9]+)(,( )*[0-9]+)*)|load( )?/,
    DISTANCE: /for\s+([0-9]+|[Mm])\s*$/,
    LOAD: /x\s+([0-9]+|[Mm])\s*$/,
    TIME: /in\s+((([0-9]+:)?[0-9]+)|[Mm])\s*$/
  },
  SHORT_GENERIC: /(^|\+)[^`]*`[^`]*($|\+)/
} as const;
