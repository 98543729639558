// Generated from UniversalWorkoutLanguageLexer.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";


export class UniversalWorkoutLanguageLexer extends antlr.Lexer {
    public static readonly WS = 1;
    public static readonly NL = 2;
    public static readonly NUMBER = 3;
    public static readonly FORTIME_KW = 4;
    public static readonly WORKOUT_SEPARATOR = 5;
    public static readonly INTO_KW = 6;
    public static readonly MOVEMENT_MARKER = 7;
    public static readonly CHOICE_KW = 8;
    public static readonly OPTION_KW = 9;
    public static readonly EMOM_KW = 10;
    public static readonly WOD_KW = 11;
    public static readonly DEF_KW = 12;
    public static readonly NEW_MOV_KW = 13;
    public static readonly REST_KW = 14;
    public static readonly MIN_KW = 15;
    public static readonly STRING = 16;
    public static readonly GENERIC_STRING = 17;
    public static readonly PAREN_L = 18;
    public static readonly LBRACE = 19;
    public static readonly RBRACE = 20;
    public static readonly COLON = 21;
    public static readonly INLINE_COMMENT = 22;
    public static readonly MULTILINE_COMMENT = 23;
    public static readonly WORKOUT_IDENTIFIER = 24;
    public static readonly GENERIC_SEPARATOR = 25;
    public static readonly ERROR_CHAR = 26;
    public static readonly WORKOUT_WS = 27;
    public static readonly WORKOUT_PAREN_L = 28;
    public static readonly WORKOUT_PAREN_R = 29;
    public static readonly WORKOUT_COLON = 30;
    public static readonly WORKOUT_COMMA = 31;
    public static readonly WORKOUT_QUOTE = 32;
    public static readonly WORKOUT_STRING = 33;
    public static readonly WORKOUT_ML_STRING = 34;
    public static readonly WORKOUT_TIME = 35;
    public static readonly WORKOUT_NUMBER = 36;
    public static readonly WORKOUT_UNIT_TIME = 37;
    public static readonly WORKOUT_IDEN = 38;
    public static readonly CHOICE_WS = 39;
    public static readonly CHOICE_NAME = 40;
    public static readonly MOVEMENT_NAME_REP_MARKER = 41;
    public static readonly SIMPLE_MOVEMENT = 42;
    public static readonly MOVEMENT_NAME_REPS = 43;
    public static readonly MOVEMENT_NAME_COMPOENENT_SEPARATOR = 44;
    public static readonly COMPLEX_MOVEMENT = 45;
    public static readonly MOVEMENT_NAME_TERMINATOR = 46;
    public static readonly MOVEMENT_NAME_WS = 47;
    public static readonly MC_WS = 48;
    public static readonly MC_NUMBER = 49;
    public static readonly MC_MAX = 50;
    public static readonly AMRAP_UNIT_KW = 51;
    public static readonly MC_PAREN_L = 52;
    public static readonly MC_PAREN_R = 53;
    public static readonly MC_DASH = 54;
    public static readonly MC_CAL_KW = 55;
    public static readonly MC_TIME = 56;
    public static readonly MC_SET_SEPARATOR = 57;
    public static readonly LOAD_MARKER = 58;
    public static readonly TEMPO_MARKER = 59;
    public static readonly DURATION_MARKER = 60;
    public static readonly DISTANCE_MARKER = 61;
    public static readonly MOVEMENT_TERM = 62;
    public static readonly MC_CONTENT = 63;
    public static readonly MOV_ERR = 64;
    public static readonly DEF_WS = 65;
    public static readonly DEF_COMMENT = 66;
    public static readonly DEF_REGEX = 67;
    public static readonly DEF_IDENTIFIER = 68;
    public static readonly DEF_QUOTE = 69;
    public static readonly DEF_COLON = 70;
    public static readonly DEF_OPTIONAL = 71;
    public static readonly DEF_COMMA = 72;
    public static readonly DEF_PAREN_L = 73;
    public static readonly DEF_PAREN_R = 74;
    public static readonly DEF_BRACE_L = 75;
    public static readonly DEF_BRACE_R = 76;
    public static readonly DEF_SUPER_KW = 77;
    public static readonly DEF_TYPES = 78;
    public static readonly DEF_ENUM_KW = 79;
    public static readonly NEW_MOV_WS = 80;
    public static readonly NEW_MOV_QUOTE = 81;
    public static readonly NEW_MOV_IDENTIFIER = 82;
    public static readonly NEW_MOV_COLON = 83;
    public static readonly NEW_MOV_COMMA = 84;
    public static readonly NEW_MOV_SEMI = 85;
    public static readonly NEW_MOV_PARAM = 86;
    public static readonly NUMBER_WS = 87;
    public static readonly ROUNDS_WORD = 88;
    public static readonly ALT_KW = 89;
    public static readonly FORTIME_SET_KW = 90;
    public static readonly TIME_UNIT = 91;
    public static readonly NUMBER_CONTEXT_ERR = 92;
    public static readonly FORTIME_WS = 93;
    public static readonly FORTIME_ERR = 94;
    public static readonly AMRAP_WS = 95;
    public static readonly AMRAP_KW = 96;
    public static readonly AMRAP_ERR = 97;
    public static readonly EMOM_X_SEP = 98;
    public static readonly EMOM_DASH = 99;
    public static readonly EMOM_COLON = 100;
    public static readonly EMOM_MIN_KW = 101;
    public static readonly EMOM_NUM = 102;
    public static readonly EMOM_WS = 103;
    public static readonly EMOM_NL = 104;
    public static readonly EMOM_ERR = 105;
    public static readonly M_WORKOUT = 1;
    public static readonly CH_NAME = 2;
    public static readonly M_MOVEMENT_NAME = 3;
    public static readonly M_MOVEMENT_CONTENT = 4;
    public static readonly M_DEF = 5;
    public static readonly M_NEW_MOV = 6;
    public static readonly M_NUMBER_CONTEXT = 7;
    public static readonly M_FORTIME = 8;
    public static readonly M_AMRAP = 9;
    public static readonly M_EMOM = 10;

    public static readonly channelNames = [
        "DEFAULT_TOKEN_CHANNEL", "HIDDEN", "CH_WS", "CH_ERR", "CH_COMM"
    ];

    public static readonly literalNames = [
        null, null, null, null, null, null, "'into'", null, null, null, 
        null, null, "'define'", "'newMovement'", null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, "'M'", 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, "'?'", null, 
        null, null, null, null, null, null, "'enum'", null, null, null, 
        null, null, "';'"
    ];

    public static readonly symbolicNames = [
        null, "WS", "NL", "NUMBER", "FORTIME_KW", "WORKOUT_SEPARATOR", "INTO_KW", 
        "MOVEMENT_MARKER", "CHOICE_KW", "OPTION_KW", "EMOM_KW", "WOD_KW", 
        "DEF_KW", "NEW_MOV_KW", "REST_KW", "MIN_KW", "STRING", "GENERIC_STRING", 
        "PAREN_L", "LBRACE", "RBRACE", "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", 
        "WORKOUT_IDENTIFIER", "GENERIC_SEPARATOR", "ERROR_CHAR", "WORKOUT_WS", 
        "WORKOUT_PAREN_L", "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", 
        "WORKOUT_QUOTE", "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", 
        "WORKOUT_NUMBER", "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "CHOICE_WS", 
        "CHOICE_NAME", "MOVEMENT_NAME_REP_MARKER", "SIMPLE_MOVEMENT", "MOVEMENT_NAME_REPS", 
        "MOVEMENT_NAME_COMPOENENT_SEPARATOR", "COMPLEX_MOVEMENT", "MOVEMENT_NAME_TERMINATOR", 
        "MOVEMENT_NAME_WS", "MC_WS", "MC_NUMBER", "MC_MAX", "AMRAP_UNIT_KW", 
        "MC_PAREN_L", "MC_PAREN_R", "MC_DASH", "MC_CAL_KW", "MC_TIME", "MC_SET_SEPARATOR", 
        "LOAD_MARKER", "TEMPO_MARKER", "DURATION_MARKER", "DISTANCE_MARKER", 
        "MOVEMENT_TERM", "MC_CONTENT", "MOV_ERR", "DEF_WS", "DEF_COMMENT", 
        "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", "DEF_COLON", "DEF_OPTIONAL", 
        "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", "DEF_BRACE_L", "DEF_BRACE_R", 
        "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", "NEW_MOV_WS", "NEW_MOV_QUOTE", 
        "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", "NEW_MOV_COMMA", "NEW_MOV_SEMI", 
        "NEW_MOV_PARAM", "NUMBER_WS", "ROUNDS_WORD", "ALT_KW", "FORTIME_SET_KW", 
        "TIME_UNIT", "NUMBER_CONTEXT_ERR", "FORTIME_WS", "FORTIME_ERR", 
        "AMRAP_WS", "AMRAP_KW", "AMRAP_ERR", "EMOM_X_SEP", "EMOM_DASH", 
        "EMOM_COLON", "EMOM_MIN_KW", "EMOM_NUM", "EMOM_WS", "EMOM_NL", "EMOM_ERR"
    ];

    public static readonly modeNames = [
        "DEFAULT_MODE", "M_WORKOUT", "CH_NAME", "M_MOVEMENT_NAME", "M_MOVEMENT_CONTENT", 
        "M_DEF", "M_NEW_MOV", "M_NUMBER_CONTEXT", "M_FORTIME", "M_AMRAP", 
        "M_EMOM",
    ];

    public static readonly ruleNames = [
        "G_WS", "G_NL", "G_FULL_WS", "G_ML_COMMENT", "WS", "NL", "INTEGER_LITERAL", 
        "FLOAT_LITERAL", "G_TIME_LITERAL", "G_UNIT_TIME_LITERAL", "REGEX_MARKER", 
        "REGEX_LITERAL", "ERR_G", "NUMBER", "FORTIME_KW", "WORKOUT_SEPARATOR", 
        "INTO_KW", "MOVEMENT_MARKER", "CHOICE_KW", "OPTION_KW", "EMOM_KW", 
        "WOD_KW", "DEF_KW", "NEW_MOV_KW", "REST_KW", "MIN_KW", "STRING", 
        "GENERIC_STRING", "PAREN_L", "WORD_G", "LBRACE", "RBRACE", "COLON", 
        "INLINE_COMMENT", "MULTILINE_COMMENT", "WORKOUT_IDENTIFIER", "GENERIC_SEPARATOR", 
        "ERROR_CHAR", "WORKOUT_WS", "WORKOUT_PAREN_L", "WORKOUT_PAREN_R", 
        "WORKOUT_COLON", "WORKOUT_COMMA", "WORKOUT_QUOTE", "WORKOUT_STRING", 
        "WORKOUT_ML_STRING", "WORKOUT_TIME", "WORKOUT_NUMBER", "WORKOUT_UNIT_TIME", 
        "WORKOUT_IDEN", "CHOICE_WS", "CHOICE_NAME", "MOVEMENT_NAME_REP_MARKER", 
        "SIMPLE_MOVEMENT", "MOVEMENT_NAME_REPS", "MOVEMENT_NAME_COMPOENENT_SEPARATOR", 
        "COMPLEX_MOVEMENT", "MOVEMENT_NAME_TERMINATOR", "MOVEMENT_NAME_WS", 
        "MC_WS", "MC_NUMBER", "MC_MAX", "AMRAP_UNIT_KW", "MC_PAREN_L", "MC_PAREN_R", 
        "MC_DASH", "MC_CAL_KW", "MC_TIME", "MC_SET_SEPARATOR", "LOAD_MARKER", 
        "TEMPO_MARKER", "DURATION_MARKER", "DISTANCE_MARKER", "MOVEMENT_TERM", 
        "MC_CONTENT", "MOV_ERR", "DEF_WS", "DEF_COMMENT", "DEF_REGEX", "DEF_IDENTIFIER", 
        "DEF_QUOTE", "DEF_COLON", "DEF_OPTIONAL", "DEF_COMMA", "DEF_PAREN_L", 
        "DEF_PAREN_R", "DEF_BRACE_L", "DEF_BRACE_R", "DEF_SUPER_KW", "DEF_TYPES", 
        "DEF_ENUM_KW", "NEW_MOV_WS", "NEW_MOV_QUOTE", "NEW_MOV_IDENTIFIER", 
        "NEW_MOV_COLON", "NEW_MOV_COMMA", "NEW_MOV_SEMI", "NEW_MOV_PARAM", 
        "NUMBER_WS", "ROUNDS_WORD", "ALT_KW", "FORTIME_SET_KW", "TIME_UNIT", 
        "NUMBER_CONTEXT_ERR", "FORTIME_WS", "M_FORTIME_KW", "FORTIME_ERR", 
        "AMRAP_WS", "AMRAP_KW", "AMRAP_ERR", "EMOM_X_SEP", "EMOM_DASH", 
        "EMOM_COLON", "EMOM_MIN_KW", "EMOM_NUM", "EMOM_WS", "EMOM_NL", "EMOM_ERR",
    ];


    public constructor(input: antlr.CharStream) {
        super(input);
        this.interpreter = new antlr.LexerATNSimulator(this, UniversalWorkoutLanguageLexer._ATN, UniversalWorkoutLanguageLexer.decisionsToDFA, new antlr.PredictionContextCache());
    }

    public get grammarFileName(): string { return "UniversalWorkoutLanguageLexer.g4"; }

    public get literalNames(): (string | null)[] { return UniversalWorkoutLanguageLexer.literalNames; }
    public get symbolicNames(): (string | null)[] { return UniversalWorkoutLanguageLexer.symbolicNames; }
    public get ruleNames(): string[] { return UniversalWorkoutLanguageLexer.ruleNames; }

    public get serializedATN(): number[] { return UniversalWorkoutLanguageLexer._serializedATN; }

    public get channelNames(): string[] { return UniversalWorkoutLanguageLexer.channelNames; }

    public get modeNames(): string[] { return UniversalWorkoutLanguageLexer.modeNames; }

    public static readonly _serializedATN: number[] = [
        4,0,105,1032,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,
        -1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,
        7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,
        14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,20,7,20,2,
        21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,7,
        27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,
        34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,
        40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,
        47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,
        53,2,54,7,54,2,55,7,55,2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,
        60,7,60,2,61,7,61,2,62,7,62,2,63,7,63,2,64,7,64,2,65,7,65,2,66,7,
        66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,7,70,2,71,7,71,2,72,7,72,2,
        73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,77,2,78,7,78,2,79,7,
        79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,2,85,7,85,2,
        86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,92,7,
        92,2,93,7,93,2,94,7,94,2,95,7,95,2,96,7,96,2,97,7,97,2,98,7,98,2,
        99,7,99,2,100,7,100,2,101,7,101,2,102,7,102,2,103,7,103,2,104,7,
        104,2,105,7,105,2,106,7,106,2,107,7,107,2,108,7,108,2,109,7,109,
        2,110,7,110,2,111,7,111,2,112,7,112,2,113,7,113,2,114,7,114,2,115,
        7,115,2,116,7,116,2,117,7,117,1,0,4,0,249,8,0,11,0,12,0,250,1,1,
        3,1,254,8,1,1,1,1,1,3,1,258,8,1,1,2,1,2,3,2,262,8,2,1,2,1,2,3,2,
        266,8,2,4,2,268,8,2,11,2,12,2,269,1,3,1,3,1,3,1,3,5,3,276,8,3,10,
        3,12,3,279,9,3,1,3,1,3,1,3,1,4,4,4,285,8,4,11,4,12,4,286,1,4,1,4,
        1,5,3,5,292,8,5,1,5,1,5,3,5,296,8,5,1,5,1,5,1,6,1,6,1,6,5,6,303,
        8,6,10,6,12,6,306,9,6,3,6,308,8,6,1,7,1,7,1,7,5,7,313,8,7,10,7,12,
        7,316,9,7,3,7,318,8,7,1,7,1,7,4,7,322,8,7,11,7,12,7,323,3,7,326,
        8,7,1,8,5,8,329,8,8,10,8,12,8,332,9,8,1,8,1,8,1,8,3,8,337,8,8,1,
        9,4,9,340,8,9,11,9,12,9,341,1,9,3,9,345,8,9,1,9,5,9,348,8,9,10,9,
        12,9,351,9,9,1,10,1,10,1,11,1,11,4,11,357,8,11,11,11,12,11,358,1,
        11,1,11,1,12,1,12,1,13,4,13,366,8,13,11,13,12,13,367,1,13,1,13,1,
        14,1,14,1,14,1,14,5,14,376,8,14,10,14,12,14,379,9,14,1,14,1,14,1,
        14,1,14,1,14,1,15,1,15,1,16,1,16,1,16,1,16,1,16,1,17,1,17,1,17,1,
        17,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,19,1,19,1,19,1,
        19,1,19,1,19,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,20,1,20,1,20,1,
        21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,
        21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,3,21,445,8,21,1,
        21,3,21,448,8,21,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,
        23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,
        23,1,24,1,24,1,24,1,24,1,24,1,25,1,25,1,25,1,25,5,25,482,8,25,10,
        25,12,25,485,9,25,1,25,1,25,5,25,489,8,25,10,25,12,25,492,9,25,1,
        25,4,25,495,8,25,11,25,12,25,496,3,25,499,8,25,1,25,5,25,502,8,25,
        10,25,12,25,505,9,25,1,25,1,25,1,26,1,26,5,26,511,8,26,10,26,12,
        26,514,9,26,1,26,1,26,1,27,1,27,5,27,520,8,27,10,27,12,27,523,9,
        27,1,27,1,27,1,28,1,28,1,28,1,28,1,29,4,29,532,8,29,11,29,12,29,
        533,1,30,1,30,1,31,1,31,1,32,1,32,1,33,1,33,5,33,544,8,33,10,33,
        12,33,547,9,33,1,33,1,33,1,34,1,34,1,34,1,34,1,35,1,35,5,35,557,
        8,35,10,35,12,35,560,9,35,1,35,1,35,1,36,1,36,1,36,1,36,1,37,1,37,
        1,38,1,38,1,38,1,38,1,39,1,39,1,40,1,40,1,40,1,40,1,41,1,41,1,42,
        1,42,1,43,1,43,1,44,1,44,5,44,588,8,44,10,44,12,44,591,9,44,1,44,
        1,44,1,45,1,45,1,45,1,45,1,45,5,45,600,8,45,10,45,12,45,603,9,45,
        1,45,1,45,1,45,1,45,1,46,1,46,1,47,1,47,1,48,1,48,1,49,1,49,5,49,
        617,8,49,10,49,12,49,620,9,49,1,50,1,50,1,50,1,50,1,51,1,51,5,51,
        628,8,51,10,51,12,51,631,9,51,1,51,1,51,1,52,1,52,4,52,637,8,52,
        11,52,12,52,638,1,53,4,53,642,8,53,11,53,12,53,643,1,53,4,53,647,
        8,53,11,53,12,53,648,3,53,651,8,53,1,53,1,53,5,53,655,8,53,10,53,
        12,53,658,9,53,1,54,1,54,5,54,662,8,54,10,54,12,54,665,9,54,1,54,
        1,54,1,55,1,55,1,56,3,56,672,8,56,1,56,1,56,1,56,1,56,3,56,678,8,
        56,1,56,1,56,5,56,682,8,56,10,56,12,56,685,9,56,1,57,1,57,1,57,1,
        57,1,58,1,58,1,58,1,58,1,59,4,59,696,8,59,11,59,12,59,697,1,59,1,
        59,1,60,4,60,703,8,60,11,60,12,60,704,1,60,1,60,4,60,709,8,60,11,
        60,12,60,710,3,60,713,8,60,1,61,1,61,1,62,1,62,1,62,1,62,1,62,1,
        62,1,63,1,63,1,64,1,64,1,65,1,65,1,66,1,66,1,66,1,66,1,66,1,66,1,
        66,1,66,1,66,3,66,738,8,66,1,67,1,67,1,68,1,68,1,69,1,69,1,70,1,
        70,1,71,1,71,1,71,1,72,1,72,1,72,1,72,1,73,3,73,756,8,73,1,73,1,
        73,3,73,760,8,73,1,73,1,73,1,74,4,74,765,8,74,11,74,12,74,766,1,
        75,1,75,1,75,1,75,1,76,1,76,1,76,1,76,1,77,1,77,1,77,1,77,5,77,781,
        8,77,10,77,12,77,784,9,77,1,77,1,77,1,77,1,78,1,78,1,79,1,79,4,79,
        793,8,79,11,79,12,79,794,1,79,1,79,1,80,1,80,1,81,1,81,1,82,1,82,
        1,83,1,83,1,84,1,84,1,85,1,85,1,86,1,86,1,87,1,87,1,87,1,87,1,88,
        1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,
        1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,
        3,88,844,8,88,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,
        1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,
        1,89,1,89,1,89,1,89,3,89,873,8,89,1,90,1,90,1,90,1,90,1,90,1,91,
        1,91,1,91,1,91,1,92,1,92,1,93,1,93,4,93,888,8,93,11,93,12,93,889,
        1,93,1,93,1,94,1,94,1,95,1,95,1,96,1,96,1,97,4,97,901,8,97,11,97,
        12,97,902,1,98,1,98,1,98,1,98,1,99,1,99,1,99,1,99,1,99,1,99,1,99,
        1,99,1,99,1,100,1,100,1,100,1,100,1,100,1,100,1,100,1,100,1,100,
        1,100,1,100,1,100,1,101,3,101,931,8,101,1,101,1,101,1,101,1,101,
        1,101,3,101,938,8,101,1,101,1,101,1,102,3,102,943,8,102,1,102,1,
        102,1,102,1,102,1,102,1,102,1,102,3,102,952,8,102,1,102,1,102,1,
        103,1,103,1,103,1,103,1,103,1,104,1,104,1,104,1,104,1,105,1,105,
        1,105,1,105,5,105,969,8,105,10,105,12,105,972,9,105,1,105,1,105,
        1,105,1,105,1,105,1,105,1,105,1,105,1,106,1,106,1,106,1,106,1,106,
        1,107,1,107,1,107,1,107,1,108,1,108,1,108,1,108,1,108,1,108,1,108,
        1,108,1,109,1,109,1,109,1,109,1,109,1,110,1,110,1,111,1,111,1,112,
        1,112,1,112,1,112,1,113,1,113,1,113,1,113,3,113,1016,8,113,1,114,
        1,114,1,115,1,115,1,115,1,115,1,116,1,116,1,116,1,116,1,117,1,117,
        1,117,1,117,1,117,7,277,358,512,521,589,601,782,0,118,11,0,13,0,
        15,0,17,0,19,1,21,2,23,0,25,0,27,0,29,0,31,0,33,0,35,0,37,3,39,4,
        41,5,43,6,45,7,47,8,49,9,51,10,53,11,55,12,57,13,59,14,61,15,63,
        16,65,17,67,18,69,0,71,19,73,20,75,21,77,22,79,23,81,24,83,25,85,
        26,87,27,89,28,91,29,93,30,95,31,97,32,99,33,101,34,103,35,105,36,
        107,37,109,38,111,39,113,40,115,41,117,42,119,43,121,44,123,45,125,
        46,127,47,129,48,131,49,133,50,135,51,137,52,139,53,141,54,143,55,
        145,56,147,57,149,58,151,59,153,60,155,61,157,62,159,63,161,64,163,
        65,165,66,167,67,169,68,171,69,173,70,175,71,177,72,179,73,181,74,
        183,75,185,76,187,77,189,78,191,79,193,80,195,81,197,82,199,83,201,
        84,203,85,205,86,207,87,209,88,211,89,213,90,215,91,217,92,219,93,
        221,0,223,94,225,95,227,96,229,97,231,98,233,99,235,100,237,101,
        239,102,241,103,243,104,245,105,11,0,1,2,3,4,5,6,7,8,9,10,34,2,0,
        9,9,32,32,1,0,49,57,1,0,48,57,2,0,65,90,97,122,2,0,70,70,102,102,
        2,0,79,79,111,111,2,0,82,82,114,114,2,0,84,84,116,116,2,0,73,73,
        105,105,2,0,77,77,109,109,2,0,69,69,101,101,1,0,62,62,2,0,67,67,
        99,99,2,0,65,65,97,97,2,0,80,80,112,112,2,0,87,87,119,119,2,0,68,
        68,100,100,2,0,78,78,110,110,3,0,48,57,65,90,97,122,2,0,10,10,13,
        13,4,0,45,45,65,90,95,95,97,122,5,0,45,45,48,57,65,90,95,95,97,122,
        1,0,59,59,6,0,32,32,45,45,48,57,65,90,95,95,97,122,2,0,88,88,120,
        120,5,0,45,45,47,47,65,90,95,95,97,122,9,0,32,32,40,41,45,45,47,
        57,65,91,93,93,95,95,97,123,125,125,1,0,32,32,1,0,58,58,2,0,13,13,
        59,59,7,0,9,10,13,13,32,32,40,41,43,45,59,59,64,64,3,0,65,90,95,
        95,97,122,2,0,83,83,115,115,4,0,77,77,83,83,109,109,115,115,1087,
        0,19,1,0,0,0,0,21,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,
        0,43,1,0,0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,
        0,53,1,0,0,0,0,55,1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,0,0,0,
        0,63,1,0,0,0,0,65,1,0,0,0,0,67,1,0,0,0,0,71,1,0,0,0,0,73,1,0,0,0,
        0,75,1,0,0,0,0,77,1,0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,0,0,
        0,85,1,0,0,0,1,87,1,0,0,0,1,89,1,0,0,0,1,91,1,0,0,0,1,93,1,0,0,0,
        1,95,1,0,0,0,1,97,1,0,0,0,1,99,1,0,0,0,1,101,1,0,0,0,1,103,1,0,0,
        0,1,105,1,0,0,0,1,107,1,0,0,0,1,109,1,0,0,0,2,111,1,0,0,0,2,113,
        1,0,0,0,3,115,1,0,0,0,3,117,1,0,0,0,3,119,1,0,0,0,3,121,1,0,0,0,
        3,123,1,0,0,0,3,125,1,0,0,0,3,127,1,0,0,0,4,129,1,0,0,0,4,131,1,
        0,0,0,4,133,1,0,0,0,4,135,1,0,0,0,4,137,1,0,0,0,4,139,1,0,0,0,4,
        141,1,0,0,0,4,143,1,0,0,0,4,145,1,0,0,0,4,147,1,0,0,0,4,149,1,0,
        0,0,4,151,1,0,0,0,4,153,1,0,0,0,4,155,1,0,0,0,4,157,1,0,0,0,4,159,
        1,0,0,0,4,161,1,0,0,0,5,163,1,0,0,0,5,165,1,0,0,0,5,167,1,0,0,0,
        5,169,1,0,0,0,5,171,1,0,0,0,5,173,1,0,0,0,5,175,1,0,0,0,5,177,1,
        0,0,0,5,179,1,0,0,0,5,181,1,0,0,0,5,183,1,0,0,0,5,185,1,0,0,0,5,
        187,1,0,0,0,5,189,1,0,0,0,5,191,1,0,0,0,6,193,1,0,0,0,6,195,1,0,
        0,0,6,197,1,0,0,0,6,199,1,0,0,0,6,201,1,0,0,0,6,203,1,0,0,0,6,205,
        1,0,0,0,7,207,1,0,0,0,7,209,1,0,0,0,7,211,1,0,0,0,7,213,1,0,0,0,
        7,215,1,0,0,0,7,217,1,0,0,0,8,219,1,0,0,0,8,221,1,0,0,0,8,223,1,
        0,0,0,9,225,1,0,0,0,9,227,1,0,0,0,9,229,1,0,0,0,10,231,1,0,0,0,10,
        233,1,0,0,0,10,235,1,0,0,0,10,237,1,0,0,0,10,239,1,0,0,0,10,241,
        1,0,0,0,10,243,1,0,0,0,10,245,1,0,0,0,11,248,1,0,0,0,13,257,1,0,
        0,0,15,267,1,0,0,0,17,271,1,0,0,0,19,284,1,0,0,0,21,295,1,0,0,0,
        23,307,1,0,0,0,25,317,1,0,0,0,27,330,1,0,0,0,29,339,1,0,0,0,31,352,
        1,0,0,0,33,354,1,0,0,0,35,362,1,0,0,0,37,365,1,0,0,0,39,371,1,0,
        0,0,41,385,1,0,0,0,43,387,1,0,0,0,45,392,1,0,0,0,47,396,1,0,0,0,
        49,405,1,0,0,0,51,414,1,0,0,0,53,444,1,0,0,0,55,449,1,0,0,0,57,458,
        1,0,0,0,59,472,1,0,0,0,61,477,1,0,0,0,63,508,1,0,0,0,65,517,1,0,
        0,0,67,526,1,0,0,0,69,531,1,0,0,0,71,535,1,0,0,0,73,537,1,0,0,0,
        75,539,1,0,0,0,77,541,1,0,0,0,79,550,1,0,0,0,81,554,1,0,0,0,83,563,
        1,0,0,0,85,567,1,0,0,0,87,569,1,0,0,0,89,573,1,0,0,0,91,575,1,0,
        0,0,93,579,1,0,0,0,95,581,1,0,0,0,97,583,1,0,0,0,99,585,1,0,0,0,
        101,594,1,0,0,0,103,608,1,0,0,0,105,610,1,0,0,0,107,612,1,0,0,0,
        109,614,1,0,0,0,111,621,1,0,0,0,113,625,1,0,0,0,115,634,1,0,0,0,
        117,650,1,0,0,0,119,659,1,0,0,0,121,668,1,0,0,0,123,671,1,0,0,0,
        125,686,1,0,0,0,127,690,1,0,0,0,129,695,1,0,0,0,131,702,1,0,0,0,
        133,714,1,0,0,0,135,716,1,0,0,0,137,722,1,0,0,0,139,724,1,0,0,0,
        141,726,1,0,0,0,143,728,1,0,0,0,145,739,1,0,0,0,147,741,1,0,0,0,
        149,743,1,0,0,0,151,745,1,0,0,0,153,747,1,0,0,0,155,750,1,0,0,0,
        157,759,1,0,0,0,159,764,1,0,0,0,161,768,1,0,0,0,163,772,1,0,0,0,
        165,776,1,0,0,0,167,788,1,0,0,0,169,790,1,0,0,0,171,798,1,0,0,0,
        173,800,1,0,0,0,175,802,1,0,0,0,177,804,1,0,0,0,179,806,1,0,0,0,
        181,808,1,0,0,0,183,810,1,0,0,0,185,812,1,0,0,0,187,843,1,0,0,0,
        189,872,1,0,0,0,191,874,1,0,0,0,193,879,1,0,0,0,195,883,1,0,0,0,
        197,885,1,0,0,0,199,893,1,0,0,0,201,895,1,0,0,0,203,897,1,0,0,0,
        205,900,1,0,0,0,207,904,1,0,0,0,209,908,1,0,0,0,211,917,1,0,0,0,
        213,930,1,0,0,0,215,942,1,0,0,0,217,955,1,0,0,0,219,960,1,0,0,0,
        221,964,1,0,0,0,223,981,1,0,0,0,225,986,1,0,0,0,227,990,1,0,0,0,
        229,998,1,0,0,0,231,1003,1,0,0,0,233,1005,1,0,0,0,235,1007,1,0,0,
        0,237,1015,1,0,0,0,239,1017,1,0,0,0,241,1019,1,0,0,0,243,1023,1,
        0,0,0,245,1027,1,0,0,0,247,249,7,0,0,0,248,247,1,0,0,0,249,250,1,
        0,0,0,250,248,1,0,0,0,250,251,1,0,0,0,251,12,1,0,0,0,252,254,5,13,
        0,0,253,252,1,0,0,0,253,254,1,0,0,0,254,255,1,0,0,0,255,258,5,10,
        0,0,256,258,5,13,0,0,257,253,1,0,0,0,257,256,1,0,0,0,258,14,1,0,
        0,0,259,268,7,0,0,0,260,262,5,13,0,0,261,260,1,0,0,0,261,262,1,0,
        0,0,262,263,1,0,0,0,263,266,5,10,0,0,264,266,5,13,0,0,265,261,1,
        0,0,0,265,264,1,0,0,0,266,268,1,0,0,0,267,259,1,0,0,0,267,265,1,
        0,0,0,268,269,1,0,0,0,269,267,1,0,0,0,269,270,1,0,0,0,270,16,1,0,
        0,0,271,272,5,47,0,0,272,273,5,42,0,0,273,277,1,0,0,0,274,276,9,
        0,0,0,275,274,1,0,0,0,276,279,1,0,0,0,277,278,1,0,0,0,277,275,1,
        0,0,0,278,280,1,0,0,0,279,277,1,0,0,0,280,281,5,42,0,0,281,282,5,
        47,0,0,282,18,1,0,0,0,283,285,7,0,0,0,284,283,1,0,0,0,285,286,1,
        0,0,0,286,284,1,0,0,0,286,287,1,0,0,0,287,288,1,0,0,0,288,289,6,
        4,0,0,289,20,1,0,0,0,290,292,5,13,0,0,291,290,1,0,0,0,291,292,1,
        0,0,0,292,293,1,0,0,0,293,296,5,10,0,0,294,296,5,13,0,0,295,291,
        1,0,0,0,295,294,1,0,0,0,296,297,1,0,0,0,297,298,6,5,0,0,298,22,1,
        0,0,0,299,308,5,48,0,0,300,304,7,1,0,0,301,303,7,2,0,0,302,301,1,
        0,0,0,303,306,1,0,0,0,304,302,1,0,0,0,304,305,1,0,0,0,305,308,1,
        0,0,0,306,304,1,0,0,0,307,299,1,0,0,0,307,300,1,0,0,0,308,24,1,0,
        0,0,309,318,5,48,0,0,310,314,7,1,0,0,311,313,7,2,0,0,312,311,1,0,
        0,0,313,316,1,0,0,0,314,312,1,0,0,0,314,315,1,0,0,0,315,318,1,0,
        0,0,316,314,1,0,0,0,317,309,1,0,0,0,317,310,1,0,0,0,318,325,1,0,
        0,0,319,321,5,46,0,0,320,322,7,2,0,0,321,320,1,0,0,0,322,323,1,0,
        0,0,323,321,1,0,0,0,323,324,1,0,0,0,324,326,1,0,0,0,325,319,1,0,
        0,0,325,326,1,0,0,0,326,26,1,0,0,0,327,329,7,2,0,0,328,327,1,0,0,
        0,329,332,1,0,0,0,330,328,1,0,0,0,330,331,1,0,0,0,331,333,1,0,0,
        0,332,330,1,0,0,0,333,334,5,58,0,0,334,336,7,2,0,0,335,337,7,2,0,
        0,336,335,1,0,0,0,336,337,1,0,0,0,337,28,1,0,0,0,338,340,7,2,0,0,
        339,338,1,0,0,0,340,341,1,0,0,0,341,339,1,0,0,0,341,342,1,0,0,0,
        342,344,1,0,0,0,343,345,3,11,0,0,344,343,1,0,0,0,344,345,1,0,0,0,
        345,349,1,0,0,0,346,348,7,3,0,0,347,346,1,0,0,0,348,351,1,0,0,0,
        349,347,1,0,0,0,349,350,1,0,0,0,350,30,1,0,0,0,351,349,1,0,0,0,352,
        353,5,47,0,0,353,32,1,0,0,0,354,356,3,31,10,0,355,357,9,0,0,0,356,
        355,1,0,0,0,357,358,1,0,0,0,358,359,1,0,0,0,358,356,1,0,0,0,359,
        360,1,0,0,0,360,361,3,31,10,0,361,34,1,0,0,0,362,363,9,0,0,0,363,
        36,1,0,0,0,364,366,7,2,0,0,365,364,1,0,0,0,366,367,1,0,0,0,367,365,
        1,0,0,0,367,368,1,0,0,0,368,369,1,0,0,0,369,370,6,13,1,0,370,38,
        1,0,0,0,371,372,7,4,0,0,372,373,7,5,0,0,373,377,7,6,0,0,374,376,
        3,19,4,0,375,374,1,0,0,0,376,379,1,0,0,0,377,375,1,0,0,0,377,378,
        1,0,0,0,378,380,1,0,0,0,379,377,1,0,0,0,380,381,7,7,0,0,381,382,
        7,8,0,0,382,383,7,9,0,0,383,384,7,10,0,0,384,40,1,0,0,0,385,386,
        5,43,0,0,386,42,1,0,0,0,387,388,5,105,0,0,388,389,5,110,0,0,389,
        390,5,116,0,0,390,391,5,111,0,0,391,44,1,0,0,0,392,393,7,11,0,0,
        393,394,1,0,0,0,394,395,6,17,2,0,395,46,1,0,0,0,396,397,7,12,0,0,
        397,398,5,104,0,0,398,399,5,111,0,0,399,400,5,105,0,0,400,401,5,
        99,0,0,401,402,5,101,0,0,402,403,1,0,0,0,403,404,6,18,3,0,404,48,
        1,0,0,0,405,406,7,5,0,0,406,407,5,112,0,0,407,408,5,116,0,0,408,
        409,5,105,0,0,409,410,5,111,0,0,410,411,5,110,0,0,411,412,1,0,0,
        0,412,413,6,19,3,0,413,50,1,0,0,0,414,415,7,10,0,0,415,416,7,9,0,
        0,416,417,7,5,0,0,417,418,7,9,0,0,418,419,1,0,0,0,419,420,6,20,4,
        0,420,52,1,0,0,0,421,422,7,13,0,0,422,445,7,9,0,0,423,424,7,9,0,
        0,424,425,5,111,0,0,425,426,5,114,0,0,426,427,5,110,0,0,427,428,
        5,105,0,0,428,429,5,110,0,0,429,445,5,103,0,0,430,431,7,14,0,0,431,
        445,7,9,0,0,432,433,7,13,0,0,433,434,5,102,0,0,434,435,5,116,0,0,
        435,436,5,101,0,0,436,437,5,114,0,0,437,438,5,110,0,0,438,439,5,
        111,0,0,439,440,5,111,0,0,440,445,5,110,0,0,441,442,7,15,0,0,442,
        443,7,5,0,0,443,445,7,16,0,0,444,421,1,0,0,0,444,423,1,0,0,0,444,
        430,1,0,0,0,444,432,1,0,0,0,444,441,1,0,0,0,445,447,1,0,0,0,446,
        448,5,58,0,0,447,446,1,0,0,0,447,448,1,0,0,0,448,54,1,0,0,0,449,
        450,5,100,0,0,450,451,5,101,0,0,451,452,5,102,0,0,452,453,5,105,
        0,0,453,454,5,110,0,0,454,455,5,101,0,0,455,456,1,0,0,0,456,457,
        6,22,5,0,457,56,1,0,0,0,458,459,5,110,0,0,459,460,5,101,0,0,460,
        461,5,119,0,0,461,462,5,77,0,0,462,463,5,111,0,0,463,464,5,118,0,
        0,464,465,5,101,0,0,465,466,5,109,0,0,466,467,5,101,0,0,467,468,
        5,110,0,0,468,469,5,116,0,0,469,470,1,0,0,0,470,471,6,23,6,0,471,
        58,1,0,0,0,472,473,7,6,0,0,473,474,5,101,0,0,474,475,5,115,0,0,475,
        476,5,116,0,0,476,60,1,0,0,0,477,478,7,9,0,0,478,479,7,8,0,0,479,
        483,7,17,0,0,480,482,3,11,0,0,481,480,1,0,0,0,482,485,1,0,0,0,483,
        481,1,0,0,0,483,484,1,0,0,0,484,498,1,0,0,0,485,483,1,0,0,0,486,
        490,5,45,0,0,487,489,3,11,0,0,488,487,1,0,0,0,489,492,1,0,0,0,490,
        488,1,0,0,0,490,491,1,0,0,0,491,494,1,0,0,0,492,490,1,0,0,0,493,
        495,7,2,0,0,494,493,1,0,0,0,495,496,1,0,0,0,496,494,1,0,0,0,496,
        497,1,0,0,0,497,499,1,0,0,0,498,486,1,0,0,0,498,499,1,0,0,0,499,
        503,1,0,0,0,500,502,3,11,0,0,501,500,1,0,0,0,502,505,1,0,0,0,503,
        501,1,0,0,0,503,504,1,0,0,0,504,506,1,0,0,0,505,503,1,0,0,0,506,
        507,5,58,0,0,507,62,1,0,0,0,508,512,5,34,0,0,509,511,9,0,0,0,510,
        509,1,0,0,0,511,514,1,0,0,0,512,513,1,0,0,0,512,510,1,0,0,0,513,
        515,1,0,0,0,514,512,1,0,0,0,515,516,5,34,0,0,516,64,1,0,0,0,517,
        521,5,96,0,0,518,520,9,0,0,0,519,518,1,0,0,0,520,523,1,0,0,0,521,
        522,1,0,0,0,521,519,1,0,0,0,522,524,1,0,0,0,523,521,1,0,0,0,524,
        525,5,96,0,0,525,66,1,0,0,0,526,527,5,40,0,0,527,528,1,0,0,0,528,
        529,6,28,7,0,529,68,1,0,0,0,530,532,7,18,0,0,531,530,1,0,0,0,532,
        533,1,0,0,0,533,531,1,0,0,0,533,534,1,0,0,0,534,70,1,0,0,0,535,536,
        5,123,0,0,536,72,1,0,0,0,537,538,5,125,0,0,538,74,1,0,0,0,539,540,
        5,58,0,0,540,76,1,0,0,0,541,545,5,42,0,0,542,544,8,19,0,0,543,542,
        1,0,0,0,544,547,1,0,0,0,545,543,1,0,0,0,545,546,1,0,0,0,546,548,
        1,0,0,0,547,545,1,0,0,0,548,549,3,13,1,0,549,78,1,0,0,0,550,551,
        3,17,3,0,551,552,1,0,0,0,552,553,6,34,8,0,553,80,1,0,0,0,554,558,
        7,20,0,0,555,557,7,21,0,0,556,555,1,0,0,0,557,560,1,0,0,0,558,556,
        1,0,0,0,558,559,1,0,0,0,559,561,1,0,0,0,560,558,1,0,0,0,561,562,
        6,35,7,0,562,82,1,0,0,0,563,564,7,22,0,0,564,565,1,0,0,0,565,566,
        6,36,9,0,566,84,1,0,0,0,567,568,3,35,12,0,568,86,1,0,0,0,569,570,
        3,15,2,0,570,571,1,0,0,0,571,572,6,38,0,0,572,88,1,0,0,0,573,574,
        5,40,0,0,574,90,1,0,0,0,575,576,5,41,0,0,576,577,1,0,0,0,577,578,
        6,40,10,0,578,92,1,0,0,0,579,580,5,58,0,0,580,94,1,0,0,0,581,582,
        5,44,0,0,582,96,1,0,0,0,583,584,5,34,0,0,584,98,1,0,0,0,585,589,
        5,34,0,0,586,588,9,0,0,0,587,586,1,0,0,0,588,591,1,0,0,0,589,590,
        1,0,0,0,589,587,1,0,0,0,590,592,1,0,0,0,591,589,1,0,0,0,592,593,
        5,34,0,0,593,100,1,0,0,0,594,595,5,34,0,0,595,596,5,34,0,0,596,597,
        5,34,0,0,597,601,1,0,0,0,598,600,9,0,0,0,599,598,1,0,0,0,600,603,
        1,0,0,0,601,602,1,0,0,0,601,599,1,0,0,0,602,604,1,0,0,0,603,601,
        1,0,0,0,604,605,5,34,0,0,605,606,5,34,0,0,606,607,5,34,0,0,607,102,
        1,0,0,0,608,609,3,27,8,0,609,104,1,0,0,0,610,611,3,25,7,0,611,106,
        1,0,0,0,612,613,3,29,9,0,613,108,1,0,0,0,614,618,7,21,0,0,615,617,
        7,23,0,0,616,615,1,0,0,0,617,620,1,0,0,0,618,616,1,0,0,0,618,619,
        1,0,0,0,619,110,1,0,0,0,620,618,1,0,0,0,621,622,3,15,2,0,622,623,
        1,0,0,0,623,624,6,50,0,0,624,112,1,0,0,0,625,629,3,69,29,0,626,628,
        3,69,29,0,627,626,1,0,0,0,628,631,1,0,0,0,629,627,1,0,0,0,629,630,
        1,0,0,0,630,632,1,0,0,0,631,629,1,0,0,0,632,633,6,51,10,0,633,114,
        1,0,0,0,634,636,7,24,0,0,635,637,3,127,58,0,636,635,1,0,0,0,637,
        638,1,0,0,0,638,636,1,0,0,0,638,639,1,0,0,0,639,116,1,0,0,0,640,
        642,7,2,0,0,641,640,1,0,0,0,642,643,1,0,0,0,643,641,1,0,0,0,643,
        644,1,0,0,0,644,646,1,0,0,0,645,647,7,0,0,0,646,645,1,0,0,0,647,
        648,1,0,0,0,648,646,1,0,0,0,648,649,1,0,0,0,649,651,1,0,0,0,650,
        641,1,0,0,0,650,651,1,0,0,0,651,652,1,0,0,0,652,656,7,25,0,0,653,
        655,7,26,0,0,654,653,1,0,0,0,655,658,1,0,0,0,656,654,1,0,0,0,656,
        657,1,0,0,0,657,118,1,0,0,0,658,656,1,0,0,0,659,663,3,23,6,0,660,
        662,7,27,0,0,661,660,1,0,0,0,662,665,1,0,0,0,663,661,1,0,0,0,663,
        664,1,0,0,0,664,666,1,0,0,0,665,663,1,0,0,0,666,667,7,24,0,0,667,
        120,1,0,0,0,668,669,5,43,0,0,669,122,1,0,0,0,670,672,3,23,6,0,671,
        670,1,0,0,0,671,672,1,0,0,0,672,673,1,0,0,0,673,674,5,120,0,0,674,
        683,3,117,53,0,675,677,5,43,0,0,676,678,3,23,6,0,677,676,1,0,0,0,
        677,678,1,0,0,0,678,679,1,0,0,0,679,680,5,120,0,0,680,682,3,117,
        53,0,681,675,1,0,0,0,682,685,1,0,0,0,683,681,1,0,0,0,683,684,1,0,
        0,0,684,124,1,0,0,0,685,683,1,0,0,0,686,687,7,28,0,0,687,688,1,0,
        0,0,688,689,6,57,11,0,689,126,1,0,0,0,690,691,3,11,0,0,691,692,1,
        0,0,0,692,693,6,58,0,0,693,128,1,0,0,0,694,696,7,0,0,0,695,694,1,
        0,0,0,696,697,1,0,0,0,697,695,1,0,0,0,697,698,1,0,0,0,698,699,1,
        0,0,0,699,700,6,59,0,0,700,130,1,0,0,0,701,703,7,2,0,0,702,701,1,
        0,0,0,703,704,1,0,0,0,704,702,1,0,0,0,704,705,1,0,0,0,705,712,1,
        0,0,0,706,708,5,46,0,0,707,709,7,2,0,0,708,707,1,0,0,0,709,710,1,
        0,0,0,710,708,1,0,0,0,710,711,1,0,0,0,711,713,1,0,0,0,712,706,1,
        0,0,0,712,713,1,0,0,0,713,132,1,0,0,0,714,715,5,77,0,0,715,134,1,
        0,0,0,716,717,7,13,0,0,717,718,7,9,0,0,718,719,7,6,0,0,719,720,7,
        13,0,0,720,721,7,14,0,0,721,136,1,0,0,0,722,723,5,40,0,0,723,138,
        1,0,0,0,724,725,5,41,0,0,725,140,1,0,0,0,726,727,5,45,0,0,727,142,
        1,0,0,0,728,729,7,12,0,0,729,730,5,97,0,0,730,731,5,108,0,0,731,
        737,1,0,0,0,732,733,5,111,0,0,733,734,5,114,0,0,734,735,5,105,0,
        0,735,736,5,101,0,0,736,738,5,115,0,0,737,732,1,0,0,0,737,738,1,
        0,0,0,738,144,1,0,0,0,739,740,3,27,8,0,740,146,1,0,0,0,741,742,5,
        44,0,0,742,148,1,0,0,0,743,744,5,120,0,0,744,150,1,0,0,0,745,746,
        5,64,0,0,746,152,1,0,0,0,747,748,7,8,0,0,748,749,5,110,0,0,749,154,
        1,0,0,0,750,751,7,4,0,0,751,752,5,111,0,0,752,753,5,114,0,0,753,
        156,1,0,0,0,754,756,5,13,0,0,755,754,1,0,0,0,755,756,1,0,0,0,756,
        757,1,0,0,0,757,760,5,10,0,0,758,760,7,29,0,0,759,755,1,0,0,0,759,
        758,1,0,0,0,760,761,1,0,0,0,761,762,6,73,12,0,762,158,1,0,0,0,763,
        765,8,30,0,0,764,763,1,0,0,0,765,766,1,0,0,0,766,764,1,0,0,0,766,
        767,1,0,0,0,767,160,1,0,0,0,768,769,3,35,12,0,769,770,1,0,0,0,770,
        771,6,75,13,0,771,162,1,0,0,0,772,773,3,15,2,0,773,774,1,0,0,0,774,
        775,6,76,0,0,775,164,1,0,0,0,776,777,5,47,0,0,777,778,5,42,0,0,778,
        782,1,0,0,0,779,781,9,0,0,0,780,779,1,0,0,0,781,784,1,0,0,0,782,
        783,1,0,0,0,782,780,1,0,0,0,783,785,1,0,0,0,784,782,1,0,0,0,785,
        786,5,42,0,0,786,787,5,47,0,0,787,166,1,0,0,0,788,789,3,33,11,0,
        789,168,1,0,0,0,790,792,5,34,0,0,791,793,7,23,0,0,792,791,1,0,0,
        0,793,794,1,0,0,0,794,792,1,0,0,0,794,795,1,0,0,0,795,796,1,0,0,
        0,796,797,5,34,0,0,797,170,1,0,0,0,798,799,5,34,0,0,799,172,1,0,
        0,0,800,801,5,58,0,0,801,174,1,0,0,0,802,803,5,63,0,0,803,176,1,
        0,0,0,804,805,5,44,0,0,805,178,1,0,0,0,806,807,5,40,0,0,807,180,
        1,0,0,0,808,809,5,41,0,0,809,182,1,0,0,0,810,811,5,123,0,0,811,184,
        1,0,0,0,812,813,5,125,0,0,813,814,1,0,0,0,814,815,6,87,10,0,815,
        186,1,0,0,0,816,817,5,98,0,0,817,818,5,97,0,0,818,819,5,115,0,0,
        819,844,5,101,0,0,820,821,5,112,0,0,821,822,5,114,0,0,822,823,5,
        111,0,0,823,824,5,103,0,0,824,825,5,114,0,0,825,826,5,101,0,0,826,
        827,5,115,0,0,827,828,5,115,0,0,828,829,5,105,0,0,829,830,5,110,
        0,0,830,844,5,103,0,0,831,832,5,110,0,0,832,833,5,111,0,0,833,834,
        5,95,0,0,834,835,5,109,0,0,835,836,5,111,0,0,836,837,5,118,0,0,837,
        838,5,101,0,0,838,839,5,109,0,0,839,840,5,101,0,0,840,841,5,110,
        0,0,841,842,5,116,0,0,842,844,5,115,0,0,843,816,1,0,0,0,843,820,
        1,0,0,0,843,831,1,0,0,0,844,188,1,0,0,0,845,846,5,105,0,0,846,847,
        5,110,0,0,847,873,5,116,0,0,848,849,5,100,0,0,849,850,5,111,0,0,
        850,851,5,117,0,0,851,852,5,98,0,0,852,853,5,108,0,0,853,873,5,101,
        0,0,854,855,5,115,0,0,855,856,5,116,0,0,856,857,5,114,0,0,857,858,
        5,105,0,0,858,859,5,110,0,0,859,873,5,103,0,0,860,861,5,119,0,0,
        861,862,5,111,0,0,862,863,5,114,0,0,863,864,5,107,0,0,864,865,5,
        111,0,0,865,866,5,117,0,0,866,867,5,116,0,0,867,873,5,115,0,0,868,
        869,5,116,0,0,869,870,5,105,0,0,870,871,5,109,0,0,871,873,5,101,
        0,0,872,845,1,0,0,0,872,848,1,0,0,0,872,854,1,0,0,0,872,860,1,0,
        0,0,872,868,1,0,0,0,873,190,1,0,0,0,874,875,5,101,0,0,875,876,5,
        110,0,0,876,877,5,117,0,0,877,878,5,109,0,0,878,192,1,0,0,0,879,
        880,3,15,2,0,880,881,1,0,0,0,881,882,6,91,0,0,882,194,1,0,0,0,883,
        884,5,34,0,0,884,196,1,0,0,0,885,887,5,34,0,0,886,888,7,23,0,0,887,
        886,1,0,0,0,888,889,1,0,0,0,889,887,1,0,0,0,889,890,1,0,0,0,890,
        891,1,0,0,0,891,892,5,34,0,0,892,198,1,0,0,0,893,894,5,58,0,0,894,
        200,1,0,0,0,895,896,5,44,0,0,896,202,1,0,0,0,897,898,5,59,0,0,898,
        204,1,0,0,0,899,901,7,31,0,0,900,899,1,0,0,0,901,902,1,0,0,0,902,
        900,1,0,0,0,902,903,1,0,0,0,903,206,1,0,0,0,904,905,3,15,2,0,905,
        906,1,0,0,0,906,907,6,98,0,0,907,208,1,0,0,0,908,909,7,6,0,0,909,
        910,5,111,0,0,910,911,5,117,0,0,911,912,5,110,0,0,912,913,5,100,
        0,0,913,914,5,115,0,0,914,915,1,0,0,0,915,916,6,99,14,0,916,210,
        1,0,0,0,917,918,7,13,0,0,918,919,5,108,0,0,919,920,5,116,0,0,920,
        921,1,0,0,0,921,922,3,11,0,0,922,923,7,32,0,0,923,924,5,101,0,0,
        924,925,5,116,0,0,925,926,5,115,0,0,926,927,1,0,0,0,927,928,6,100,
        12,0,928,212,1,0,0,0,929,931,3,11,0,0,930,929,1,0,0,0,930,931,1,
        0,0,0,931,932,1,0,0,0,932,933,7,32,0,0,933,934,5,101,0,0,934,935,
        5,116,0,0,935,937,1,0,0,0,936,938,7,32,0,0,937,936,1,0,0,0,937,938,
        1,0,0,0,938,939,1,0,0,0,939,940,6,101,12,0,940,214,1,0,0,0,941,943,
        3,11,0,0,942,941,1,0,0,0,942,943,1,0,0,0,943,951,1,0,0,0,944,945,
        7,9,0,0,945,946,7,8,0,0,946,952,7,17,0,0,947,948,7,32,0,0,948,949,
        7,10,0,0,949,952,7,12,0,0,950,952,7,33,0,0,951,944,1,0,0,0,951,947,
        1,0,0,0,951,950,1,0,0,0,952,953,1,0,0,0,953,954,6,102,15,0,954,216,
        1,0,0,0,955,956,9,0,0,0,956,957,1,0,0,0,957,958,6,103,13,0,958,959,
        6,103,12,0,959,218,1,0,0,0,960,961,3,15,2,0,961,962,1,0,0,0,962,
        963,6,104,0,0,963,220,1,0,0,0,964,965,7,4,0,0,965,966,7,5,0,0,966,
        970,7,6,0,0,967,969,3,19,4,0,968,967,1,0,0,0,969,972,1,0,0,0,970,
        968,1,0,0,0,970,971,1,0,0,0,971,973,1,0,0,0,972,970,1,0,0,0,973,
        974,7,7,0,0,974,975,7,8,0,0,975,976,7,9,0,0,976,977,7,10,0,0,977,
        978,1,0,0,0,978,979,6,105,12,0,979,980,6,105,16,0,980,222,1,0,0,
        0,981,982,9,0,0,0,982,983,1,0,0,0,983,984,6,106,13,0,984,985,6,106,
        12,0,985,224,1,0,0,0,986,987,3,11,0,0,987,988,1,0,0,0,988,989,6,
        107,0,0,989,226,1,0,0,0,990,991,7,13,0,0,991,992,7,9,0,0,992,993,
        7,6,0,0,993,994,7,13,0,0,994,995,7,14,0,0,995,996,1,0,0,0,996,997,
        6,108,12,0,997,228,1,0,0,0,998,999,9,0,0,0,999,1000,1,0,0,0,1000,
        1001,6,109,13,0,1001,1002,6,109,12,0,1002,230,1,0,0,0,1003,1004,
        7,24,0,0,1004,232,1,0,0,0,1005,1006,5,45,0,0,1006,234,1,0,0,0,1007,
        1008,5,58,0,0,1008,1009,1,0,0,0,1009,1010,6,112,10,0,1010,236,1,
        0,0,0,1011,1012,7,9,0,0,1012,1013,7,8,0,0,1013,1016,7,17,0,0,1014,
        1016,7,9,0,0,1015,1011,1,0,0,0,1015,1014,1,0,0,0,1016,238,1,0,0,
        0,1017,1018,3,23,6,0,1018,240,1,0,0,0,1019,1020,3,11,0,0,1020,1021,
        1,0,0,0,1021,1022,6,115,0,0,1022,242,1,0,0,0,1023,1024,3,13,1,0,
        1024,1025,1,0,0,0,1025,1026,6,116,0,0,1026,244,1,0,0,0,1027,1028,
        3,35,12,0,1028,1029,1,0,0,0,1029,1030,6,117,13,0,1030,1031,6,117,
        12,0,1031,246,1,0,0,0,81,0,1,2,3,4,5,6,7,8,9,10,250,253,257,261,
        265,267,269,277,286,291,295,304,307,314,317,323,325,330,336,341,
        344,349,358,367,377,444,447,483,490,496,498,503,512,521,533,545,
        558,589,601,618,629,638,643,648,650,656,663,671,677,683,697,704,
        710,712,737,755,759,766,782,794,843,872,889,902,930,937,942,951,
        970,1015,17,0,2,0,5,7,0,2,3,0,5,2,0,5,10,0,5,5,0,5,6,0,5,1,0,0,4,
        0,6,0,0,4,0,0,2,4,0,2,0,0,0,3,0,2,8,0,5,9,0,7,4,0
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!UniversalWorkoutLanguageLexer.__ATN) {
            UniversalWorkoutLanguageLexer.__ATN = new antlr.ATNDeserializer().deserialize(UniversalWorkoutLanguageLexer._serializedATN);
        }

        return UniversalWorkoutLanguageLexer.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(UniversalWorkoutLanguageLexer.literalNames, UniversalWorkoutLanguageLexer.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return UniversalWorkoutLanguageLexer.vocabulary;
    }

    private static readonly decisionsToDFA = UniversalWorkoutLanguageLexer._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}