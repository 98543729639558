import api from '../api/base';

export interface MovementAlias {
  id: number;
  alias: string;
}

export interface Movement {
  id: number;
  name: string;
  aliases?: MovementAlias[];
  has_reps: boolean;
  has_load: boolean;
  has_duration: boolean;
  has_distance: boolean;
  has_height: boolean;
  has_power: boolean;
  has_calories: boolean;
  is_complex: boolean;
  complex_details?: string;
  category?: string;
  videos?: any;
  gym_id?: number;
  created_at?: string;
  updated_at?: string;
  video_url?: string;
  is_priority?: boolean;
}

class MovementsService {
  private subscribers: Set<() => void> = new Set();

  public subscribe(callback: () => void): () => void {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  }

  private notifyChange(): void {
    this.subscribers.forEach((callback) => callback());
  }

  async getAll(page = 1, per_page = 6000): Promise<Movement[]> {
    const response = await api.get('/api/movements', {
      params: { page, per_page },
    });
    return response.data;
  }

  async getTotalCount(): Promise<number> {
    const response = await api.get('/api/movements/count');
    return response.data.count;
  }

  async create(movement: Omit<Movement, 'id'>): Promise<Movement> {
    const response = await api.post('/api/movements', movement);
    this.notifyChange();
    return response.data;
  }

  async update(id: number, movement: Partial<Movement>): Promise<Movement> {
    const response = await api.put(`/api/movements/${id}`, movement);
    this.notifyChange();
    return response.data;
  }

  async delete(id: number): Promise<void> {
    await api.delete(`/api/movements/${id}`);
    this.notifyChange();
  }

  async search(
    query: string,
    page = 1,
    per_page = 10,
    signal?: AbortSignal,
  ): Promise<{ results: Movement[]; totalCount: number }> {
    const response = await api.get('/api/movements/search', {
      params: { query, page, per_page },
      signal,
    });
    return { results: response.data.results, totalCount: response.data.totalCount };
  }

  async getById(id: number): Promise<Movement> {
    const response = await api.get(`/api/movements/${id}`);
    return response.data;
  }

  async addAlias(movementId: number, alias: string): Promise<MovementAlias> {
    const response = await api.post(`/api/movements/${movementId}/aliases`, { alias });
    this.notifyChange();
    return response.data;
  }

  async removeAlias(movementId: number, aliasId: number): Promise<void> {
    await api.delete(`/api/movements/${movementId}/aliases/${aliasId}`);
    this.notifyChange();
  }
}

const movementsServiceInstance = new MovementsService();
export const createMovementsService = () => movementsServiceInstance;
