import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import { Box, Paper, Typography } from '@mui/material';

import { Session, SessionType, StrengthComponent } from '../../../generalParser/parser.types';
import { styles } from '../WodUpPreview.styles';

import { AlternatingPreview } from './AlternatingPreview';
import { AmrapPreview } from './AmrapPreview/AmrapPreview';
import { EmomPreview } from './EmomPreview/EmomPreview';
import { ForTimePreview } from './ForTimePreview/ForTimePreview';
import { GenericPreview } from './GenericPreview';
import { StrengthPreview } from './StrengthPreview/StrengthPreview';

export const SessionPreview: React.FC<{ session: Session }> = ({ session }) => {
  const processedComponents = session.components.reduce<React.ReactNode[]>(
    (acc, component, index) => {
      // If this component shouldn't be in alternating block, render it directly
      if (!component.should_be_inside_alternating) {
        acc.push(
          <Box key={`regular-${index}`} sx={styles.componentBox}>
            {component.type === 'Generic' && (
              <GenericPreview component={component} sessionType={session.type} />
            )}
            {component.type === 'Strength' && (
              <StrengthPreview component={component} sessionType={session.type} />
            )}
            {component.type === 'ForTime' && (
              <ForTimePreview component={component} sessionType={session.type} />
            )}
            {component.type === 'Amrap' && (
              <AmrapPreview component={component} sessionType={session.type} />
            )}
            {component.type === 'Emom' && (
              <EmomPreview component={component} sessionType={session.type} />
            )}
          </Box>,
        );
        return acc;
      }

      // If this is the start of an alternating group, collect all components with the same group ID
      if (
        index === 0 ||
        component.alternating_group_id !== session.components[index - 1].alternating_group_id
      ) {
        const alternatingComponents: StrengthComponent[] = [];
        let currentIndex = index;

        while (
          currentIndex < session.components.length &&
          session.components[currentIndex].alternating_group_id ===
            component.alternating_group_id &&
          session.components[currentIndex].type === 'Strength'
        ) {
          alternatingComponents.push(session.components[currentIndex] as StrengthComponent);
          currentIndex++;
        }

        if (alternatingComponents.length > 1) {
          acc.push(
            <Box key={`alternating-${component.alternating_group_id}`} sx={styles.componentBox}>
              <AlternatingPreview components={alternatingComponents} sessionType={session.type} />
            </Box>,
          );
        } else {
          // Render single component normally
          acc.push(
            <Box key={`regular-${index}`} sx={styles.componentBox}>
              <StrengthPreview component={alternatingComponents[0]} sessionType={session.type} />
            </Box>,
          );
        }
      }

      return acc;
    },
    [],
  );

  return (
    <Paper
      elevation={3}
      sx={{
        ...styles.sessionPaper,
        ...(session.type === SessionType.AM ? styles.amSession : styles.pmSession),
        mb: 2,
      }}
    >
      <Typography variant="h6" sx={styles.sessionTitle}>
        {session.type === SessionType.AM ? (
          <>
            <WbSunnyOutlinedIcon sx={styles.sessionIcon} />
            AM Session
          </>
        ) : (
          <>
            <DarkModeOutlinedIcon sx={styles.sessionIcon} />
            PM Session
          </>
        )}
      </Typography>
      {processedComponents}
    </Paper>
  );
};
